import React from "react";
import Styles from "./LoadingSpinner.module.scss";
import { GridLoader } from "react-spinners";
import classNames from "classnames";

interface Props {
  centered?: boolean;
}

const LoadingSpinner: React.SFC<Props> = ({ centered }) => {
  return (
    <div
      className={classNames({
        [Styles.spinner]: true,
        [Styles.centeredSpinner]: centered ? true : false,
      })}
    >
      <GridLoader sizeUnit={"px"} size={20} color={"#F67599"} />
    </div>
  );
};

export default LoadingSpinner;
