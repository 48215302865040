import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { DateTime } from "luxon";
import PubNubReact from "pubnub-react";
import LoadingSpinner from "../LoadingSpinner";
import maybePluralize from "../../utilities/maybePluralize";
import Styles from "./VideoStream.module.scss";

interface Props {
  className: string;
  streaming: boolean;
  videoLoaded: boolean;
  videoIsMuted: boolean;
  toggleVideoMute: () => void;
  redirectTime: string;
  loading: boolean;
  props: object;
  fullScreenMode: boolean,
  heartCallback: () => void;
  pubnubPublishKey: string;
  pubnubSubscribeKey: string;
  pubnub: object;
  channelInfo: any;
}

type State = {
  date?: string;
  throttling: boolean;
};

let colors: any = [
  require("../../images/icon.heart.alone.blue.svg"),
  require("../../images/icon.heart.alone.fuschia.svg"),
  require("../../images/icon.heart.alone.mint.svg"),
  require("../../images/icon.heart.alone.orange.svg"),
  require("../../images/icon.heart.alone.pink.svg"),
  require("../../images/icon.heart.alone.blue.svg"),
  require("../../images/icon.heart.alone.purple.svg")
];

class VideoStream extends Component<Props, State> {
  public pubnub: any;
  unmounted = false;
  timeUpdateInterval?: number;
  clearHearts: any;
  hearts: any = [];
  animating = false;
  canvasRef = React.createRef<HTMLCanvasElement>();
  videoRef = React.createRef<HTMLVideoElement>();
  ctx?: CanvasRenderingContext2D | null;

  state: State = {
    throttling: false
  };

  constructor(props: any) {
    super(props);
    const { pubnubSubscribeKey } = this.props;
    this.pubnub = new PubNubReact({
      publishKey: this.props.pubnubPublishKey,
      subscribeKey: pubnubSubscribeKey
    });
    this.pubnub.init(this);

    this.animate = this.animate.bind(this);
  }

  componentDidMount() {
    this.animate();
  }

  componentWillUnmount() {
    this.unmounted = true;
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval);
    }
  }

  componentDidUpdate(prevProps: any) {
    const { channelInfo, redirectTime } = this.props;

    if (prevProps.channelInfo !== channelInfo) {
      this.pubnub.subscribe({
        channels: [
          channelInfo.publicationChannelId,
          channelInfo.systemChannelId
        ]
      });

      this.pubnub.getMessage(channelInfo.systemChannelId, (response: any) => {
        if (response.message.Type === "SendHearts") {
          this.drawToCanvas();
        }
      });
    }

    if (redirectTime) {
      if (this.timeUpdateInterval) {
        clearInterval(this.timeUpdateInterval);
      }
      this.timeUpdateInterval = window.setInterval(
        () =>
          !this.unmounted &&
          this.setState({
            date: DateTime.fromISO(redirectTime)
              .diffNow("minutes")
              .toFormat("m")
          }),
        1000
      );
    }
  }

  toggleVideoMute = () =>
  {
    if (this.videoRef.current) {
      this.videoRef.current.play();
      this.videoRef.current.muted = false;
    }
    this.props.toggleVideoMute();
  };

  clearHeartsArray = () => {
    this.hearts = [];
  };

  drawToCanvas = () => {
    window.clearTimeout(this.clearHearts);
    this.clearHearts = setTimeout(this.clearHeartsArray, 5000);

    let imageObj = new Image();

    if (this.canvasRef.current) {
      this.ctx = this.canvasRef.current.getContext("2d");

      let random = Math.floor(Math.random() * colors.length);
      /* Cycle colors instead of random */
      /* if (i === colors.length - 1) {
        i = 0;
      } else {
        i++;
      } */

      imageObj.src = colors[random];

      let heartDirection = Math.floor(Math.random() * 2);

      let xReset = Math.floor(Math.random() * (25 - 15 + 1) + 15);
      let speed = Math.floor(Math.random() * (4 - 2 + 1) + 2);

      imageObj.onload = () => {
        this.hearts.push([imageObj, 335, 6, heartDirection, xReset, speed]);
      };

      this.canvasRef.current.width = 50;
      this.canvasRef.current.height = 400;

      if (!this.animating) {
        this.animating = true;
        this.animate();
      }
    }
  };

  animate = () => {
    if (this.ctx && this.canvasRef.current) {
      this.ctx.clearRect(
        0,
        0,
        this.canvasRef.current.width,
        this.canvasRef.current.height
      );

      this.hearts.forEach((heart: any, index: number) => {
        let imgObj = this.hearts[index][0];
        let yIndex = this.hearts[index][1];
        let xIndex = this.hearts[index][2];

        if (this.ctx) {
          this.ctx.save();

          if (this.hearts[index][1] < 300) {
            this.ctx.globalAlpha = this.hearts[index][1] / 200;
          }

          if (this.hearts[index][1] < 10) {
            this.ctx.globalAlpha = 0;
          }

          yIndex -= this.hearts[index][5];

          if (this.hearts[index][3] === 0) {
            this.hearts[index][2] += 0.25;

            if (this.hearts[index][2] > this.hearts[index][4]) {
              this.hearts[index][3] = 1;
            }
          }

          if (this.hearts[index][3] === 1) {
            this.hearts[index][2] -= 0.25;

            if (this.hearts[index][2] <= 0) {
              this.hearts[index][3] = 0;
            }
          }
          this.ctx.drawImage(imgObj, xIndex, yIndex, 25, 25);
          this.ctx.restore();

          this.hearts[index][1] = yIndex;
        }
      });

      requestAnimationFrame(this.animate);
    }
  };

  timeLeftPhrase = (date: string) => {
    return date !== "Invalid Duration"
      ? date + " " + maybePluralize(parseInt(date, 10), "minute")
      : "a limited time";
  };

  throttle = () => {
    const { heartCallback } = this.props;
    const { throttling } = this.state;

    if (throttling) {
      this.drawToCanvas();
    } else {
      this.setState({ throttling: true }, () => {
        heartCallback();
        setTimeout(() => {
          !this.unmounted && this.setState({ throttling: false });
        }, 1000);
      });
    }
  };

  render() {
    const {
      className,
      videoLoaded,
      videoIsMuted,
      props,
      streaming,
      fullScreenMode
    } = this.props;
    const { date } = this.state;

    return (
      <>
        <div
          className={classNames(Styles.video_stream_container, className)}
          {...props}
        >
          {!videoLoaded && (
            <div className={Styles.not_streaming}>
              <LoadingSpinner />
            </div>
          )}
          {videoLoaded && !streaming && (
            <div className={Styles.not_streaming}>
              {date && (
                <>
                  <h5 className={Styles.not_streaming_heading}>
                    This live sale has ended
                  </h5>
                  <p>
                    Better hurry! You only have {this.timeLeftPhrase(date)} to
                    finish shopping this Live Sale.
                  </p>
                </>
              )}
            </div>
          )}
          <video className={Styles.videoStream} ref={this.videoRef} id="PhenixLiveStream" />
        </div>

        {videoIsMuted ? (
          <button
            id="PhenixUnmuteButton"
            onClick={this.toggleVideoMute}
            className={classNames(Styles.mute_button, {
              "d-block": videoIsMuted
            })}
          >
            <svg width="50px" height="40px" viewBox="0 0 50 40" version="1.1">
              <g>
                <path d="M28.0464145,19.8140789 C28.0464145,25.6798684 28.0547478,31.5456579 28.0305154,37.4113377 C28.0286513,37.8780044 27.9083662,38.4129825 27.6586952,38.7960965 C27.0567215,39.7195614 25.8244846,39.7697807 24.9228399,38.9894079 C21.0818311,35.6655044 17.2375329,32.3456579 13.4080373,29.0087061 C13.0241557,28.6741667 12.6476206,28.5248246 12.1352303,28.528114 C8.84608553,28.5488377 5.55672149,28.5394079 2.26746711,28.5383114 C0.675471491,28.5379825 0.00123903509,27.8638596 0.000910087719,26.2707675 C-0.000186403509,21.9033333 -0.000405701754,17.5360088 0.00101973684,13.1686842 C0.00145833333,11.5981798 0.671633772,10.9265789 2.23117325,10.9264693 C5.64827851,10.9261404 9.06549342,10.9351316 12.482489,10.9116667 C12.7922478,10.9095833 13.1658224,10.7763596 13.3997039,10.5757018 C17.2275548,7.29280702 21.0359978,3.98721491 24.8528838,0.691381579 C26.0254715,-0.32122807 27.5819408,0.0334868421 27.9694408,1.40399123 C28.0470724,1.67855263 28.0433443,1.98162281 28.0435636,2.27153509 C28.047511,8.11901316 28.0464145,13.9664912 28.0464145,19.8140789"></path>
                <path d="M42.9891009,19.7091886 C44.8830702,21.9283772 46.7320833,24.0949342 48.5810965,26.2611623 C48.8894298,26.6223465 49.2066447,26.9762939 49.5046711,27.3458114 C50.1866886,28.1913158 50.1246272,29.2907675 49.3679386,29.9521711 C48.5790132,30.6415351 47.4727632,30.5558991 46.7378947,29.7039254 C44.8295614,27.4908772 42.9388816,25.262807 41.040636,23.0409868 C40.8990789,22.875307 40.7542325,22.7124781 40.5848246,22.5186184 C39.4374561,23.8616009 38.3088377,25.1770614 37.1865789,26.4978947 C36.2885526,27.554693 35.3953509,28.6158772 34.5051096,29.679364 C33.8264912,30.4901096 32.659386,30.6336404 31.8650877,29.9958114 C31.0479825,29.3395614 30.9556579,28.2102851 31.7013816,27.3180702 C33.0600439,25.6926316 34.44875,24.0923026 35.8239693,22.4807895 C36.6041228,21.5667544 37.3823026,20.6510746 38.1844956,19.7091886 C37.9864693,19.4719079 37.8150877,19.2625877 37.6395395,19.0567763 C35.6832895,16.7645614 33.7258333,14.4733333 31.7700219,12.1807895 C31.1146491,11.4125877 31.0148684,10.5391228 31.494693,9.82026316 C32.1809868,8.79241228 33.5895395,8.72804825 34.4519298,9.71938596 C35.8662939,11.3453728 37.2548904,12.9936184 38.6546711,14.6324342 C39.2825219,15.3675219 39.9109211,16.1021711 40.6387719,16.9537061 C40.7323026,16.7992105 40.7905263,16.6654386 40.8809868,16.5590789 C42.7869079,14.3200439 44.702807,12.0893421 46.6022588,9.84471491 C47.3649781,8.94328947 48.4758333,8.74559211 49.3016009,9.40600877 C50.1552193,10.0889035 50.2122368,11.229364 49.415636,12.1680702 C47.6904167,14.2012939 45.9543421,16.2251974 44.2233114,18.2534868 C43.8219956,18.7237719 43.4235307,19.1965789 42.9891009,19.7091886"></path>
              </g>
            </svg>
            <br />
            Click to unmute
          </button>
        ) : <>
          <canvas ref={this.canvasRef} className={Styles.canvas} />
          <img
            className={fullScreenMode ? Styles.heart_overlay_full_screen : Styles.heart_overlay}
            onClick={this.throttle}
            alt="heart reaction"
            src={require("../../images/icon.heart.svg")}
          />
        </>}

      </>
    );
  }
}

export default connect()(VideoStream);
