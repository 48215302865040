import axios from "axios";
import { productsApiBase } from "../config";

const productDescriptionEndpoint = (productId: number) =>
  `${productsApiBase}/${productId}/description`;
const productSizeChartEndpoint = (styleTagId: number) =>
  `${productsApiBase}/SizeCharts/ByStyleTagId/${styleTagId}`;

export async function getProductDescription(
  productId: number
): Promise<string> {
  return await axios
    .get<string>(productDescriptionEndpoint(productId))
    .then(response => response.data);
}

export async function getProductSizeChart(
  styleTagId: number
): Promise<string> {
  return await axios
    .get<string>(productSizeChartEndpoint(styleTagId))
    .then(response => response.data);
}
