import { Component } from "react";
import { connect } from "react-redux";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import { ProductManagerContext, ListingGroupsChangeEvent } from "./ProductManagerContext";
import { fetchAlbumsByIds } from "../../actions/albums";

export class ListingGroupChangeHandler extends Component<ThunkDispatchProp> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  listenerHandler?: number;
  fetchInterval?: number;
  staleListingGroupIds: number[] = [];

  handleChangedListingGroups = (event: ListingGroupsChangeEvent) => {
    this.staleListingGroupIds.push(...event.listingGroupIds);
  }

  componentDidMount() {
    this.listenerHandler = this.context.subscribeToListingGroupChanges(this.handleChangedListingGroups);
    this.fetchInterval = window.setInterval(() => {
      if (this.staleListingGroupIds.length > 0) {
        const stales = this.staleListingGroupIds;
        this.staleListingGroupIds = [];
        this.props.dispatch(fetchAlbumsByIds(stales));
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.listenerHandler && this.context.unsubscribeFromListingGroupChanges(this.listenerHandler);
    window.clearInterval(this.fetchInterval);
  }

  render = () => null;
}

export default connect()(ListingGroupChangeHandler);
