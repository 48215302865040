import React, { Component, MouseEventHandler } from "react";
import LoadingButton from "../../Buttons";
import { CheckoutDeliveryDataState } from "../../../store/state/checkout/delivery";
import {
  withFormik,
  InjectedFormikProps,
  Form,
  Field,
  ErrorMessage
} from "formik";
import classnames from "classnames";
import Styles from "./DeliveryForm.module.scss";
import { PickupDetailsState } from "../../../store/state/store";
import * as Yup from "yup";
import { Formatter } from "../../../Formatter";
import StateOptions from "../StateOptions";
import { CheckoutCustomerDataState } from "../../../store/state/checkout/customer";
import { DeliveryMethod } from "../../../enums";

interface Props {
  customer?: CheckoutCustomerDataState;
  existing?: CheckoutDeliveryDataState;
  pickupDetails: PickupDetailsState;
  estimatedShipping?: number;
  submitAction: (data: CheckoutDeliveryDataState) => void;
  failureErrors: {
    [p in keyof CheckoutDeliveryDataState]?: string
  }
}

class InnerForm extends Component<
  InjectedFormikProps<Props, CheckoutDeliveryDataState>
> {
  setShipping = () => {
    this.props.setFieldValue("method", "SHIPPING");
  };

  setPickup = () => {
    if (this.props.pickupDetails.enabled) {
      this.props.setFieldValue("method", "PICKUP");
    }
  };

  setSameNameAsCustomer: MouseEventHandler<HTMLInputElement> = (event) => {
    const { customer } = this.props;
    if (event.currentTarget.checked) {
      this.props.setFieldValue("firstName", (customer && customer.firstName) || "");
      this.props.setFieldTouched("firstName", false);
      this.props.setFieldValue("lastName", (customer && customer.lastName) || "");
      this.props.setFieldTouched("lastName", false);
      this.props.setFieldValue("sameNameAsCustomer", true);
    } else {
      this.props.setFieldValue("firstName", "");
      this.props.setFieldTouched("firstName", false);
      this.props.setFieldValue("lastName", "");
      this.props.setFieldTouched("lastName", false);
      this.props.setFieldValue("sameNameAsCustomer", false);
    }
  }

  render() {
    const methodSelected = this.props.values.method;
    const { estimatedShipping, pickupDetails, failureErrors, errors, touched } = this.props;
    const hasPickup = pickupDetails.enabled;

    return (
      <Form>
        <div
          className={classnames({
            [Styles.radioSection]: true,
            selectable: hasPickup,
            selected: methodSelected === "SHIPPING"
          })}
        >
          <div className={Styles.radioSectionHeader} onClick={this.setShipping}>
            {hasPickup && (
              <>
                <div className={Styles.check} />
              </>
            )}
            <div className={Styles.radioSectionHeaderLeft}>Ship Items</div>
            {estimatedShipping !== undefined && (
              <div className={Styles.radioSectionHeaderRight}>
                {(estimatedShipping > 0)
                  ? Formatter.format(estimatedShipping)
                  : "FREE"}
              </div>
            )}
          </div>
          <div className={Styles.radioSectionBody}>
            {methodSelected === "SHIPPING" && (
              <div className={Styles.padTopBody}>
                <Field type="hidden" name="method" value="SHIPPING" />
                <Field type="hidden" name="sameNameAsCustomer" />
                <label className={Styles.sameAsShipping}>
                  <input type="checkbox" defaultChecked={this.props.values.sameNameAsCustomer} onClick={this.setSameNameAsCustomer} />
                  <span className={Styles.checkmark} /> Same as customer name
                </label>
                <label className={Styles.input_header}>First Name</label>
                <Field
                  type="text"
                  name="firstName"
                  autoComplete="shipping given-name"
                  className={classnames({
                    [Styles.input_box]: true, 
                    "form-control": true,
                    [Styles.invalid]: (failureErrors.firstName && !touched.firstName) || (errors.firstName && touched.firstName)
                  })}
                  disabled={this.props.values.sameNameAsCustomer}
                />
                <div className={Styles.inputfailure}>
                  <ErrorMessage name="firstName" /> {!touched.firstName && failureErrors.firstName}
                </div>
                <label className={Styles.input_header}>Last Name</label>
                <Field
                  type="text"
                  name="lastName"
                  autoComplete="shipping family-name"
                  className={classnames({
                    [Styles.input_box]: true, 
                    "form-control": true,
                    [Styles.invalid]: (failureErrors.lastName && !touched.lastName) || (errors.lastName && touched.lastName)
                  })}
                  disabled={this.props.values.sameNameAsCustomer}
                />
                <div className={Styles.inputfailure}>
                  <ErrorMessage name="lastName" /> {!touched.lastName && failureErrors.lastName}
                </div>
                <label className={Styles.input_header}>Shipping Address</label>
                <Field
                  type="text"
                  name="line1"
                  autoComplete="shipping address-line1"
                  className={classnames({
                    [Styles.input_box]: true, 
                    "form-control": true,
                    [Styles.invalid]: (failureErrors.line1 && !touched.line1) || (errors.line1 && touched.line1)
                  })}
                />
                <div className={Styles.inputfailure}>
                  <ErrorMessage name="line1" /> {!touched.line1 && failureErrors.line1}
                </div>
                <label className={Styles.input_header}>
                  Shipping Address Line 2
                </label>
                <Field
                  type="text"
                  name="line2"
                  autoComplete="shipping address-line2"
                  className={classnames({
                    [Styles.input_box]: true, 
                    "form-control": true,
                    [Styles.invalid]: (failureErrors.line2 && !touched.line2) || (errors.line2 && touched.line2)
                  })}
                />
                <div className={Styles.inputfailure}>
                  <ErrorMessage name="line2" /> {!touched.line2 && failureErrors.line2}
                </div>
                <label className={Styles.input_header}>City</label>
                <Field
                  type="text"
                  name="city"
                  autoComplete="shipping address-level2"
                  className={classnames({
                    [Styles.input_box]: true, 
                    "form-control": true,
                    [Styles.invalid]: (failureErrors.city && !touched.city) || (errors.city && touched.city)
                  })}
                />
                <div className={Styles.inputfailure}>
                  <ErrorMessage name="city" /> {!touched.city && failureErrors.city}
                </div>
                <div className="row">
                  <div className="col-6">
                    <label className={Styles.input_header}>State</label>
                    <Field
                      component="select"
                      name="state"
                      autoComplete="shipping address-level1"
                      className={classnames({
                        [Styles.input_box]: true, 
                        "form-control": true,
                        [Styles.invalid]: (failureErrors.state && !touched.state) || (errors.state && touched.state)
                      })}
                    >
                      <StateOptions />
                    </Field>
                    <div className={Styles.inputfailure}>
                      <ErrorMessage name="state" /> {!touched.state && failureErrors.state}
                    </div>
                  </div>
                  <div className="col-6">
                    <label className={Styles.input_header}>Zip Code</label>
                    <Field
                      type="text"
                      name="zipCode"
                      autoComplete="shipping postal-code"
                      pattern="[0-9]{5}"
                      className={classnames({
                        [Styles.input_box]: true, 
                        "form-control": true,
                        [Styles.invalid]: (failureErrors.zipCode && !touched.zipCode) || (errors.zipCode && touched.zipCode)
                      })}
                    />
                    <div className={Styles.inputfailure}>
                      <ErrorMessage name="zipCode" /> {!touched.zipCode && failureErrors.zipCode}
                    </div>
                  </div>
                </div>
                <LoadingButton
                  onClick={event => {this.props.submitForm(); event.preventDefault();}}
                  disabled={this.props.dirty && !this.props.isValid}
                  block
                >
                  Next
                </LoadingButton>
              </div>
            )}
          </div>
        </div>

        {hasPickup && (
          <div
            className={classnames({
              [Styles.radioSection]: true,
              selected: methodSelected === "PICKUP",
              selectable: true
            })}
          >
            <div className={Styles.radioSectionHeader} onClick={this.setPickup}>
              <div className={Styles.check} />
              <div className={Styles.radioSectionHeaderLeft}>Pick up Items</div>
              <div className={Styles.radioSectionHeaderRight}>FREE</div>
            </div>
            <div className={Styles.radioSectionBody}>
              {methodSelected === "PICKUP" && (
                <div>
                  {pickupDetails.enabled && (
                    <>
                      <div className={Styles.input_header_shipping}>
                        Pick up Address
                      </div>
                      <p>
                        {`${pickupDetails.addressLine1}${
                          pickupDetails.addressLine2
                            ? `, ${pickupDetails.addressLine2}`
                            : ""
                        }`}
                        <br />
                        {pickupDetails.city},<br />
                        {pickupDetails.state} {pickupDetails.zip}
                      </p>
                      {pickupDetails.notes && (
                        <>
                          <div className={Styles.input_header_shipping}>
                            Notes
                          </div>
                          <p>{pickupDetails.notes}</p>
                        </>
                      )}
                    </>
                  )}

                  <div className={Styles.input_header_shipping}>
                    Who's picking up this order?
                  </div>
                  <Field type="hidden" name="method" value="PICKUP" />
                  <Field type="hidden" name="sameNameAsCustomer" />
                  <label className={Styles.sameAsShipping}>
                    <input type="checkbox" defaultChecked={this.props.values.sameNameAsCustomer} onClick={this.setSameNameAsCustomer} />
                    <span className={Styles.checkmark} /> Same as customer name
                  </label>
                  <label className={Styles.input_header}>First Name</label>
                  <Field
                    type="text"
                    name="firstName"
                    autoComplete="shipping given-name"
                    className={classnames({
                      [Styles.input_box]: true, 
                      "form-control": true,
                      [Styles.invalid]: (failureErrors.firstName && !touched.firstName) || (errors.firstName && touched.firstName)
                    })}
                    disabled={this.props.values.sameNameAsCustomer}
                  />
                  <div className={Styles.inputfailure}>
                    <ErrorMessage name="firstName" /> {!touched.firstName && failureErrors.firstName}
                  </div>
                  <label className={Styles.input_header}>Last Name</label>
                  <Field
                    type="text"
                    name="lastName"
                    autoComplete="shipping family-name"
                    className={classnames({
                      [Styles.input_box]: true, 
                      "form-control": true,
                      [Styles.invalid]: (failureErrors.lastName && !touched.lastName) || (errors.lastName && touched.lastName)
                    })}
                    disabled={this.props.values.sameNameAsCustomer}
                  />
                  <div className={Styles.inputfailure}>
                    <ErrorMessage name="lastName" /> {!touched.lastName && failureErrors.lastName}
                  </div>
                  <LoadingButton
                    onClick={event => {this.props.submitForm(); event.preventDefault();}}
                    disabled={this.props.dirty && !this.props.isValid}
                    block
                  >
                    {this.props.existing ? "Save" : "Next"}
                  </LoadingButton>
                </div>
              )}
            </div>
          </div>
        )}
      </Form>
    );
  }
}

export const DeliveryForm = withFormik<Props, CheckoutDeliveryDataState>({
  mapPropsToValues: ({ existing, customer, pickupDetails }) =>
    existing || {
      sameNameAsCustomer: true,
      firstName: (customer && customer.firstName) || "",
      lastName: (customer && customer.lastName) || "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      zipCode: "",
      method: pickupDetails.enabled
        ? DeliveryMethod.Unspecified
        : DeliveryMethod.Shipping
    },
  validationSchema: Yup.object().shape({
    method: Yup.string().required(),
    sameNameAsCustomer: Yup.boolean(),
    firstName: Yup.string().label("First Name").required().max(20),
    lastName: Yup.string().label("Last Name").required().max(30),
    line1: Yup.string().when("method", {
      is: (value) => value === "SHIPPING",
      then: Yup.string().label("Line 1").required().max(255)
    }),
    line2: Yup.string().label("Line 2").max(255),
    city: Yup.string().when("method", {
      is: (value) => value === "SHIPPING",
      then: Yup.string().label("City").required().max(50)
    }),
    state: Yup.string().when("method", {
      is: (value) => value === "SHIPPING",
      then: Yup.string().label("State").required().matches(/^[a-zA-Z]{2}$/)
    }),
    zipCode: Yup.string().when("method", {
      is: (value) => value === "SHIPPING",
      then: Yup.string().label("ZIP Code")
        .required()
        .matches(/^\d{5}$/, "ZIP Code must be 5 digits")
    })
  }),
  handleSubmit: (values, { props }) => {
    props.submitAction(values);
  },
  enableReinitialize: true
})(InnerForm);

export default DeliveryForm;
