import React, { useEffect, useRef } from 'react';
import Styles from './SizeButtonGroup.module.scss';
import { PatternListingDetails } from "../../apis/listingsEndpoints";
import { TagCategoryDefs } from '../../enums/TagCategoryDefs';

interface Props {
  patternListings?: PatternListingDetails[];
  selectedListing?: number;
  onMouseEnter: (listingPrice: number) => void;
  onMouseLeave: () => void;
  onClick: (listingId: number, listingPrice: number) => void;
}

const SizeButtonGroup = ({ patternListings, selectedListing, onMouseEnter, onMouseLeave, onClick }: Props) => {
  const prevFirstPatternListingId = useRef<number>();

  useEffect(() => {
    if (patternListings?.length === 1 && prevFirstPatternListingId.current !== patternListings[0].listingId) {
      onClick(patternListings[0].listingId, patternListings[0].salePrice);
    }

    prevFirstPatternListingId.current = patternListings?.length === 1 ? patternListings[0].listingId : undefined;
  }, [patternListings, selectedListing, onClick]);

  return (
    <>
      {
        patternListings?.map((listing) => {
          return (
            <button
              key={listing.listingId}
              className={`btn ${Styles.size_select} ${selectedListing === listing.listingId ? Styles.selected : ""}`}
              onMouseEnter={() => onMouseEnter(listing.salePrice)}
              onMouseLeave={onMouseLeave}
              onClick={() => onClick(listing.listingId, listing.salePrice)}
            >
              {listing.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name}
            </button>
          )
        })
      }
    </>
  );
};

export default SizeButtonGroup;
