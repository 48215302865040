import React, { Component, Fragment } from "react";
import { connect, MapStateToProps } from "react-redux";
import { AlbumList } from "../../components/AlbumList/AlbumList";
import { activeAlbumsSelector } from "../../utilities/albums";
import Styles from "./AlbumListPage.module.scss";
import { State } from "../../store/state";
import { AlbumState } from "../../store/state/albums";
import { PageUrls } from "../../config";

interface StateProps {
  storeAlias: string;
  albums: AlbumState[];
}
class AlbumListPage extends Component<StateProps> {
  render() {
    const { albums, storeAlias } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <h4 className={Styles.bless_h3}>Categories</h4>
          </div>
        </div>
        <div className="row">
          <AlbumList
            padPlaceholders={6}
            albums={albums.map((album) => ({
              ...album,
              link: PageUrls.AlbumDetailsPage(storeAlias, album.id)
            }))}
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, void, State> = (state) => {
  const { store } = state;

  return {
    albums: activeAlbumsSelector(state),
    storeAlias: store.alias,
    storeId: store.id
  };
};

export default connect(mapStateToProps)(AlbumListPage);
