import axios from "axios";
import { storesApiBase } from "../config";
import { CheckoutStatus } from "../enums/CheckoutStatus";

const checkoutStatusEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/status/update`;
const checkoutErrorStatusEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/status/error`;
const checkoutCustomerStatusEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/status/customer`;

export async function updateStatus(
  storeId: number,
  data: {
    checkoutStatus: CheckoutStatus;
  }
): Promise<void> {
  return axios({
    method: "post",
    url: checkoutStatusEndpoint(storeId),
    data: data
  }).then(response => Promise.resolve(response.data));
}

export async function updateErrorStatus(
  storeId: number,
  data: {
    errorMessage: string;
  }
): Promise<void> {
  return axios({
    method: "post",
    url: checkoutErrorStatusEndpoint(storeId),
    data: data
  }).then(response => Promise.resolve(response.data));
}

export async function updateCustomerStatus(
  storeId: number,
  data: {
    emailAddress: string;
    firstName: string;
    lastName: string;
    facebookId: string;
  }
): Promise<void> {
  return axios({
    method: "post",
    url: checkoutCustomerStatusEndpoint(storeId),
    data: data
  }).then(response => Promise.resolve(response.data));
}
