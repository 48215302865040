import { Reducer } from "redux";
import { ChangesState, initialChangesState } from "../store/state/changes";
import { ChangesActions } from "../actions/changes";

export const changesReducer: Reducer<ChangesState, ChangesActions> = (
  state = initialChangesState,
  action
): ChangesState => {
  switch (action.type) {
    case "@@changes/UPDATE_LASTCHANGEID":
      return {
        ...state,
        lastChangeId: action.payload.lastChangeId
      };
    default:
      return state;
  }
};
