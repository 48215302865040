/* eslint-disable no-extend-native */
// Adapted from https://cdn.jsdelivr.net/npm/reflect.ownkeys@0.2.0/index.js
if (typeof Reflect !== 'object') {
  window.Reflect = {};
}
if (typeof Reflect.ownKeys !== 'function') {
  if (typeof Object.getOwnPropertySymbols === 'function') {
    Reflect.ownKeys = (o) => {
      return Object.getOwnPropertyNames(o).concat(Object.getOwnPropertySymbols(o));
    };
  } else {
    Reflect.ownKeys = Object.getOwnPropertyNames;
  }
}
