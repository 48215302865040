import React, { useState, useCallback, useEffect } from "react";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import Styles from "./ReviewPage.module.scss";
import Form from "react-bootstrap/Form";
import { submitStoreReview, getStoreReview } from "../../apis/reviewsEndpoints";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { useHistory } from "react-router-dom";

interface Props {
  identifierId: string;
  initialRating: number;
  storeName?: string;
  storeId: number | undefined;
  commentsLength: number;
}

export const ReviewPage: React.FC<Props> = ({
  identifierId,
  initialRating,
  storeName,
  storeId,
  commentsLength
}) => {
  const [value, setValue] = React.useState(initialRating);
  const [hover, setHover] = React.useState(initialRating);
  const [reviewComplete, updateReview] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const [comments, setComments] = React.useState("");
  const [isSending, setIsSending] = useState(false);

  const labels = ["Poor", "Below Average", "Average", "Good", "Excellent"];
  const history = useHistory();

  useEffect(() => {
    const fetchReview = async () => {
      setIsLoading(true);
      if (storeId && identifierId) {
        try {
          const response = await getStoreReview(storeId, identifierId);
          if (response.retailerRatingId) {
            setComments(response.comment);
            setValue(response.stars);
            setHover(response.stars);
            updateReview(true);
          }
        } catch (err) {
          console.log("error: " + err.message);
          history.push("/404");
        }
      }
      setIsLoading(false);
    };
    fetchReview();
  }, [history, identifierId, storeId]);

  const sendRequest = useCallback(
    async (currentValue, currentComments) => {
      if (isSending) return;
      setIsSending(true);

      try {
        storeId &&
          currentValue &&
          (await submitStoreReview(storeId, {
            orderIdentifier: identifierId,
            comments: currentComments,
            rating: currentValue
          }));
        updateReview(true);
      } catch (error) {
        setIsSending(false);
        updateReview(true);
        setError(error.message);
      }
      setIsSending(false);
    },
    [isSending, identifierId, storeId]
  );

  return (
    <>
      {isLoading || (isSending && <LoadingSpinner centered={true} />)}
      <div className={isLoading || isSending ? Styles.overlay : ""}>
        <div className={isLoading || isSending ? Styles.formContainer : ""}>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <p>
              How was your shopping experience with{" "}
              {storeName ? storeName : " your retailer"}?
            </p>
            <Rating
              name="simple-controlled"
              value={value}
              className={Styles.stars}
              onChange={(event, newValue) => {
                newValue && setValue(newValue);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              readOnly={reviewComplete}
            />
            <Box ml={2} className={Styles.ratingLabel}>
              {hover === -1 ? labels[value - 1] : labels[hover - 1]}
            </Box>
          </Box>
          <Form.Group
            controlId="exampleForm.ControlTextarea1"
            className={Styles.commentsContainer}
          >
            <Form.Control
              as="textarea"
              rows={4}
              placeholder={comments ? comments : "Comments"}
              maxLength={commentsLength}
              className={Styles.comments}
              onChange={(e) => setComments(e.target.value)}
              disabled={reviewComplete}
            />
          </Form.Group>
          <div className={Styles.remainingCharacters}>
            {commentsLength - comments.length} characters remaining
          </div>
          <button
            className={`btn ${Styles.submit}`}
            onClick={() => sendRequest(value, comments)}
            disabled={reviewComplete}
          >
            Submit
          </button>
        </div>
      </div>
      {reviewComplete && error === null ? (
        <div className={Styles.success}>
          Your rating has been submitted. We appreciate your feedback!
        </div>
      ) : null}
      {!!error && (
        <div className={Styles.error}>
          There was an error submitting your review. Please try again later.
        </div>
      )}
    </>
  );
};
