import { Form, Field, ErrorMessage, InjectedFormikProps, withFormik } from "formik";
import React, { Component } from "react";
import { CheckoutCustomerDataState } from "../../../store/state/checkout/customer";
import Styles from "scss/FormStyles.module.scss";
import * as Yup from "yup";
import LoadingButton from "../../Buttons";
import classnames from "classnames";
import OhSnap from "../../../components/Checkout/OhSnap/OhSnap";

interface Props {
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  existing?: CheckoutCustomerDataState;
  showPromo: boolean;
  submitAction: (values: CheckoutCustomerDataState) => void;
  failureErrors: {
    [p in keyof CheckoutCustomerDataState | "other"]?: string;
  }
}

class InnerCustomerForm extends Component<InjectedFormikProps<Props, CheckoutCustomerDataState>> {
  render() {
    const {
      failureErrors,
      touched,
      errors,
      showPromo,
      existing,
      dirty,
      isValid,
      submitForm
    } = this.props;
    return (
      <Form className={Styles.pad}>
        <label className={Styles.input_header}>Email Address</label>
        <Field type="email" name="email" autoComplete="billing email" className={classnames({
          [Styles.input_box]: true, 
          "form-control": true,
          [Styles.invalid]: (failureErrors.email && !touched.email ) || (errors.email && touched.email)
        })} />
        <div className={Styles.inputfailure}>
          <ErrorMessage name="email" />
          {!touched.email && failureErrors.email}
        </div>
        <label className={Styles.input_header}>First Name</label>
        <Field type="text" name="firstName" autoComplete="billing given-name" className={classnames({
          [Styles.input_box]: true, 
          "form-control": true,
          [Styles.invalid]: (failureErrors.firstName && !touched.firstName) || (errors.firstName && touched.firstName)
        })} />
        <div className={Styles.inputfailure}>
          <ErrorMessage name="firstName" />
          {!touched.firstName && failureErrors.firstName}
        </div>
        <label className={Styles.input_header}>Last Name</label>
        <Field type="text" name="lastName" autoComplete="billing family-name" className={classnames({
          [Styles.input_box]: true, 
          "form-control": true,
          [Styles.invalid]: (failureErrors.lastName && !touched.lastName) || (errors.lastName && touched.lastName)
        })} />
        <div className={Styles.inputfailure}>
          <ErrorMessage name="lastName" />
          {!touched.lastName && failureErrors.lastName}
        </div>
        {showPromo && <>
          <label className={Styles.input_header}>Promo Code</label>
          <Field type="text" name="promoCode" autoComplete="off" className={classnames({
            [Styles.input_box]: true, 
            "form-control": true,
            [Styles.invalid]: (failureErrors.promoCode && !touched.promoCode) || (errors.promoCode && touched.promoCode)
          })} />
          {(failureErrors.promoCode) || (errors.promoCode) ? <OhSnap errorText={errors.promoCode || failureErrors.promoCode} /> : null}
          <div className={Styles.inputfailure}></div>
        </>}            
        <LoadingButton
          onClick={event => {submitForm(); event.preventDefault();}}
          disabled={dirty && !isValid}
          block
        >
          {existing ? "Save" : "Next"}
        </LoadingButton>
      </Form>
    );
  }
}

export const CustomerForm = withFormik<Props, CheckoutCustomerDataState>({
  mapPropsToValues: ({ existing, customerEmail, customerFirstName, customerLastName }) => existing || {
    email: customerEmail || "",
    firstName: customerFirstName || "",
    lastName: customerLastName || "",
    promoCode: ""
  },
  validationSchema: Yup.object().shape<CheckoutCustomerDataState>({
    email: Yup.string().label("Email")
      .email()
      .required()
      .max(255),
    firstName: Yup.string().label("First Name").required().max(20),
    lastName: Yup.string().label("Last Name").required().max(35),
    promoCode: Yup.string().label("Promo Code").max(35)
  }),
  handleSubmit: (values, { props }) => {
    props.submitAction(values);
  },
  enableReinitialize: true
})(InnerCustomerForm);

export default CustomerForm;
