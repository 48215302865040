import { PatternsState } from "./../store/state/patterns/index";
import { Reducer } from "redux";
import deepEqual from "deep-equal";
import { initialListingPatternsState } from "../store/state/patterns";
import { ListingPatternsActions } from "../actions/patterns";
import { ClaimStatus } from "../enums";

export const patternsReducer: Reducer<PatternsState, ListingPatternsActions> = (
  state = initialListingPatternsState,
  action
): PatternsState => {
  switch (action.type) {
    case "@@patterns/UPDATE_LISTING_PATTERNS":
      const listingPatternsNeedingUpdate = action.payload.filter(
        listingPattern => !deepEqual(listingPattern, state.items.find(item => item.listingPatternId === listingPattern.listingPatternId))
      );

      if (listingPatternsNeedingUpdate.length > 0) {
        return {
          ...state,
          items: [
            ...state.items.filter(item => !listingPatternsNeedingUpdate.some(updated => updated.listingPatternId === item.listingPatternId)),
            ...listingPatternsNeedingUpdate
          ]
        };
      } else {
        return state;
      }
    case "@@patterns/REMOVE_LISTING_PATTERNS":
      if (action.payload.some(id => state.items.some(item => item.listingPatternId === id))) {
        return {
          ...state,
          items: state.items
            .filter(listing => !action.payload.includes(listing.listingPatternId))
        };
      } else {
        return state;
      }

    case "@@patterns/MARK_QUANTITY_SOLD":
      return {
        ...state,
        items: state.items.map(pattern => ({
          ...pattern,
          listingDetails: pattern.listingDetails.map((listing) => {
            const claimedByYou = listing.claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou);

            if (action.payload.includes(listing.listingId) && claimedByYou) {
              const claims = listing.claimBySessions?.filter(claim => claim.claimStatus !== ClaimStatus.ClaimedByYou);

              return {
                ...listing,
                claimBySessions: [
                  ...claims,
                  {
                    ...claimedByYou,
                    isSold: true
                  }
                ]
              }
            } else {
              return listing;
            }
          }),
        })
      )}
      case "@@patterns/CLAIM_LISTING_QUANTITY":
        return {
          ...state,
          items: state.items.map(pattern => ({
            ...pattern,
            listingDetails: pattern.listingDetails.map((listing) => {
              if (action.payload.listingId === listing.listingId) {
                const claimedByYou = listing.claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou);

                if (claimedByYou) {
                  const claims = listing.claimBySessions?.filter(claim => claim.claimStatus !== ClaimStatus.ClaimedByYou);

                  return {
                    ...listing,
                    quantity: listing.quantity - 1,
                    claimBySessions: [
                      ...claims,
                      {
                        ...claimedByYou,
                        quantityClaimed: (claimedByYou?.quantityClaimed || 0) + 1,
                        expiresAt: action.payload.expirationDateTime,
                      }
                    ]
                  }
                } else {
                  return {
                    ...listing,
                    quantity: listing.quantity - 1,
                    claimBySessions: [
                      ...(listing.claimBySessions || []),
                      {
                        claimStatus: ClaimStatus.ClaimedByYou,
                        quantityClaimed: 1,
                        expiresAt: action.payload.expirationDateTime,
                      }
                    ]
                  }
                }
              } else {
                return listing;
              }
            }),
          }))
        }
      case "@@patterns/UNCLAIM_LISTING_QUANTITY":
        return {
          ...state,
          items: state.items.map(pattern => ({
            ...pattern,
            listingDetails: pattern.listingDetails.map((listing) => {
              const claimedByYou = listing.claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou);

              if (action.payload === listing.listingId && claimedByYou) {
                const listingQuantity = listing.quantity;
                const claims = listing.claimBySessions?.filter(claim => claim.claimStatus !== ClaimStatus.ClaimedByYou);

                return {
                  ...listing,
                  quantity: listingQuantity + 1,
                  claimBySessions: [
                    ...claims,
                    {
                      ...claimedByYou,
                      quantityClaimed: (claimedByYou?.quantityClaimed || 0) - 1
                    }
                  ]
                }
              } else {
                return listing;
              }
            })
          }))
        }
      case "@@patterns/UNCLAIM_ALL_LISTINGS":
        return {
          ...state,
          items: state.items.map(pattern => ({
            ...pattern,
            listingDetails: pattern.listingDetails.map((listing) => {
              const claimedByYou = listing.claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou);

              if (claimedByYou) {
                const listingQuantity = listing.quantity;
                const claims = listing.claimBySessions?.filter(claim => claim.claimStatus !== ClaimStatus.ClaimedByYou);

                return {
                  ...listing,
                  quantity: listingQuantity + (claimedByYou.quantityClaimed || 0),
                  claimBySessions: claims,
                }
              } else {
                return listing;
              }
            })
          }))
        }
    default:
      return state;
  }
};
