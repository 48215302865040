import { State } from "../store/state";

const youtubeRegex = /.*(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+).*/;
function getYoutubeIdFromUrl(url: string) {
  if (!url) return null;

  const match = url.match(youtubeRegex);
  if (match && match[1].length === 11) {
    return match[1];
  } else if (url.length === 11) {
    return url;
  } else {
    return null;
  }
}

export interface BioInfo {
  initials: string;
  type?: "soloBio";
  bioImageUrl?: string;
  name: string;
  ownerName?: string;
  biography?: string;
  alias: string;
  contactInformation: {
    phoneNumber?: string;
    emailAddress?: string;
  };
  socialMediaAccounts: {
    facebookAccount?: string;
    twitterAccount?: string;
    instagramAccount?: string;
    pinterestAccount?: string;
  };
  youtubeVideoId?: string | null;
}

export const buildBioInfo = (state: State): BioInfo => {
  const { store } = state;

  const ownerName = store.ownerName || "";
  const allInitials = ownerName
    .toUpperCase()
    .split(" ")
    .map(part => part[0]);
  const firstAndLast = allInitials.filter(
    (_initial, index) => index === 0 || index === allInitials.length - 1
  );

  return {
    ...store,
    contactInformation: store.contactInformation || {},
    socialMediaAccounts: store.socialMediaAccounts || {},
    youtubeVideoId:
      store.youtubeVideoId && getYoutubeIdFromUrl(store.youtubeVideoId),
    initials: firstAndLast.join("")
  };
};
