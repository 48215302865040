import "./polyfills";
import React from "react";
import { render } from "react-dom";
import BuildRoot from "./root";
import "./scss/index.scss";
// Redux Store
import { configureStore } from "./store";
import { getCartTokenFromQuery } from "./utilities/queryStrings";

const cartToken = getCartTokenFromQuery(window.location.search);

export const { store, history } = configureStore();

BuildRoot(cartToken).then(async Root => {
  render(
    <Root store={store} history={history} />,
    document.getElementById("root")
  )
});

// render(
//   <Root store={store} history={history} />,
//   document.getElementById("root")
// );
