import React, { Component, Fragment } from "react";
import { ExternalUrls } from "../../config";
import { YoutubePlayer } from "./YoutubePlayer/YoutubePlayer";
import Styles from "./RetailerBio.module.scss";
import { BioInfo } from "../../utilities/bio";

type OwnProps = BioInfo;
interface ComponentState {
  customerRegistrationUrl: string;
}

class RetailerBio extends Component<OwnProps, ComponentState> {
  unmounted = false;
  state = {
    customerRegistrationUrl: ""
  };

  async componentDidMount() {
    const { alias } = this.props;
    const registrationUrl = await ExternalUrls.CustomerRegistration(alias);

    !this.unmounted && this.setState({
      customerRegistrationUrl: registrationUrl
    })
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    const {
      youtubeVideoId,
      type,
      initials,
      bioImageUrl,
      biography,
      contactInformation,
      name,
      ownerName,
      socialMediaAccounts
    } = this.props;

    if (type === "soloBio") {
      return (
        <>
          <div className={Styles.floatingBio}>
            <img
              src={require("../../images/logo.png")}
              className={Styles.logoStyling}
              alt="LulaRoe logo"
            />
            {bioImageUrl ? (
              <div className={Styles.profileBorder}>
                <div className={Styles.imageFrame}>
                  <img
                    alt={`${name} profile`}
                    className={Styles.imageSolo}
                    src={bioImageUrl}
                  />
                </div>
              </div>
            ) : (
              <div className={Styles.noProfilePic}>
                <div className={Styles.initials}>{initials}</div>
              </div>
            )}
            <div className={Styles.ownerNameSolo}>{ownerName}</div>
            <div className={Styles.bioDetail}>
              LulaRoe Professional Retailer
            </div>
            {biography ? (
              <div
                className={Styles.registerDetail}
                style={{ maxWidth: "21.2rem" }}
              >
                {biography}
              </div>
            ) : (
              <div
                className={Styles.registerDetail}
                style={{ maxWidth: "21.5rem" }}
              >
                Register with me by clicking the Customer Registration button
              </div>
            )}
            {youtubeVideoId ? (
              <div className="mx-auto" style={{ maxWidth: "21.2rem" }}>
                <div className={Styles.video} style={{ marginBottom: "30px" }}>
                  <YoutubePlayer videoId={youtubeVideoId} />
                </div>
              </div>
            ) : null}
            <a
              style={{ marginBottom: "10px" }}
              className={`btn ${Styles.buttonSolo}`}
              href={this.state.customerRegistrationUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              Customer Registration
            </a>

            <div className="row">
              <div className="col-12">
                <h6
                  className={`mb-3 mt-5 text-center ${
                    Styles.contactInfoHeader
                  }`}
                >
                  Contact Information
                </h6>
              </div>
            </div>
            <div className="row">
              <div className={`col-12 mb-3 text-center ${Styles.iconRow}`}>
                {contactInformation.phoneNumber ? (
                  <a href={"tel:" + contactInformation.phoneNumber}>
                    <img
                      alt="phone icon"
                      className={Styles.socialLogo}
                      src={require("../../images/icon.phone.svg")}
                    />
                  </a>
                ) : null}
                {contactInformation.emailAddress ? (
                  <a href={"mailto:" + contactInformation.emailAddress}>
                    <img
                      alt="email icon"
                      className={Styles.socialLogo}
                      src={require("../../images/icon.email.svg")}
                    />
                  </a>
                ) : null}
                {socialMediaAccounts.facebookAccount ? (
                  <a
                    href={socialMediaAccounts.facebookAccount}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="facebook icon"
                      className={Styles.socialLogo}
                      src={require("../../images/icon.facebook.svg")}
                    />
                  </a>
                ) : null}
                {socialMediaAccounts.twitterAccount ? (
                  <a
                    href={socialMediaAccounts.twitterAccount}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="twitter icon"
                      className={Styles.socialLogo}
                      src={require("../../images/icon.twitter_Color.svg")}
                    />
                  </a>
                ) : null}
                {socialMediaAccounts.pinterestAccount ? (
                  <a
                    href={socialMediaAccounts.pinterestAccount}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="pintrest icon"
                      className={Styles.socialLogo}
                      src={require("../../images/icon.pinterest.svg")}
                    />
                  </a>
                ) : null}
                {socialMediaAccounts.instagramAccount ? (
                  <a
                    href={socialMediaAccounts.instagramAccount}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="instagram icon"
                      className={Styles.socialLogo}
                      src={require("../../images/icon.instagram_Color.png")}
                    />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <Fragment>
          <div className="row">
            <div className="col-12 pb-3">
              <h2 className={Styles.aboutHeader}>About</h2>
            </div>
          </div>
          <div className="row">
            {bioImageUrl ? (
              <div
                className={`col-12 col-sm-4 col-xl-3 ${
                  !youtubeVideoId ? Styles.profilePicMargin : null
                } pb-3`}
              >
                <div
                  className={youtubeVideoId ? "mx-auto" : ""}
                  style={{ maxWidth: "310px" }}
                >
                  <div className={Styles.imageFrame}>
                    <img
                      alt={`${name} profile`}
                      className={Styles.image}
                      src={bioImageUrl}
                    />
                  </div>
                </div>
              </div>
            ) : null}

            <div
              style={
                !bioImageUrl
                  ? !youtubeVideoId
                    ? { margin: "auto" }
                    : {}
                  : {}
              }
              className={`col-12 col-sm-8 ${
                !bioImageUrl
                  ? !youtubeVideoId
                    ? "col-xl-8"
                    : "col-xl-6"
                  : "col-xl-5"
              }  pb-3 ${Styles.bioFrame}`}
            >
              <h2 className={!bioImageUrl ? Styles.centerName : ""}>
                {ownerName}
              </h2>
              <p className={!bioImageUrl ? Styles.centerBio : ""}>
                {biography}
              </p>
              <div className={!bioImageUrl ? "text-center" : "text-right"}>
                <a
                  className={`btn ${Styles.button}`}
                  href={this.state.customerRegistrationUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Customer Registration
                </a>
              </div>
            </div>
            {youtubeVideoId ? (
              <div
                className={`col-12 ${
                  !bioImageUrl
                    ? youtubeVideoId
                      ? "col-xl-5"
                      : null
                    : "col-xl-4"
                } pb-3`}
              >
                <div className="mx-auto" style={{ maxWidth: "540px" }}>
                  <div className={Styles.video}>
                    <YoutubePlayer videoId={youtubeVideoId} />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Fragment>
      );
    }
  }
}

export default RetailerBio;
