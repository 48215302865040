import { Reducer } from "redux";
import { CheckoutState, initialCheckoutState } from "../store/state/checkout";
import { initialBillingState } from "../store/state/checkout/billing";
import { initialCustomerState } from "../store/state/checkout/customer";
import { CheckoutActions } from "../actions/checkout";
import { initialDeliveryState } from "../store/state/checkout/delivery";

export const checkoutReducer: Reducer<CheckoutState, CheckoutActions> = (
  state = initialCheckoutState,
  action
): CheckoutState => {
  switch (action.type) {
    // Billing form
    case "@@checkout/billing/CLEAR_STATE":
      return {
        ...state,
        billingInfo: initialBillingState(),
      };
    case "@@checkout/billing/MARK_EDITING":
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          editing: true,
          editingAddress: action.payload.editAddress,
          editingPayment: action.payload.editPayment,
        },
      };
    case "@@checkout/billing/DONE_EDITING":
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          editing: false,
          editingAddress: false,
          editingPayment: false,
        },
      };
    case "@@checkout/billing/MARK_FAILURE":
      return {
        ...state,
        billingInfo: {
          ...state.billingInfo,
          data: state.billingInfo.data
            ? {
                ...state.billingInfo.data,
                paymentToken: "",
              }
            : undefined,
          failed: true,
          editing: true,
          editingAddress: action.payload.addressFailure,
          // Always mark editingPayment as true, because our cryptograms
          // are one-time-use only!
          editingPayment: true,
          errors: action.payload.errors,
        },
      };
    case "@@checkout/billing/UPDATE_BILLING":
      let addressProvided =
        !!action.payload.line1 &&
        !!action.payload.city &&
        !!action.payload.state &&
        !!action.payload.zipCode;
      let paymentProvided = !!action.payload.paymentToken;
      return {
        ...state,
        billingInfo: {
          data: action.payload,
          editing: !addressProvided || !paymentProvided,
          editingAddress: !addressProvided,
          editingPayment: !paymentProvided,
          failed: false,
          errors: {},
        },
      };
    // Customer form
    case "@@checkout/customer/CLEAR_STATE":
      return {
        ...state,
        customerInfo: initialCustomerState(),
      };
    case "@@checkout/customer/MARK_EDITING":
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          editing: true,
        },
      };
    case "@@checkout/customer/DONE_EDITING":
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          editing: false,
        },
      };
    case "@@checkout/customer/MARK_FAILURE":
      return {
        ...state,
        customerInfo: {
          ...state.customerInfo,
          editing: true,
          failed: true,
          errors: action.payload.errors,
        },
      };
    case "@@checkout/customer/UPDATE_CUSTOMER":
      return {
        ...state,
        customerInfo: {
          data: action.payload,
          editing: false,
          failed: false,
          errors: {},
        },
      };
    case "@@checkout/customer/PRELOAD_EMAIL":
      return {
        ...state,
        customerEmail: action.payload,
      };
    case "@@checkout/customer/PRELOAD_NAME":
      return {
        ...state,
        customerFirstName: action.payload.split(" ").slice(0, -1).join(" "),
        customerLastName: action.payload.split(" ").slice(-1).join(" "),
      };
    case "@@checkout/customer/SAVE_FACEBOOK_ID":
      return {
        ...state,
        facebookId: action.payload,
      };
    // Delivery form
    case "@@checkout/delivery/CLEAR_STATE":
      return {
        ...state,
        deliveryInfo: initialDeliveryState(),
      };
    case "@@checkout/delivery/MARK_EDITING":
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          editing: true,
        },
      };
    case "@@checkout/delivery/DONE_EDITING":
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          editing: false,
        },
      };
    case "@@checkout/delivery/MARK_FAILURE":
      return {
        ...state,
        deliveryInfo: {
          ...state.deliveryInfo,
          editing: true,
          failed: true,
          errors: action.payload.errors,
        },
      };
    case "@@checkout/delivery/UPDATE_DELIVERY":
      return {
        ...state,
        deliveryInfo: {
          data: action.payload,
          editing: false,
          failed: false,
          errors: {},
        },
      };
    // Order estimation
    case "@@checkout/estimate/ESTIMATE_REQUEST":
      return {
        ...state,
        estimate: {
          request: action.payload,
          inProcess: true,
        },
      };
    case "@@checkout/estimate/ESTIMATE_RESPONSE_FAILURE":
      if (action.payload.promoCodeError !== undefined) {
        return {
          ...state,
          customerInfo: {
            ...state.customerInfo,
            editing: true,
          },
          estimate: {
            ...state.estimate,
            response: action.payload,
          },
        };
      } else {
        return {
          ...state,
          estimate: {
            ...state.estimate,
            response: action.payload,
          },
        };
      }
    case "@@checkout/estimate/ESTIMATE_RESPONSE_SUCCESS":
      return {
        ...state,
        estimate: {
          ...state.estimate,
          inProcess: false,
          response: action.payload,
        },
      };
    case "@@checkout/estimate/DISCOUNT_ESTIMATE":
      return {
        ...state,
        discountEstimate: action.payload.discountDollarAmount,
      };
    // Order creation
    case "@@checkout/order/ORDER_REQUEST":
      return {
        ...state,
        order: {
          request: action.payload,
          inProcess: true,
        },
      };
    case "@@checkout/order/ORDER_RESPONSE_FAILURE":
    case "@@checkout/order/ORDER_RESPONSE_SUCCESS":
      return {
        ...state,
        order: {
          ...state.order,
          inProcess: false,
          response: action.payload,
        },
      };
    // Shipping amount
    case "@@checkout/shipping/SHIPPING_REQUEST":
      return {
        ...state,
        shipping: {
          inProcess: true,
          request: action.payload,
        },
      };
    case "@@checkout/shipping/SHIPPING_RESPONSE_FAILURE":
    case "@@checkout/shipping/SHIPPING_RESPONSE_SUCCESS":
      return {
        ...state,
        shipping: {
          ...state.shipping,
          inProcess: false,
          response: action.payload,
        },
      };
    default:
      return state;
  }
};
