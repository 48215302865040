import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { PageUrls } from "../../config";
import LoadingSpinner from "../../components/LoadingSpinner";
import Styles from "./PatternDetailPage.module.scss";
import { Formatter } from "../../Formatter";
import { Link } from "react-router-dom";
import { SpecificPatternList } from "../../components/ProductList";
import BackIcon from "../../images/arrow-icon-back.svg";
// import { TrackItemView } from "../../analytics";
import ImageZoom from "react-medium-image-zoom";
import { State } from "../../store/state";
import { getAvailablePatterns, getAllPatterns, PatternItem, sortPatternListingsBySize } from "../../utilities/patterns";
import { getSuggestedListingPatterns } from "../../apis/listingsEndpoints";
import {
  getProductDescription,
  getProductSizeChart
} from "../../apis/productEndpoints";
import { SizeButtonGroup } from '../../components/SizeButtonGroup';
import { ProductManagerContext } from "../../components/ProductManager/ProductManagerContext";
import { PatternImage } from "../../apis/listingsEndpoints";
import { activeAlbumsSelector } from "../../utilities/albums";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import Lightbox from "react-images";
import "./lightbox.css";
import { ClaimContextType } from "../../enums/ClaimContextType";
import { TagCategoryDefs } from "../../enums/TagCategoryDefs";
import { ClaimStatus } from "../../enums";
import { NotFound } from "../../NotFound";

interface StateProps {
  isLoading: boolean;
  storeId: number;
  patternDetails?: PatternItem;
  breadcrumbPath: string;
  breadcrumbText: string;
  listed: boolean;
  storeAlias: string;
  claimStatus: ClaimStatus.Unclaimed | ClaimStatus.Claimed | ClaimStatus.ClaimedByYou;
}

interface OwnProps {
  patternId: number;
  albumId?: number;
}

interface ComponentState {
  suggestedListingPatternIds: number[];
  productDescription: string;
  backUrl?: string;
  backLinkText?: string;
  touchInput: boolean;
  displayPrice: number;
  selectedPrice: number;
  selectedItem: number;
  activeImageUrl: string;
  sizeChartUrl: string;
  listed: boolean;
  lightboxIsOpen: boolean;
  patternDetailSizeChart: string;
}

interface RouteParameters {
  storeName: string;
  id: string;
}

type Props = StateProps & OwnProps & ThunkDispatchProp;

class PatternDetailPage extends Component<Props, ComponentState> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  refreshing: boolean = false;
  defaultState: ComponentState = {
    suggestedListingPatternIds: [],
    productDescription: "",
    sizeChartUrl: "",
    listed: false,
    lightboxIsOpen: false,
    touchInput: false,
    displayPrice: 0,
    selectedPrice: 0,
    selectedItem: 0,
    activeImageUrl: "",
    patternDetailSizeChart: "",
  };

  setTouch = () => {
    this.setState({ touchInput: true });
  };

  constructor(props: Props) {
    super(props);
    this.openLightbox = this.openLightbox.bind(this);

    this.state = this.defaultState;
  }

  async componentDidMount() {
    const { patternDetails } = this.props;

    window.addEventListener("touchstart", this.setTouch);

    if (!patternDetails) {
      //await this.props.dispatch(fetchListingsByIds([this.props.listingId]));
    } else {
      await this.gatherPatternDetails(patternDetails);
    }
/*     this.props.itemDetails &&
      // TrackItemView(this.props.itemDetails, this.props.breadcrumbText); */
  }

  async componentDidUpdate(prevProps: Props) {
    const { patternDetails } = this.props;
    const { selectedItem } = this.state;

    if (selectedItem) {
      const listingsSizes = sortPatternListingsBySize(patternDetails?.listingDetails);

      if (!listingsSizes?.find(listing => listing.listingId === selectedItem)) {
        this.setState((state) => ({
          ...state,
          selectedItem: 0,
          selectedPrice: 0
        }))
      }
    }

    if (patternDetails && prevProps.patternDetails?.listingPatternId !== patternDetails.listingPatternId) {
      this.setState((state) => ({
        ...state,
        selectedItem: 0,
        selectedPrice: 0
      }))

      this.setState((state) => ({
        ...state,
        ...this.defaultState,
      }));

      await this.gatherPatternDetails(patternDetails);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("touchstart", this.setTouch);
  }

  async gatherPatternDetails(patternDetails: PatternItem) {
    let url: string;
    let description: string = '';

    const suggested = await getSuggestedListingPatterns(
      this.props.storeId,
      patternDetails.listingPatternId
    );

    if (patternDetails?.listingDetails.length > 0) {
      try {
        description = await getProductDescription(patternDetails?.listingDetails[0].productId);

        if (!description) description = ' ';
      } catch {
        description = ' ';
      }
    }

    const patternStyle = patternDetails.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Style);

    if (patternDetails?.listingDetails.length > 0 && patternStyle?.id !== undefined) {
      try {
        url = await getProductSizeChart(patternStyle.id);
      } catch {
        url = ""
      }
    }

    const primaryImageUrl = patternDetails.imageDetails.find((image) => (
      image.isPrimary
    ))?.imageUrl || patternDetails.imageDetails[0]?.imageUrl;

    this.setState((state) => ({
      ...state,
      suggestedListingPatternIds: suggested,
      productDescription: description,
      sizeChartUrl: url,
      patternDetailSizeChart: url,
      lightboxIsOpen: false,
      activeImageUrl: primaryImageUrl,
    }));
  }

  closeLightbox() {
    this.setState((state) => ({
      ...state,
      lightboxIsOpen: false,
      sizeChartUrl: state.patternDetailSizeChart,
    }));
  }

  openLightbox(styleId?: number) {
    if (styleId) {
      this.fetchSizeChartUrl(styleId)
    } else {
      this.setState((state) => ({
        ...state,
        lightboxIsOpen: true
      }));
    }
  }

  fetchSizeChartUrl = async (styleId: number) => {
    const url = await getProductSizeChart(styleId);
    this.setState((state) => ({
      ...state,
      lightboxIsOpen: true,
      sizeChartUrl: url
    }));
  };

  thumbnailImages = (image: PatternImage, index: number) => (
    <a
      key={image.imageMapId}
      href={`#${index}`}
      className={Styles.thumbnailImageWrapper}
      onClick={(e) => {
        e.preventDefault();
        this.setState((currentState) => ({
          ...currentState,
          activeImageUrl: image.imageUrl
        }));
      }}
    >
      <img
        className={Styles.thumbnailImg}
        alt={`thumbnail ${image.imageMapId}`}
        src={image.imageUrl}
      />
    </a>
  );

  displayPrice = (price: number) => {
    this.setState({
      displayPrice: price
    })
  }

  hidePrice = () => {
      this.setState({
        displayPrice: 0
      })
  }

  setSelectedItem = (listingId: number, listingPrice: number) => {
    this.setState({
      selectedItem: listingId,
      selectedPrice: listingPrice
    })
  }

  render() {
    const {
      isLoading,
      patternDetails,
      breadcrumbPath,
      breadcrumbText,
      albumId,
      claimStatus
    } = this.props;
    const {
      productDescription,
      suggestedListingPatternIds,
      activeImageUrl,
      sizeChartUrl,
      selectedItem,
      touchInput,
      lightboxIsOpen,
      selectedPrice,
      displayPrice
    } = this.state;


    if (patternDetails && patternDetails.listingDetails.length > 0 && activeImageUrl && productDescription) {
      const listingsSizes = sortPatternListingsBySize(patternDetails?.listingDetails);

      return (
        <div className={Styles.item_detail}>
          <div className={Styles.breadcrumb}>
            <Link to={breadcrumbPath}>
              <img
                src={BackIcon}
                alt="&#8592;"
                className={Styles.breadcrumbArrow}
              />
              {breadcrumbText}
            </Link>
          </div>
          <div className="row">
            <h3 className={`col-12 ${Styles.header}`}>Item detail</h3>
          </div>
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4 mb-5">
              {activeImageUrl !== "" ? (
                <div className={Styles.productImageArea}>
                  <div className={Styles.productImgWrapper}>
                    <ImageZoom
                      defaultStyles={{
                        overlay: { backgroundColor: "black", opacity: "0.4" }
                      }}
                      image={{
                        className: Styles.productImg,
                        alt: "Product Image",
                        src: activeImageUrl
                      }}
                      // Only include a large image if it's different than our standard one.
                      zoomImage={ {
                              src: activeImageUrl,
                              alt: "Large Product Image"
                            }
                      }
                      shouldHandleZoom={() => !touchInput}
                    />
                  </div>
                  {patternDetails?.imageDetails && patternDetails.imageDetails.length > 1 ? (
                    <div className={`row ${Styles.thumbnailImagesWrapper}`}>
                      {patternDetails?.imageDetails.map(this.thumbnailImages)}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="col-12 col-sm-6 col-lg-5 mb-5">
              <div className={Styles.productDescriptionArea}>
                <h2 className={Styles.productTitle}>{patternDetails?.styleDisplayName}</h2>
                <p className={Styles.productPrice}>
                  {
                    patternDetails?.minimumPrice &&
                    patternDetails.maximumPrice &&
                    patternDetails.displayPriceRange
                  }
                </p>
                <p
                  className={Styles.productDescription}
                  dangerouslySetInnerHTML={{
                    __html: productDescription.replace(
                      /(?:\\[rn])+/g,
                      "<br />"
                    )
                  }}
                >
                </p>
                <div style={{ marginBottom: ".5rem" }}>
                  {sizeChartUrl && sizeChartUrl !== "" && (
                    <>
                      <span style={{ fontWeight: 600 }}>PLEASE SELECT SIZE:</span>
                      <span
                        className={Styles.sizeGuide}
                        onClick={() => this.openLightbox()}
                      >
                        Size Guide
                      </span>
                      {sizeChartUrl && (
                        <Lightbox
                          images={[{ src: sizeChartUrl }]}
                          isOpen={lightboxIsOpen}
                          onClose={() => this.closeLightbox()}
                          backdropClosesModal={true}
                          showCloseButton={false}
                          showImageCount={false}
                          preventScroll={false}
                        />
                      )}
                    </>
                  )}
                </div>
                <div>
                  <SizeButtonGroup
                    patternListings={listingsSizes}
                    selectedListing={selectedItem}
                    onMouseEnter={this.displayPrice}
                    onMouseLeave={this.hidePrice}
                    onClick={this.setSelectedItem}
                  />
                </div>

                <div style={{marginTop: '10px', marginBottom: '5px'}}>
                {listingsSizes && listingsSizes.length > 0 ? (
                  selectedPrice !== 0 && displayPrice === 0 ? Formatter.format(selectedPrice) :
                    patternDetails?.minimumPrice && patternDetails.maximumPrice &&
                    <div className={Styles.itemCardText}>{displayPrice === 0 ?
                      patternDetails.displayPriceRange : Formatter.format(displayPrice)}
                    </div>
                  ) : (
                    claimStatus === ClaimStatus.Claimed ? (
                      <p className={Styles.productClaimedMessage}>{'We are sorry, this item has already been claimed by someone else :('}</p>
                    ) : (
                      <></>
                    )
                  )
                }
                </div>

                {listingsSizes && listingsSizes.length > 0 ? (
                  <button
                    className={`btn ${Styles.add_to_bag}`}
                    disabled={selectedItem === 0}
                    onClick={(e) => {
                        if (albumId) {
                          this.context.addListingToCart(
                            selectedItem,
                            breadcrumbText,
                            ClaimContextType.ListingGroup,
                            albumId
                          );
                        } else {
                          this.context.addListingToCart(selectedItem, breadcrumbText);
                        }
                      }}
                  >
                    Add To My Bag
                  </button>) : (
                    <button
                      className={`btn ${Styles.disabled}`}
                      disabled
                    >
                      {claimStatus === ClaimStatus.ClaimedByYou ? 'In Your Bag' : 'Add To My Bag'}
                    </button>
                  )
                }
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-5">
                <p>More looks you&apos;ll love...</p>
                {suggestedListingPatternIds.length > 0 ? (
                <SpecificPatternList
                  showFilters={false}
                  listingPatternIds={suggestedListingPatternIds}
                  //soldItems={false}
                  itemClassName="suggestedProductList"
                  listName="Suggested"
                  sizeChartOpen={lightboxIsOpen}
                  openSizeChart={this.openLightbox}
                />
              ) : null}
            </div>
          </div>
        </div>
      );
    } else if (patternDetails && patternDetails.listingDetails.length > 0) {
      return <LoadingSpinner />;
    } else if (!isLoading) {
      return <NotFound />;
    }
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  ownProps
) => {
  let claimStatus = ClaimStatus.Unclaimed;

  let item = getAvailablePatterns(state).find(
    (pattern) => pattern.listingPatternId === ownProps.patternId
  );

  if (!item) {
    item = getAllPatterns(state).find(
      (pattern) => pattern.listingPatternId === ownProps.patternId
    );
  }

  const isClaimed = item?.listingDetails.find((listing) => (listing.claimBySessions?.length || 0) > 0)

  if (item && isClaimed) {
    const claimedByYou = item?.listingDetails
      .find((listing) => listing.claimBySessions?.find((claim) => claim.claimStatus === ClaimStatus.ClaimedByYou))

    if (claimedByYou) {
      claimStatus = ClaimStatus.ClaimedByYou;
    } else {
      claimStatus = ClaimStatus.Claimed;
    }
  }

  const activeAlbums = activeAlbumsSelector(state);
  // const activeAlbumIds = activeAlbums.map((album) => album.id);
  const listed = true;
  const lastAlbum =
    ownProps.albumId &&
    activeAlbums.find((album) => album.id === ownProps.albumId);

  let breadcrumbPath;
  let breadcrumbText;
  if (lastAlbum) {
    breadcrumbPath = PageUrls.AlbumDetailsPage(state.store.alias, lastAlbum.id);
    breadcrumbText = lastAlbum.title;
  } else {
    breadcrumbPath = PageUrls.AlbumsPage(state.store.alias);
    breadcrumbText = "Shop Albums";
  }
  return {
    isLoading: state.app.loading,
    patternDetails: item,
    storeId: state.store.id,
    breadcrumbPath,
    breadcrumbText,
    listed,
    storeAlias: state.store.alias,
    claimStatus
  };
};

export default connect(mapStateToProps)(PatternDetailPage);
