import axios from "axios";
import { storesApiBase } from "../config";

const setCartFromTokenEndpoint = (storeId: number, cartToken: string) =>
  `${storesApiBase}/${storeId}/cart/redeem?cartToken=${cartToken}`;

const setCartFromPreviousSessionEndpoint = (
  storeId: number,
  previousSessionId: string
) =>
  `${storesApiBase}/${storeId}/cart/restore?previousSessionId=${previousSessionId}`;

const emptySessionCartEndpoint = (storeId: number) => `${storesApiBase}/${storeId}/cart/empty`;

export interface SetCartResponse {
  isTokenValid: boolean;
  unsuccessfulClaims: string[];
  successfulClaims: string[];
}

export async function setCartFromToken(
  storeId: number,
  cartToken: string
): Promise<SetCartResponse> {
  return axios
    .post(setCartFromTokenEndpoint(storeId, cartToken))
    .then<SetCartResponse>((response) => Promise.resolve(response.data));
}

export async function setCartFromPreviousSession(
  storeId: number,
  previousSessionId: string
): Promise<SetCartResponse> {
  return axios
    .post(setCartFromPreviousSessionEndpoint(storeId, previousSessionId))
    .then<SetCartResponse>((response) => Promise.resolve(response.data));
}

export const emptySessionCart = async (storeId: number): Promise<void> => (
  axios.post(emptySessionCartEndpoint(storeId))
    .then<void>((response) => Promise.resolve(response.data))
    .catch(console.error)
)
