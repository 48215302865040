import React from "react";
import { node, string } from "prop-types";
import classNames from "classnames";

import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import Styles from "./Chat.module.scss";

const Chat = ({ children, className, ...props }) => (
  <div className={classNames(Styles.mainContainer, className)} {...props}>
    {children}
  </div>
);

Chat.propTypes = {
  children: node.isRequired,
  className: string
};

Chat.Header = ChatHeader;
Chat.Body = ChatBody;

export default Chat;
