import { Component, ReactNode } from "react";
import { connect, MapStateToProps } from "react-redux";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import { State } from "../../store/state";
import {
  CartListingType,
  getClaimedListings,
  cartExpiration
} from "../../utilities/cart";
import { createAggregateDebounce } from "../../utilities/aggregateDebounce";
import { CreatedOrder } from "../../store/state/checkout/order";
import { getCreatedOrder } from "../../utilities/checkout";
import { emptySessionCart } from '../../apis/cartEndpoints';
import { ProductManagerContext } from "./ProductManagerContext";
import { ClaimStatus } from "enums";

interface StateProps {
  cartListings: CartListingType[];
  expiration?: Date;
  createdOrder: CreatedOrder | null;
  storeId: number;
}

interface OwnProps {
  notify: (message: string) => void;
  children?: ReactNode;
}

type Props = StateProps & OwnProps & ThunkDispatchProp;

export class CartChangesHandler extends Component<Props> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  cartExpirationTimer = 0;

  render() {
    return null;
  }

  componentDidMount() {
    this.props.expiration && this.setCartExpirationTimer(this.props.expiration);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.expiration !== prevProps.expiration) {
      this.setCartExpirationTimer(this.props.expiration || null);
    }

    this.detectAddedListings(prevProps);
    this.detectRemovedListings(prevProps);
  }

  componentWillUnmount() {
    clearTimeout(this.cartExpirationTimer);
    this.notifyAddedItems.destroy();
    this.notifyRemovedItems.destroy();
  }

  emptyCart = () => {
    this.props.dispatch({ type: "@@patterns/UNCLAIM_ALL_LISTINGS"})

    void emptySessionCart(this.props.storeId);
  }

  setCartExpirationTimer = (date: Date | null) => {
    clearTimeout(this.cartExpirationTimer);
    delete this.cartExpirationTimer;

    if (date) {
      const ticksLeft = date.getTime() - Date.now();
      if (ticksLeft > 0 && ticksLeft < 2147483647) {
        this.cartExpirationTimer = window.setTimeout(() => {
          this.emptyCart();

          this.props.notify("Time's up!");
        }, ticksLeft);
      }
    }
  };

  onlyInFirstSet = <TObject, TKey>(
    a: TObject[],
    b: TObject[],
    descriminator: (object: TObject) => TKey
  ): TObject[] => {
    const firstKeys = a.map(descriminator);
    const secondKeys = b.map(descriminator);

    const keysOnlyInFirst = firstKeys.filter((key) => !secondKeys.find((val) => val === key));

    const keysInBothSets = firstKeys
      .filter((key) => secondKeys.find((val) => val === key))
      .filter((key, index, keys) => keys.indexOf(key) === index);

    keysInBothSets.forEach((key) => {
      const quantityInFirstSet = firstKeys.reduce((a, v) => (v === key ? a + 1 : a), 0);
      const quantityInSecondSet = secondKeys.reduce((a, v) => (v === key ? a + 1 : a), 0);

      if (quantityInFirstSet > quantityInSecondSet) {
        for (let i = 0; i < quantityInFirstSet - quantityInSecondSet; i++) keysOnlyInFirst.push(key);
      }
    });

    return a.reduce<TObject[]>((acc, curr) => {
      const keyIndex = keysOnlyInFirst.findIndex((key) => key === descriminator(curr));

      if (!(keyIndex >= 0)) return acc;

      keysOnlyInFirst.splice(keyIndex, 1);

      return acc.concat(curr);
    }, []);
  };

  notifyAddedItems = createAggregateDebounce<number>(
    (count) => {
      this.props.notify(
        count === 1 ? "Item added to bag!" : `${count} items added to bag!`
      );
    },
    (agg, cur) => agg + cur,
    0,
    500
  );

  notifyRemovedItems = createAggregateDebounce<number>(
    (count) => {
      this.props.notify(
        count === 1
          ? "Item removed from bag!"
          : `${count} items removed from bag!`
      );
    },
    (agg, cur) => agg + cur,
    0,
    500
  );

  spreadCartListings = (cartListings: CartListingType[]) => (
    cartListings.reduce<CartListingType[]>((acc, curr) => {
      const quantityClaimed = curr.claimBySessions.find((session) => (
        session.claimStatus === ClaimStatus.ClaimedByYou
      ))?.quantityClaimed || 0;

      for (let i = 0; i < quantityClaimed; i++) acc.push(curr);

      return acc;
    }, [])
  );

  detectAddedListings = (prevProps: Props) => {
    const quantityClaimed = this.props.cartListings.reduce((acc, curr) => (
      acc + (
        curr.claimBySessions.find((session) => (
          session.claimStatus === ClaimStatus.ClaimedByYou
        ))?.quantityClaimed || 0
      )
    ), 0);
    const prevQuantityClaimed = prevProps.cartListings.reduce((acc, curr) => (
      acc + (
        curr.claimBySessions.find((session) => (
          session.claimStatus === ClaimStatus.ClaimedByYou
        ))?.quantityClaimed || 0
      )
    ), 0);

    if (this.props.cartListings !== prevProps.cartListings || quantityClaimed !== prevQuantityClaimed) {
      const newIds = this.onlyInFirstSet(
        this.spreadCartListings(this.props.cartListings),
        this.spreadCartListings(prevProps.cartListings),
        (listing) => listing.listingId
      );
      if (newIds.length) {
        this.notifyAddedItems(newIds.length);
      }
    }
  };

  detectRemovedListings = (prevProps: Props) => {
    if (this.props.cartListings !== prevProps.cartListings) {
      const purchasedIds = this.props.createdOrder
        ? this.props.createdOrder.listingIds
        : [];
      const removedListings = this.onlyInFirstSet(
        this.spreadCartListings(prevProps.cartListings),
        this.spreadCartListings(this.props.cartListings),
        (listing) => listing.listingId
      );

      const removedButNotPurchased = removedListings.filter(
        (listing) => !purchasedIds.includes(listing.listingId)
      );
      if (removedButNotPurchased.length) {
        this.notifyRemovedItems(removedButNotPurchased.length);
      }
    }
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state
) => {
  return {
    cartListings: getClaimedListings(state),
    expiration: cartExpiration(state),
    createdOrder: getCreatedOrder(state),
    storeId: state.store.id,
  };
};

export default connect(mapStateToProps)(CartChangesHandler);
