import React, { Fragment, FunctionComponent } from "react";
import { connect, MapStateToProps } from "react-redux";
import RetailerBio from "../../components/RetailerBio/RetailerBio";
import HomepageAlbums from "../../components/HomepageAlbums/HomepageAlbums";
import LiveEventsBanner from '../../components/LiveEventsBanner';
import { activeAlbumsSelector } from "../../utilities/albums";
import { activeBannerImagesSelector } from "../../utilities/stores";
import { PageUrls } from "../../config";
import { State } from "../../store/state";
import { AlbumState } from "../../store/state/albums";
import { buildBioInfo, BioInfo } from "../../utilities/bio";
import Styles from "./HomePage.module.scss";
//import Carousel from "react-bootstrap/Carousel";
import { StoreBannerImage } from "store/state/banners";
import "./styles.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

interface StateProps {
  bio: BioInfo;
  albums: {
    albumsLink: string;
    storeAlias: string;
    list: AlbumState[];
  };
  banners?: { list: StoreBannerImage[] };
}

export const HomePage: FunctionComponent<StateProps> = ({
  bio,
  albums,
  banners
}) => (
  <Fragment>
    {banners && banners.list.length > 0 && (
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        swipeable={true}
        showThumbs={false}
        stopOnHover={false}
      >
        {banners.list.map((banner) => {
          return (
            <a href={banner.redirectUrl} key={banner.displayOrder}>
              <div
                className={Styles.banner}
                style={{
                  backgroundImage: `url(${banner.imageUrl})`
                }}
              >
                {(banner.title || banner.subtitle) && (
                  <>
                    <p className={Styles.title}>{banner.title}</p>
                    <p className={Styles.subtitle}>{banner.subtitle}</p>
                  </>
                )}
              </div>
            </a>
          );
        })}
      </Carousel>
    )}

    <LiveEventsBanner />

    {albums.list.length > 0 && (
      <>
        <h2 className={Styles.header}>Albums</h2>
        <HomepageAlbums {...albums} />
      </>
    )}
    <RetailerBio {...bio} />
  </Fragment>
);

const mapStateToProps: MapStateToProps<StateProps, void, State> = (
  state: State
) => {
  return {
    bio: buildBioInfo(state),
    albums: {
      albumsLink: PageUrls.AlbumsPage(state.store.alias),
      storeAlias: state.store.alias,
      list: activeAlbumsSelector(state)
    },
    banners: {
      list: activeBannerImagesSelector(state)
    }
  };
};

export default connect(mapStateToProps)(HomePage);
