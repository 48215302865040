import { ListingPatternModel } from '../apis/listingsEndpoints';
import {
  ListingsStorageKey,
  ListingsPersistWipeIntervalSeconds
} from "../config";
import { ClaimStatus } from "../enums";

// If we ever need to change the way the cache behaves,
// we should increment this value to instruct browsers with existing
// caches to clear their local storage.
export const currentCacheVersion = "4.1";

enum ListingsCacheClaimStatus {
  Unclaimed = "U",
  Claimed = "C",
  ClaimedByYou = "Y"
}

interface PatternImageCache {
  imid: number; // imageMapId
  u: string; // imageUrl
  p: boolean; // isPrimary
  v: number; // viewingOrder
}

interface ListingPatternTagDetailsCache {
  id: number; // id
  n: string; // name
  c?: number; // code
  s: number; // sortOrder
  tcid: number; // tagCategoryId
}

interface ClaimsCache {
  cs: ListingsCacheClaimStatus; // claimStatus
  q: number; // quantity
  e?: string; // expiresAt
  s?: boolean; // isSold
}

interface PatternListingDetailsCache {
  lid: number; // listingId
  pid: number; // productId
  q: number; // quantity
  s: number; // salePrice
  m: number; // msrp
  td: ListingPatternTagDetailsCache[]; // tagDetails
  c: ClaimsCache[];
  ptd: ListingPatternTagDetailsCache[]; // patternTagDetails
  sdn: string; // styleDisplayName
}

interface PatternsCacheItem {
  pid: number; // patternId
  r: boolean; //reservedForLiveSelling
  i: PatternImageCache[]; // imageDetails
  ld: PatternListingDetailsCache[]; // listingDetails
  led: { // liveEventDetails
    id: number; // liveEventId
    pdt: string; // publishedDateTime
  }[];
  lgid: number[]; // listingGroupIds
  sdn: string; // styleDisplayName
  td: ListingPatternTagDetailsCache[]; // tagDetails
}

interface PatternsCachePhysicalStructure {
  patterns: PatternsCacheItem[];
  lastChangeId: string;
  initialized: string;
  cacheVersion: string;
}

interface PatternsCacheLogicalStructure {
  patterns: ListingPatternModel[];
  lastChangeId: string;
  initialized: string;
  cacheVersion: string;
}

function MapCacheItemToState(cacheItem: PatternsCacheItem): ListingPatternModel {
  return {
    listingPatternId: cacheItem.pid,
    reservedForLiveSelling: cacheItem.r,
    imageDetails: cacheItem.i?.map((image) => ({
      imageMapId: image.imid,
      imageUrl: image.u,
      isPrimary: image.p,
      viewingOrder: image.v,
    })),
    listingDetails: cacheItem.ld?.map((listing) => ({
      listingId: listing.lid,
      productId: listing.pid,
      quantity: listing.q,
      salePrice: listing.s,
      msrp: listing.m,
      patternTagDetails: listing.ptd?.map((cacheTag) => ({
        id: cacheTag.id,
        name: cacheTag.n,
        code: cacheTag.c,
        sortOrder: cacheTag.s,
        tagCategoryId: cacheTag.tcid,
      })),
      styleDisplayName: listing.sdn,
      tagDetails: listing.td?.map((cacheTag) => ({
        id: cacheTag.id,
        name: cacheTag.n,
        code: cacheTag.c,
        sortOrder: cacheTag.s,
        tagCategoryId: cacheTag.tcid,
      })),
      claimBySessions: listing.c?.map((cacheClaim) => {
        let listingClaimStatus: ClaimStatus;
        switch (cacheClaim.cs) {
          case ListingsCacheClaimStatus.Claimed:
            listingClaimStatus = ClaimStatus.Claimed;
            break;
          case ListingsCacheClaimStatus.ClaimedByYou:
            listingClaimStatus = ClaimStatus.ClaimedByYou;
            break;
          case ListingsCacheClaimStatus.Unclaimed:
          default:
            listingClaimStatus = ClaimStatus.Unclaimed;
            break;
        }

        return {
          claimStatus: listingClaimStatus,
          quantityClaimed: cacheClaim.q,
          expiresAt: cacheClaim.e,
          isSold: cacheClaim.s,
        }
      }),
    })),
    liveEventDetails: cacheItem.led?.map((event) => ({
      liveEventId: event.id,
      publishedDateTime: event.pdt,
    })),
    listingGroupIds: cacheItem.lgid,
    styleDisplayName: cacheItem.sdn,
    tagDetails: cacheItem.td?.map((cacheTag) => ({
      id: cacheTag.id,
      name: cacheTag.n,
      code: cacheTag.c,
      sortOrder: cacheTag.s,
      tagCategoryId: cacheTag.tcid,
    })),
  };
}

function MapStateToCacheItem(stateItem: ListingPatternModel): PatternsCacheItem {
  return {
    pid: stateItem.listingPatternId,
    r: stateItem.reservedForLiveSelling,
    i: stateItem.imageDetails?.map((stateImage) => ({
      imid: stateImage.imageMapId,
      u: stateImage.imageUrl,
      p: stateImage.isPrimary,
      v: stateImage.viewingOrder,
    })),
    ld: stateItem.listingDetails?.map((stateListing) => ({
      lid: stateListing.listingId,
      pid: stateListing.productId,
      q: stateListing.quantity,
      s: stateListing.salePrice,
      m: stateListing.msrp,
      ptd: stateListing.patternTagDetails?.map(stateTag => ({
        id: stateTag.id,
        n: stateTag.name,
        s: stateTag.sortOrder,
        tcid: stateTag.tagCategoryId,
      })),
      sdn: stateListing.styleDisplayName,
      td: stateListing.tagDetails?.map((stateTag) => ({
        id: stateTag.id,
        n: stateTag.name,
        s: stateTag.sortOrder,
        tcid: stateTag.tagCategoryId,
      })),
      c: stateListing.claimBySessions?.map((stateClaim) => {
        let listingClaimStatus: ListingsCacheClaimStatus;
        switch (stateClaim.claimStatus) {
          case ClaimStatus.Claimed:
            listingClaimStatus = ListingsCacheClaimStatus.Claimed;
            break;
          case ClaimStatus.ClaimedByYou:
            listingClaimStatus = ListingsCacheClaimStatus.ClaimedByYou;
            break;
          case ClaimStatus.Unclaimed:
          default:
            listingClaimStatus = ListingsCacheClaimStatus.Unclaimed;
            break;
        }

        return {
          cs: listingClaimStatus,
          q: stateClaim.quantityClaimed,
          e: stateClaim.expiresAt,
          s: stateClaim.isSold,
        }
      }),
    })),
    led: stateItem.liveEventDetails?.map((stateEvent) => ({
      id: stateEvent.liveEventId,
      pdt: stateEvent.publishedDateTime,
    })),
    lgid: stateItem.listingGroupIds,
    sdn: stateItem.styleDisplayName,
    td: stateItem.tagDetails?.map((stateTag) => ({
      id: stateTag.id,
      n: stateTag.name,
      s: stateTag.sortOrder,
      tcid: stateTag.tagCategoryId,
    })),
  };
}

export async function getPatternsFromStorage(
  storeId: number,
): Promise<PatternsCacheLogicalStructure | null> {
  if (!localStorage) {
    return null;
  }
  const storageKey = ListingsStorageKey(storeId);
  const existing = JSON.parse(
    localStorage.getItem(storageKey) || "null"
  ) as PatternsCachePhysicalStructure;
  if (existing) {
    let cacheVersion = currentCacheVersion;

    if (existing.cacheVersion === cacheVersion) {
      const diff = Date.now() - Date.parse(existing.initialized);
      if (diff < (await ListingsPersistWipeIntervalSeconds()) * 1000) {
        // Cache is exists and is still valid.
        return {
          ...existing,
          patterns: existing.patterns.map(MapCacheItemToState)
        };
      } else {
        localStorage.removeItem(storageKey);
      }
    } else {
      localStorage.clear();
    }
  }
  return null;
}

let tooBig = false;

export function persistPatterns(
  storeId: number,
  values: PatternsCacheLogicalStructure
) {
  if (localStorage && !tooBig) {
    try {
      const storageKey = ListingsStorageKey(storeId);
      const physicalStorageRecord: PatternsCachePhysicalStructure = {
        cacheVersion: values.cacheVersion,
        initialized: values.initialized,
        lastChangeId: values.lastChangeId,
        patterns: values.patterns.map(MapStateToCacheItem)
      };

      localStorage.setItem(storageKey, JSON.stringify(physicalStorageRecord));
    } catch (e) {
      tooBig = true;
      console.error("Cannot cache patterns", e);
    }
  }
}

export async function updatePersistedPatterns(
  storeId: number,
  patterns: ListingPatternModel[]
) {
  if (!localStorage) {
    return;
  }
  const current = await getPatternsFromStorage(storeId);
  if (!current) {
    return;
  }
  persistPatterns(storeId, {
    ...current,
    patterns: patterns
  });
}
