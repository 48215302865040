import React from "react";
import { ClaimContextType } from "../../enums/ClaimContextType";

export interface ListingsChangeEvent {
  listingIds: number[];
}

export interface ListingPatternsChangeEvent {
  listingPatternIds: number[];
}

export interface ListingGroupsChangeEvent {
  listingGroupIds: number[];
}

export interface LiveEventsChangeEvent {
  eventIds: number[];
}

export type ListingsChangeCallback = (event: ListingsChangeEvent) => void;
export type ListingPatternsChangeCallback = (event: ListingPatternsChangeEvent) => void;
export type ListingGroupsChangeCallback = (
  event: ListingGroupsChangeEvent
) => void;
export type LiveEventsChangeCallback = (event: LiveEventsChangeEvent) => void;

export interface ProductManagerContextState {
  signalListingChange(event: ListingsChangeEvent): void;
  subscribeToListingChanges(callback: ListingsChangeCallback): number;
  unsubscribeFromListingChanges(handle: number): void;

  signalListingPatternChange(event: ListingPatternsChangeEvent): void;
  subscribeToListingPatternChanges(callback: ListingPatternsChangeCallback): number;
  unsubscribeFromListingPatternChanges(handle: number): void;

  signalListingGroupChange(event: ListingGroupsChangeEvent): void;
  subscribeToListingGroupChanges(callback: ListingGroupsChangeCallback): number;
  unsubscribeFromListingGroupChanges(handle: number): void;

  signalLiveEventsChange(event: LiveEventsChangeEvent): void;
  subscribeToLiveEventsChanges(callback: LiveEventsChangeCallback): number;
  unsubscribeFromLiveEventsChanges(handle: number): void;

  addListingToCart(
    id: number,
    listName: string,
    context?: ClaimContextType,
    contextId?: number
  ): Promise<void>;
  removeListingFromCart(id: number): Promise<void>;
}

const defaultValue: ProductManagerContextState = {
  addListingToCart: () => Promise.reject(),
  removeListingFromCart: () => Promise.reject(),
  signalListingChange: () => {},
  signalListingPatternChange: () => {},
  signalListingGroupChange: () => {},
  subscribeToListingChanges: () => 0,
  subscribeToListingPatternChanges: () => 0,
  subscribeToListingGroupChanges: () => 0,
  unsubscribeFromListingChanges: () => {},
  unsubscribeFromListingPatternChanges: () => {},
  unsubscribeFromListingGroupChanges: () => {},
  signalLiveEventsChange: () => {},
  subscribeToLiveEventsChanges: () => 0,
  unsubscribeFromLiveEventsChanges: () => {}
};

export const ProductManagerContext = React.createContext<
  ProductManagerContextState
>(defaultValue);
