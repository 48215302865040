import { TimerContext } from "./TimerContext";
import { DurationUnit } from "luxon";
import { UnitsDescending } from "./UnitLists";
import React, { Component, ContextType, FunctionComponent, ReactNode } from "react";

interface TimerSwitchFullProps {
  unit: DurationUnit;
  cascade?: boolean;
  children?: {
    active?: ReactNode;
    inactive?: ReactNode;
  };
}
export class TimerUnitSwitch extends Component<TimerSwitchFullProps> {
  static contextType = TimerContext;
  context!: ContextType<typeof TimerContext>;
  static defaultProps: Partial<TimerSwitchFullProps> = {
    cascade: true
  };
  readonly props!: Readonly<TimerSwitchFullProps>;

  isUnitActive() {
    const { isActive, units, duration } = this.context;
    const { unit, cascade } = this.props;
    if (!isActive || !units || !duration) {
      return false;
    }
    // Our timer wasn't even set up to track this unit!
    if (duration.get(unit) > 0) {
      return true;
    }
    // Return true if any unit bigger than our unit has a value
    if (cascade) {
      const biggerUnits = UnitsDescending.slice(
        0,
        UnitsDescending.indexOf(unit)
      );
      return biggerUnits.some(bigUnit => duration.get(bigUnit) > 0);
    }
  }


  shouldComponentUpdate(nextProps: any, _nextState: any, nextContext: ContextType<typeof TimerContext>) {
    if (!this.context || !this.context.duration || !nextContext || !nextContext.duration) { return true; }
    return this.context.duration.get(this.props.unit) !== nextContext.duration.get(nextProps.unit);
  }

  render() {
    if (!this.context) {
      console.error(
        "Tried to use a timer unit switch outside of a timer component!"
      );
      return null;
    }
    if (this.isUnitActive()) {
      if (
        typeof this.props.children === "object" &&
        "active" in this.props.children
      ) {
        return this.props.children.active;
      }
    } else {
      if (
        typeof this.props.children === "object" &&
        "inactive" in this.props.children
      ) {
        return this.props.children.inactive;
      }
    }
    return null;
  }
}

export const SecondsActive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ active: children }} unit={"seconds"} />
);
export const SecondsInactive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ inactive: children }} unit={"seconds"} />
);

export const MinutesActive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ active: children }} unit={"minutes"} />
);
export const MinutesInactive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ inactive: children }} unit={"minutes"} />
);

export const HoursActive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ active: children }} unit={"hours"} />
);
export const HoursInactive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ inactive: children }} unit={"hours"} />
);

export const DaysActive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ active: children }} unit={"days"} />
);
export const DaysInactive: FunctionComponent<{
  children?: ReactNode;
  cascade?: boolean;
}> = ({ children }) => (
  <TimerUnitSwitch children={{ inactive: children }} unit={"days"} />
);
