import React, { Component } from "react";
import Styles from "./PayForm.module.scss";
import VisaImg from "../../../images/Visa-light.png";
import MastercardImg from "../../../images/MasterCard-light.png";
import DiscoverImg from "../../../images/Discover-light.png";
import AmExImg from "../../../images/AmericanExpress-light.png";

//type Props = StateProps & ThunkDispatchProp & RouteComponentProps;
interface Props {
  onPaymentToken: (value: string) => void,
  onPaymentTokenFailure: (code: string, message: string) => void,
  payFormUrl: string;
}

interface SuccessfulPaymentTokenResponse {
  IsSuccess: true;
  PaymentToken: string;
}
interface FailedPaymentTokenResponse {
  IsSuccess: false;
  ErrorCode: string;
  ErrorMessage: string;
}
type PaymentTokenResponse = SuccessfulPaymentTokenResponse | FailedPaymentTokenResponse;

const originRegex = /(https?:\/\/[.\-0-9a-z]+(?::\d+)?)/i;

class PayForm extends Component<Props> {
  componentDidMount() {
    window.addEventListener('message', this.handleIframeTask, false);
  }

   componentWillUnmount() {
    window.removeEventListener("message", this.handleIframeTask);
  }

  handleReceiveToken = (value: string) => {
    this.props.onPaymentToken(value);
  };

  handleFailedToken = (code: string, message: string) => {
    this.props.onPaymentTokenFailure(code, message);
  };

  handleIframeTask = (e: MessageEvent) => {
    const {
      payFormUrl
    } = this.props;

    const expectedOriginMatch = originRegex.exec(payFormUrl);
    const expectedOrigin = expectedOriginMatch && expectedOriginMatch[1];

    if (e.origin !== expectedOrigin) {
      return;
    } else {
      let response: PaymentTokenResponse = JSON.parse(e.data);
      if (response.IsSuccess) {
        this.handleReceiveToken(response.PaymentToken);
      } else {
        this.handleFailedToken(response.ErrorCode, response.ErrorMessage);
      }
    }
  };

  render() {
    const {
      payFormUrl
    } = this.props;
    return (
      <div>
        {payFormUrl &&
          <iframe title="Pay Form" src={payFormUrl} style={{"height": "300px"}} width="100%" frameBorder="0"></iframe>
        }
        <label className={Styles.input_header_card}>
          Credit Card Types Accepted
        </label>
        <br />
        <div className={Styles.cardImages}>
          <img src={VisaImg} className={Styles.img_style} alt="Visa" />
          <img src={MastercardImg} className={Styles.img_style} alt="Mastercard" />
          <img src={DiscoverImg} className={Styles.img_style} alt="Discover" />
          <img src={AmExImg} className={Styles.img_style} alt="American Express" />
        </div>
      </div>
    );
  }
}

export default PayForm;


