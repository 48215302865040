import { Action } from "redux";
import { StoreState } from "../../store/state/store";
import {
  getStoreInfoByAlias,
  getStoreInfoById
} from "../../apis/storeEndpoints";
import { Dispatch } from "redux";
import { ThunkActionCreator } from "../thunkAction";

export interface LoadStoreAction extends Action {
  type: "@@store/STORE_LOAD";
  payload: StoreState;
}

export interface UpdateStoreAction extends Action {
  type: "@@store/STORE_UPDATE";
  payload: StoreState;
}

export type StoreActions = LoadStoreAction | UpdateStoreAction;

export const loadStore: ThunkActionCreator<LoadStoreAction> = (
  alias: string
) => async (dispatch: Dispatch<StoreActions>) => {
  const result = await getStoreInfoByAlias(alias);
  dispatch({ type: "@@store/STORE_LOAD", payload: result });
};

export const updateStore: ThunkActionCreator<UpdateStoreAction> = () => async (
  dispatch,
  getState
) => {
  const { store } = getState();
  if (store) {
    const result = await getStoreInfoById(store.id);

    dispatch({ type: "@@store/STORE_UPDATE", payload: result });
  }
};
