import { StoreBannerImage } from "../../store/state/banners/index";
import { Action } from "redux";
import { getBannersByStoreId } from "../../apis/bannersEndpoints";
import { ThunkActionCreator } from "../thunkAction";

export interface LoadBannersAction extends Action {
  type: "@@banners/LOAD_BANNERS";
  payload: StoreBannerImage[];
}

export type BannerActions = LoadBannersAction;

export const loadBanners: ThunkActionCreator<LoadBannersAction> = () => async (
  dispatch,
  getState
) => {
  const { store } = getState();
  if (store) {
    const result = await getBannersByStoreId(store.id);

    dispatch({ type: "@@banners/LOAD_BANNERS", payload: result });
  }
};
