import axios from "axios";
import { storesApiBase } from "../config";

const storeInfoByAliasEndpoint = (storeAlias: string) =>
  `${storesApiBase}/byname/${storeAlias}`;
const storeInfoByIdEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}`;
export interface StoreDataResponse {
  id: number;
  name: string;
  alias: string;
  ownerName: string;
  bioImageUrl?: string;
  biography: string;
  youtubeVideoId?: string;
  shippingPolicy?: string;
  returnsPolicy?: string;
  googleAnalyticsId?: string;
  isActive: boolean;

  storeFeatures: {
    [id: string]: {
      featureId: number;
      available: boolean;
      stale: boolean;
      displayName: string;
      description: string;
    };
  };

  emailBanner?: "MailChimp" | "ShippingEasy";
  showEmailSignupBanner: boolean;

  contactInformation: {
    phone?: string;
    emailAddress?: string;
  };
  socialMediaAccounts: {
    facebookAccount?: string;
    twitterAccount?: string;
    instagramAccount?: string;
    pinterestAccount?: string;
  };
  pickupDetails:
    | {
        enabled: true;
        addressLine1: string;
        addressLine2?: string;
        city: string;
        state: string;
        zip: string;
        notes?: string;
      }
    | { enabled: false };
}

export function getStoreInfoByAlias(alias: string): Promise<StoreDataResponse> {
  return axios
    .get<StoreDataResponse>(storeInfoByAliasEndpoint(alias))
    .then<StoreDataResponse>((value) => {
      return value.data;
    });
}

export function getStoreInfoById(id: number): Promise<StoreDataResponse> {
  return axios
    .get<StoreDataResponse>(storeInfoByIdEndpoint(id))
    .then<StoreDataResponse>((value) => {
      return value.data;
    });
}
