import axios from "axios";
import { storesApiBase } from "../config";

const emailSignupEndpoint = (storeId: number) => `${storesApiBase}/${storeId}/emailsignup`;

export interface EmailSignupRequest {
  firstName: string;
  lastName: string;
  email: string;
}
export interface EmailSignupResponse {
  success: boolean;
  errorMessage: string;
}

export async function sendEmailSignup(storeId: number, details: EmailSignupRequest): Promise<EmailSignupResponse> {
  return await axios
    .post<EmailSignupResponse>(emailSignupEndpoint(storeId), details)
    .then(response => response.data);
}
