import React, { Component } from "react";
import { connect } from "react-redux";
import { bool, func, array, object, number, string } from "prop-types";
import PubNubReact from "pubnub-react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlbumItem from "components/AlbumItem";
import Styles from "./LiveInventory.module.scss";
import { ProductManagerContext } from "../ProductManager/ProductManagerContext";
import { fetchListingPatternsByIds } from "../../actions/patterns";
import { ClaimContextType } from "../../enums/ClaimContextType";
import { getAllPatterns } from "../../utilities/patterns";

class LiveInventory extends Component {
  static propTypes = {
    eventId: number,
    eventName: string,
    channelInfo: object.isRequired,
    displayItems: bool,
    fullScreenMode: bool,
    jumpToRecentItem: bool,
    liveProducts: array.isRequired,
    allPatterns: array,
    toggleDisplayItems: func.isRequired,
    handleScrollItems: func.isRequired,
    setViewers: func.isRequired,
    storeId: number.isRequired,
    jumpToTopOfItems: func.isRequired,
    liveUsername: string,
    liveEmail: string,
    pubnubPublishKey: string,
    pubnubSubscribeKey: string,
    sizeChartOpen: bool,
    openSizeChart: func,
  };

  static contextType = ProductManagerContext;

  listingChangeListenHandle = null;
  listingPatternChangeListenHandle = null;

  constructor(props) {
    super(props);
    this.pubnub = new PubNubReact({
      publishKey: this.props.pubnubPublishKey,
      subscribeKey: this.props.pubnubSubscribeKey
    });
    this.pubnub.init(this);
  }

  componentDidUpdate(prevProps) {
    const { channelInfo, setViewers } = this.props;

    if (prevProps.channelInfo !== channelInfo) {
      this.pubnub.subscribe({
        channels: [
          channelInfo.publicationChannelId,
          channelInfo.systemChannelId,
          channelInfo.itemClaimsChannelId
        ]
      });

      this.pubnub.getMessage(channelInfo.systemChannelId, response => {
        response.message.data &&
          response.message.data.ParticipantCount &&
          setViewers(response.message.data.ParticipantCount);
      });

      this.pubnub.getMessage(channelInfo.publicationChannelId, response => {
        this.togglePublish(response.message.eventItemId, response.message.type);
        this.props.dispatch(fetchListingPatternsByIds([response.message.listingpatternId]));
      });

      this.pubnub.getMessage(channelInfo.itemClaimsChannelId, response => {
        this.props.dispatch(fetchListingPatternsByIds([response.message.ListingPatternId]));
      });
    }
  }

  mounted = true;

  componentWillUnmount() {
    this.mounted = false;
    const { channelInfo } = this.props;

    this.pubnub.unsubscribe({
      channels: [
        channelInfo.systemChannelId,
        channelInfo.publicationChannelId,
        channelInfo.itemClaimsChannelId
      ]
    });

    this.listingChangeListenHandle &&
      this.context.unsubscribeFromListingChanges(
        this.listingChangeListenHandle
      );

    this.listingPatternChangeListenHandle &&
      this.context.unsubscribeFromListingPatternChanges(
        this.listingPatternChangeListenHandle
      );
  }

  togglePublish() {
    const { handleScrollItems, jumpToTopOfItems } = this.props;

    handleScrollItems();
    jumpToTopOfItems();
  }

  claimItem = item => {
    const {
      eventId
    } = this.props;
    this.context.addListingToCart(item.id, "LiveSale", ClaimContextType.Event, eventId);
  };

  unclaimItem = item => {
    this.context.removeListingFromCart(item.id);
  };

  render() {
    const {
      displayItems,
      eventName,
      fullScreenMode,
      handleScrollItems,
      jumpToTopOfItems,
      jumpToRecentItem,
      liveEmail,
      toggleDisplayItems,
      liveProducts,
      eventId,
      sizeChartOpen,
      openSizeChart
    } = this.props;

    return (
      <>
        {fullScreenMode && (
          <button
            onClick={toggleDisplayItems}
            className={`btn ${Styles.full_screen_mode_inventory}`}
          >
            <FontAwesomeIcon icon="tshirt" />
          </button>
        )}
        <div
          className={classNames(
            fullScreenMode
              ? Styles.live_inventory_full_screen
              : Styles.live_inventory_empty,
            {
              "d-none": fullScreenMode && !displayItems
            }
          )}
        >
          {fullScreenMode && displayItems && (
            <div className={Styles.full_screen_mode_inventory_hide}>
              <button
                onClick={toggleDisplayItems}
                className={`btn ${Styles.full_screen_mode_inventory_hide_button}`}
              >
                <FontAwesomeIcon icon="chevron-down" />
              </button>
            </div>
          )}
          {liveProducts.length > 0 ? (
            <div className={fullScreenMode ? null : "row"}>
              <div
                className={classNames({
                  [Styles.live_inventory_scroll_full_screen]: fullScreenMode,
                  [`${Styles.live_inventory_scroll} col-12`]: !fullScreenMode,
                  "d-none": fullScreenMode && !displayItems
                })}
                id="product-list-wrapper"
                onScroll={handleScrollItems}
              >
                <div
                  className={
                    fullScreenMode ? Styles.live_inventory_scroll_down : "row"
                  }
                  id="product-list"
                >
                  {liveProducts
                    .sort((a, b) => {
                      const indexA = a.liveEventDetails.findIndex(
                        a => a.liveEventId === eventId
                      );
                      const indexB = b.liveEventDetails.findIndex(
                        b => b.liveEventId === eventId
                      );
                      return (
                        new Date(b.liveEventDetails[indexB].publishedDateTime) -
                        new Date(a.liveEventDetails[indexA].publishedDateTime)
                      );
                    })
                    .map(liveProduct => (
                      <AlbumItem
                        key={liveProduct.listingPatternId}
                        eventName={eventName}
                        eventId={eventId}
                        className={
                          fullScreenMode
                            ? Styles.live_inventory_items_full_screen
                            : "col-6 pb-4"
                        }
                        liveEmail={liveEmail}
                        liveProduct={liveProduct}
                        showCartActions={true}
                        onAddItemToCart={() => this.claimItem(liveProduct)}
                        onRemoveItemFromCart={() => {
                          this.unclaimItem(liveProduct);
                        }}
                        sizeChartOpen={sizeChartOpen}
                        openSizeChart={openSizeChart}
                      />
                    ))}
                </div>
              </div>
            </div>
          ) : (
            <div
              className={
                fullScreenMode ? null : Styles.live_inventory_empty_border
              }
            >
              <div className={Styles.live_inventory_empty_content}>
                <div className={Styles.live_inventory_empty_emoji}>
                  <FontAwesomeIcon icon="heart" />
                </div>
                <div className={Styles.live_inventory_empty_text}>
                  Unicorns are on their way...
                </div>
              </div>
            </div>
          )}
          <div
            className={`row ${Styles.live_inventory_jump_to_recent_wrapper}`}
          >
            {jumpToRecentItem && (
              <button
                onClick={() => jumpToTopOfItems(true)}
                className={`btn ${Styles.live_inventory_jump_to_recent}`}
              >
                More Items <FontAwesomeIcon icon="chevron-up" />
              </button>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => (
  {
    allPatterns: getAllPatterns(state),
  }
);

export default connect(mapStateToProps)(LiveInventory);
