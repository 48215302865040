import { FormStateBase } from "./formStateBase";

export interface CheckoutBillingDataState {
  sameAddressAsShipping: boolean;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
  paymentToken: string;
}

export interface CheckoutBillingInfoState
  extends FormStateBase<CheckoutBillingDataState> {
    editingAddress: boolean;
    editingPayment: boolean;
  }

export function initialBillingState(): CheckoutBillingInfoState {
  return {
    data: undefined,
    editing: false,
    editingAddress: false,
    editingPayment: false,
    failed: false
  };
}
