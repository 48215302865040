import React, { useEffect, useState } from 'react';
import { connect, MapStateToProps } from 'react-redux';
import { Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as ics from 'ics';
import { DateTime } from "luxon";
import ImageCover from '../../components/ImageCover';
import { allActiveLiveEvent, allUpcomingLiveEvents, LiveEvent, formatDate, formatTime } from '../../utilities/liveEvents';
import { State } from "../../store/state";
import { getFacebookVideo } from '../../apis/liveEndpoints';
import Styles from './LiveEventsBanner.module.scss';

interface Props {
  storeName: string;
  storeId?: number;
  activeEvent?: LiveEvent;
  upcomingEvent?: LiveEvent;
  storeOwner: string;
};

const LiveEventsBanner = ({ storeName, storeId, storeOwner, activeEvent, upcomingEvent }: Props): JSX.Element => {
  const event = activeEvent || upcomingEvent;

  const startDate = formatDate(event?.startDateTime, 'long');
  const endDate = formatDate(event?.endDateTime, 'long');
  const startTime = formatTime(event?.startDateTime);
  const endTime = formatTime(event?.endDateTime);

  const [facebookVideoUrl, setFacebookVideoUrl] = useState<string | null>();
  const [calendarEvent, setCalendarEvent] = useState<string>();

  useEffect(() => {
    if (storeId && activeEvent && activeEvent.eventType.toLowerCase() === "replytobuy") {
      getFacebookVideo(storeId)
        .then(setFacebookVideoUrl)
        .catch(() => setFacebookVideoUrl(null))
    }
  }, [activeEvent, storeId])

  useEffect(() => {
    if (!activeEvent && upcomingEvent) {
      const startDateTime = DateTime.fromISO(upcomingEvent.startDateTime);
      const endDateTime = upcomingEvent.endDateTime ? DateTime.fromISO(upcomingEvent.endDateTime) : null;

      ics.createEvent({
        title: upcomingEvent.description,
        description: `${storeOwner}'s Live Event`,
        start: [
          startDateTime.year,
          startDateTime.month,
          startDateTime.day,
          startDateTime.hour,
          startDateTime.minute
        ],
        end: [
          endDateTime?.year ?? startDateTime.year,
          endDateTime?.month ?? startDateTime.year,
          endDateTime?.day ?? startDateTime.year,
          endDateTime?.hour ?? 23,
          endDateTime?.minute ?? 59
        ],
        startInputType: 'local',
        endInputType: 'local',
        productId: storeName,
        organizer: {
          name: storeOwner,
        }
      }, (error: Error | undefined, value: string) => {
        if (error) {
          console.log(error)
        } else {
          setCalendarEvent("data:text/calendar;charset=utf8," + escape(value));
        }
      })
    }
  }, [activeEvent, upcomingEvent, storeName, storeOwner]);

  return (
    <div>
      {event ? (
        <div className={`${Styles.LiveEventsBanner} ${activeEvent ? Styles.activeEvent : Styles.upcomingEvent}`}>
          <Row style={{ height: '100%' }}>
            <Col xs={5} md={4} lg={3} xl={2}>
              <ImageCover
                src={event.thumbnailImageUrl || event.standardImageUrl}
                alt={event.description}
                className={Styles.Image}
                title={event.description}
              />
              {
                activeEvent && (
                  <div className={Styles.badge_wrapper}>
                    <span className={`${Styles.badge} ${Styles.badge_live}`}>
                      Live
                    </span>
                  </div>
                )
              }
            </Col>
            <Col xs={7}  md={8} lg={9} xl={10} className={Styles.info}>
              {!activeEvent ?
                (
                  <>
                    <Row>
                      <div className={Styles.text_header}>NEXT LIVE EVENT</div>
                    </Row>
                    <Row>
                      <div className={Styles.text_title}>{event.description}</div>
                    </Row>
                    <Row className={Styles.dateContainer}>
                      <div className={Styles.text_date}>
                        {startDate === endDate ? startDate : `${startDate} - ${endDate}`}
                      </div>
                    </Row>
                    <Row>
                      <div className={Styles.text_date}>
                        {startTime} - {endTime}
                      </div>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <div className={Styles.text_header}>LIVE EVENT</div>
                    </Row>
                    <Row>
                      <div className={Styles.text_title}>{event.description}</div>
                    </Row>
                  </>
                )
              }
              <Row className={Styles.addToCalendar_container}>
                {activeEvent ? (
                  activeEvent.eventType.toLowerCase() === "bless" ? (
                    <Link to={`${storeName}/live/${activeEvent.id}`} className={Styles.joinEventBtn}>
                      View Now
                    </Link>
                  ) : (
                    <Button className={Styles.joinEventBtn} disabled={!facebookVideoUrl}>
                      {facebookVideoUrl ? (
                        <a href={facebookVideoUrl} target="_blank" rel="noopener noreferrer">
                          View on Facebook
                        </a>
                        ) : (
                          "View on Facebook"
                        )
                      }
                    </Button>
                  )
                  ) : (
                    <Button className={Styles.addToCalendar_btn} disabled={!calendarEvent}>
                      {calendarEvent ? (
                        <a className={Styles.addToCalendar_btn} href={calendarEvent} download="event.ics">
                          <FontAwesomeIcon icon={['far', 'calendar']} /> Add To Calendar
                        </a>
                        ) : (
                          <><FontAwesomeIcon icon={['far', 'calendar']} /> Add To Calendar</>
                        )
                      }
                    </Button>
                  )
                }
              </Row>
              <Row>
                <Link to={`${storeName}/live`} className={Styles.text_upcomingEvents}>
                  Upcoming Events <FontAwesomeIcon icon="arrow-right" />
                </Link>
              </Row>
            </Col>
          </Row>
        </div>
        ) : (
          <></>
        )
      }
    </div>
  )
};

const mapStateToProps: MapStateToProps<Props, {}, State> = state => {
  const { store } = state;

  const activeEvent = allActiveLiveEvent(state);
  const upcomingEvent = allUpcomingLiveEvents(state)[0];

  return {
    storeName: store.alias || "",
    storeId: store.id,
    storeOwner: store.ownerName || "",
    activeEvent,
    upcomingEvent,
  };
};

export default connect(mapStateToProps)(LiveEventsBanner);
