import { Component } from "react";
import { connect } from "react-redux";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import {
   ProductManagerContext,
   ListingPatternsChangeEvent
} from "./ProductManagerContext";
import { fetchListingPatternsByIds } from "../../actions/patterns";

export class ListingPatternChangeHandler extends Component<ThunkDispatchProp> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  listenerHandler?: number;
  fetchInterval?: number;
  staleListingPatternIds: number[] = [];

  handleChangedListingPatterns = (event: ListingPatternsChangeEvent) => {
    this.staleListingPatternIds.push(...event.listingPatternIds);
  }

  componentDidMount() {
    this.listenerHandler = this.context.subscribeToListingPatternChanges(this.handleChangedListingPatterns);
    this.fetchInterval = window.setInterval(() => {
      if (this.staleListingPatternIds.length > 0) {
        const stales = this.staleListingPatternIds;
        this.staleListingPatternIds = [];
        this.props.dispatch(fetchListingPatternsByIds(stales));
      }
    }, 1000);
  }

  componentWillUnmount() {
    this.listenerHandler && this.context.unsubscribeFromListingPatternChanges(this.listenerHandler);
    window.clearInterval(this.fetchInterval);
  }

  render = () => null;
}

export default connect()(ListingPatternChangeHandler);
