import React, { Component, ReactNode } from 'react';
import classnames from 'classnames';
import Styles from './AgreementBanner.module.scss';

interface IProps {
    className?: string;
    children?: ReactNode;
    cookieName?: string;
    location: 'top' | 'bottom';
}

interface IState {
    show: boolean;
}

class AgreementBanner extends Component<IProps, IState> {
    static readonly defaultProps = {
        location: 'top'
    }

    private setCookie = (name: string, value: string, days?: number) => {
        // let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            // expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + "; path=/";
    }

    private getCookie = (name: string) => {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    constructor(props: IProps) {
        super(props);

        let show = true;
        const { cookieName } = props;
        if (cookieName) {
            const { getCookie } = this;
            const cookie = getCookie(cookieName);
            show = cookie !== "true";
        }

        this.state = {
            show
        };
    }

    agree = () => {
        const { props: { cookieName }, setCookie } = this;
        if (cookieName) {
            setCookie(cookieName, "true", 365);
        }

        this.setState({ show: false });
    };

    render() {
        const {
            props: {
                children,
                location
            },
            state: {
                show
            },
            agree
        } = this;

        return show ? (
            <div
                className={classnames(Styles.agreementBanner, "bg-dark")}
                data-location={location}
            >
                <div className={classnames(Styles.agreementBannerContent, "text-light")}>
                    {children}
                </div>
                <div className={Styles.agreementBannerButton}>
                    <button
                        className="btn btn-lg btn-light"
                        onClick={agree}
                    >
                        Agree
                    </button>
                </div>
            </div>
        ) : null;
    }
}

export default AgreementBanner;