import React, { Component, ReactNode } from "react";
import {
  ProductManagerContext,
  ProductManagerContextState,
  ListingsChangeEvent,
  ListingsChangeCallback,
  ListingPatternsChangeEvent,
  ListingPatternsChangeCallback,
  ListingGroupsChangeEvent,
  ListingGroupsChangeCallback,
  LiveEventsChangeEvent,
  LiveEventsChangeCallback
} from "./ProductManagerContext";
import { connect, MapStateToProps } from "react-redux";
import { claimListing, unclaimListing } from "../../actions/patterns";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import { TrackAddToCart, TrackRemoveFromCart } from "../../analytics";
import { ListingPatternModel } from '../../apis/listingsEndpoints';
import { getAllPatterns } from "../../utilities/patterns";
import { State } from "../../store/state";
import ListingGroupExpirationHandler from "./ListingGroupExpirationHandler";
import CartChangesHandler from "./CartChangesHandler";
import ChangeTracker from "./ChangeTracker";
import ListingPatternChangeHandler from "./ListingPatternChangeHandler";
import ListingGroupChangeHandler from "./ListingGroupChangeHandler";
import { ClaimContextType } from "../../enums/ClaimContextType";
import LiveEventChangeHandler from "./LiveEventChangeHandler";

interface StateProps {
  patterns: ListingPatternModel[];
  storeId: number;
}

interface OwnProps {
  notify: (message: string) => void;
  cartClick: () => void;
  children?: ReactNode;
}

class ProductStateManager extends Component<
  StateProps & OwnProps & ThunkDispatchProp
> {
  listenerIncrement = 0;
  listingListeners: {
    [i: number]: ListingsChangeCallback | undefined;
  } = {};
  listingPatternListeners: {
    [i: number]: ListingPatternsChangeCallback | undefined;
  } = {};
  listingGroupListeners: {
    [i: number]: ListingGroupsChangeCallback | undefined;
  } = {};
  liveEventListeners: {
    [i: number]: LiveEventsChangeCallback | undefined;
  } = {};

  subscribeToListingChanges = (callback: ListingsChangeCallback) => {
    const listenerHandler = ++this.listenerIncrement;
    this.listingListeners[listenerHandler] = callback;
    return listenerHandler;
  };

  subscribeToListingPatternChanges = (callback: ListingPatternsChangeCallback) => {
    const listenerHandler = ++this.listenerIncrement;
    this.listingPatternListeners[listenerHandler] = callback;
    return listenerHandler;
  };

  subscribeToListingGroupChanges = (callback: ListingGroupsChangeCallback) => {
    const listenerHandler = ++this.listenerIncrement;
    this.listingGroupListeners[listenerHandler] = callback;
    return listenerHandler;
  };

  subscribeToLiveEventChanges = (callback: LiveEventsChangeCallback) => {
    const listenerHandler = ++this.listenerIncrement;
    this.liveEventListeners[listenerHandler] = callback;
    return listenerHandler;
  };

  unsubscribeFromListingChanges = (handle: number) => {
    delete this.listingListeners[handle];
  };

  unsubscribeFromListingGroupChanges = (handle: number) => {
    delete this.listingGroupListeners[handle];
  };

  unsubscribeFromListingPatternChanges = (handle: number) => {
    delete this.listingPatternListeners[handle];
  };

  unsubscribeFromLiveEventChanges = (handle: number) => {
    delete this.liveEventListeners[handle];
  };

  fireListingChange = (event: ListingsChangeEvent) => {
    Object.values(this.listingListeners).forEach(
      (callback) => callback && callback(event)
    );
  };

  fireListingPatternChange = (event: ListingPatternsChangeEvent) => {
    Object.values(this.listingPatternListeners).forEach(
      (callback) => callback && callback(event)
    );
  };

  fireListingGroupChange = (event: ListingGroupsChangeEvent) => {
    Object.values(this.listingGroupListeners).forEach(
      (callback) => callback && callback(event)
    );
  };

  fireLiveEventsChange = (event: LiveEventsChangeEvent) => {
    Object.values(this.liveEventListeners).forEach(
      (callback) => callback && callback(event)
    );
  };

  addListingToCart = async (
    listingId: number,
    listName: string,
    context?: ClaimContextType,
    contextId?: number
  ) => {
    const pattern = this.props.patterns.find((p) => p.listingDetails.find((l) => l.listingId === listingId));
    const listing = pattern?.listingDetails.find((l) => l.listingId === listingId);

    if (pattern && listing) {
      await this.props.dispatch(claimListing(pattern.listingPatternId, listingId, context, contextId));
      TrackAddToCart(listing, listName, pattern.styleDisplayName, pattern.tagDetails);
    }
  };

  removeListingFromCart = async (listingId: number) => {
    const pattern = this.props.patterns.find((p) => p.listingDetails.find((l) => l.listingId === listingId));
    const listing = pattern?.listingDetails.find((l) => l.listingId === listingId);

    if (pattern && listing) {
      await this.props.dispatch(unclaimListing(pattern.listingPatternId, listingId));
      TrackRemoveFromCart(listing, pattern.styleDisplayName, pattern.tagDetails);
    }
  };

  render() {
    const value: ProductManagerContextState = {
      addListingToCart: this.addListingToCart,
      removeListingFromCart: this.removeListingFromCart,
      signalListingChange: this.fireListingChange,
      signalListingPatternChange: this.fireListingPatternChange,
      signalListingGroupChange: this.fireListingGroupChange,
      signalLiveEventsChange: this.fireLiveEventsChange,
      subscribeToListingChanges: this.subscribeToListingChanges,
      subscribeToListingPatternChanges: this.subscribeToListingPatternChanges,
      subscribeToListingGroupChanges: this.subscribeToListingGroupChanges,
      subscribeToLiveEventsChanges: this.subscribeToLiveEventChanges,
      unsubscribeFromListingChanges: this.unsubscribeFromListingChanges,
      unsubscribeFromListingPatternChanges: this.unsubscribeFromListingPatternChanges,
      unsubscribeFromListingGroupChanges: this
        .unsubscribeFromListingGroupChanges,
      unsubscribeFromLiveEventsChanges: this.unsubscribeFromLiveEventChanges
    };
    const { notify } = this.props;

    return (
      <ProductManagerContext.Provider value={value}>
        {this.props.children}
        <ChangeTracker />
        <CartChangesHandler notify={notify} />
        <ListingGroupExpirationHandler />
        <ListingPatternChangeHandler />
        <ListingGroupChangeHandler />
        <LiveEventChangeHandler />
      </ProductManagerContext.Provider>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state: State
) => ({
  patterns: getAllPatterns(state),
  storeId: state.store.id
});

export default connect(mapStateToProps)(ProductStateManager);
