import React, { Component, ComponentClass } from "react";
import { TrackPage } from "./analytics";
import { RouteProps } from "react-router";

function withTracker<TProps>(WrappedComponent: ComponentClass<TProps>, props: TProps) {
  const HOC = class extends Component<RouteProps> {
    componentDidMount() {
      if (this.props.location) {
        const page = this.props.location.pathname + this.props.location.search;
        // Delay this by a second to give retailer analytics a chance to kick up
        setTimeout(() => TrackPage(page), 1000);
      }
    }

    componentDidUpdate(prevProps: RouteProps) {
      if (this.props.location) {
        const currentPage = prevProps.location ? 
          prevProps.location.pathname + prevProps.location.search : "/";
        const nextPage =
          this.props.location.pathname + this.props.location.search;

        if (currentPage !== nextPage) {
          // Delay this by a second to give retailer analytics a chance to kick up
          setTimeout(() => TrackPage(nextPage), 1000);
        }
      }
      
    }

    render() {
      return <WrappedComponent {...this.props} {...props} />;
    }
  };

  return HOC;
}

export default withTracker;