import { BannerActions } from "./banners/index";
import { loadBanners } from "./banners/index";
import { CheckoutActions } from "./checkout";
import { AlbumsActions, fetchAllAlbums } from "./albums";
import { ChangesActions, updateLastChangeId } from "./changes";
import { ListingPatternsActions, fetchListingPatterns } from "./patterns";
import { StoreActions, loadStore } from "./store";
import {
  LocationChangeAction,
  CallHistoryMethodAction
} from "connected-react-router";
import { ThunkResult } from "./thunkAction";
import { AxiosError } from "axios";
import {
  getPatternsFromStorage,
  persistPatterns,
  currentCacheVersion
} from "../storage/listingsCache";
import { getLatestChangeId } from "../apis/changesEndpoints";
import {
  setCartFromToken,
  SetCartResponse,
  setCartFromPreviousSession
} from "../apis/cartEndpoints";
import {
  loadAppFailed,
  loadAppNotFound,
  loadAppSuccess,
  AppActions
} from "./app";
import { EventsActions, fetchAllEvents } from "./liveEvents";

export type AllActions =
  | AppActions
  | AlbumsActions
  | CheckoutActions
  | ListingPatternsActions
  | StoreActions
  | LocationChangeAction
  | CallHistoryMethodAction
  | ChangesActions
  | EventsActions
  | BannerActions;

const hydrateListings = (): ThunkResult => async (dispatch, getState) => {
  const { store } = getState();
  const storeId = store.id;

  const cache = await getPatternsFromStorage(storeId);

  if (cache) {
    dispatch({
      type: "@@patterns/UPDATE_LISTING_PATTERNS",
      payload: cache.patterns
    });
    dispatch(updateLastChangeId(cache.lastChangeId));
  } else {
    const [lastChange] = await Promise.all([
      getLatestChangeId(storeId),
      dispatch(fetchListingPatterns())
    ]);
    dispatch(updateLastChangeId(lastChange));
    const { patterns } = getState();
    persistPatterns(storeId, {
      cacheVersion: currentCacheVersion,
      initialized: new Date().toISOString(),
      lastChangeId: lastChange,
      patterns: patterns.items
    });
  }
};

export const loadInitialState = (
  alias: string,
  cartToken?: string,
  previousSessionId?: string
): ThunkResult<SetCartResponse | null> => async (dispatch, getState) => {
  let setCartResponse: SetCartResponse | null = null;

  try {
    await dispatch(loadStore(alias));
  } catch (error) {
    const axiosError = error as AxiosError;
    console.error(error);
    if (axiosError.code === "404") {
      dispatch(loadAppNotFound());
    } else {
      dispatch(loadAppFailed(axiosError.message));
    }
  }

  try {
    const promises: Promise<any>[] = [];

    const { store } = getState();

    if (cartToken) {
      setCartResponse = await setCartFromToken(store.id, cartToken);
    }

    if (previousSessionId) {
      setCartResponse = await setCartFromPreviousSession(
        store.id,
        previousSessionId
      );
    }

    promises.push(
      dispatch(hydrateListings()),
      dispatch(fetchAllAlbums()),
      dispatch(loadBanners()),
      dispatch(fetchAllEvents())
    );

    await Promise.all(promises);

    dispatch(loadAppSuccess());
  } catch (error) {
    console.error(error);
    dispatch(loadAppFailed(error));
  }
  return setCartResponse;
};
