import React, { Component } from "react";
import { TimerContext } from "./TimerContext";
import { UnitsAscending } from "./UnitLists";
import { DurationUnit, DateTime, Duration } from "luxon";
import deepEqual from "deep-equal";

interface OwnProps {
  target?: Date | string | DateTime;
  units: DurationUnit[];
  maxDuration: number;
  maxDurationUnits: DurationUnit;
}

interface ComponentState {
  isActive: boolean;
  duration: Duration;
  activeUnits: DurationUnit[];
}

export class Timer extends Component<OwnProps, ComponentState> {
  dateTime?: DateTime;
  maxDuration: Duration = Duration.fromObject({ days: 2 });
  interval?: number;

  state: ComponentState = {
    isActive: false,
    duration: Duration.fromMillis(0),
    activeUnits: []
  };

  updating: boolean = false;

  initialize = () => {
    const { target, units, maxDuration, maxDurationUnits } = this.props;
    if (!target) {
      this.setState({
        isActive: false
      });
      this.tearDown();
      return;
    }
    if (typeof target === "string") {
      this.dateTime = DateTime.fromISO(target);
    } else if (target instanceof DateTime) {
      this.dateTime = target;
    } else {
      this.dateTime = DateTime.fromJSDate(target);
    }
    this.maxDuration = Duration.fromObject({
      [maxDurationUnits]: maxDuration
    });

    // Don't waste time updating too frequently.
    // If we only care about minutes, only update once a minute.
    let intervalLength: number = 1000;
    for (const unit of UnitsAscending) {
      if (units.includes(unit)) {
        intervalLength = Duration.fromObject({ [unit]: 1 }).valueOf();
        break;
      }
    }

    this.update();

    const currentDiff = this.dateTime.diffNow();
    // Let's start after the next value turns over.
    let firstInterval = currentDiff.valueOf() % intervalLength;

    // Actually, if we are over the max, let's wait until we're in range.
    if (currentDiff > this.maxDuration) {
        firstInterval = currentDiff.minus(this.maxDuration).valueOf();
    }

    this.interval = window.setTimeout(() => {
      this.update();
      this.interval = window.setInterval(this.update, intervalLength);
    }, firstInterval);
  };

  tearDown = () => {
    clearTimeout(this.interval);
    clearInterval(this.interval);
    delete this.interval;
  };

  update = () => {
    if (this.updating) {
      return;
    }
    this.updating = true;
    if (!this.dateTime) {
      if (this.state.isActive) {
        this.setState({ isActive: false });
      }
      this.updating = true;
      return;
    }
    const diff = this.dateTime.diffNow([...this.props.units, "milliseconds"]);

    this.setState({
      duration: diff,
      isActive: diff.valueOf() > 0 && diff < this.maxDuration
    });
    
    this.updating = false;
  };

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: OwnProps) {
    if (
      !deepEqual(
        {
          target: prevProps.target,
          units: prevProps.units,
          maxDuration: prevProps.maxDuration,
          maxDurationUnits: prevProps.maxDurationUnits
        },
        {
          target: this.props.target,
          units: this.props.units,
          maxDuration: this.props.maxDuration,
          maxDurationUnits: this.props.maxDurationUnits
        }
      )
    ) {
      this.tearDown();
      this.setState({ isActive: false });
      this.initialize();
    }
  }

  componentWillUnmount() {
    this.tearDown();
  }

  render() {
    const { isActive, duration, activeUnits } = this.state;
    const { units } = this.props;
    const contextValue = {
      isActive,
      duration,
      units,
      activeUnits
    };
    return (
      <TimerContext.Provider
        value={contextValue}
        children={this.props.children}
      />
    );
  }
}
