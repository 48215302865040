import { CheckoutCustomerInfoState, initialCustomerState } from "./customer";
import { CheckoutDeliveryInfoState, initialDeliveryState } from "./delivery";
import { CheckoutBillingInfoState, initialBillingState } from "./billing";
import { CheckoutOrderState, initialOrderState } from "./order";
import { CheckoutEstimateState, initialEstimateState } from "./estimate";
import { ShippingAmountState, initialShippingState } from "./shipping";

export interface CheckoutState {
  customerEmail?: string;
  customerFirstName?: string;
  customerLastName?: string;
  facebookId?: string;
  customerInfo: CheckoutCustomerInfoState;
  deliveryInfo: CheckoutDeliveryInfoState;
  billingInfo: CheckoutBillingInfoState;
  order: CheckoutOrderState;
  discountEstimate?: number;
  estimate: CheckoutEstimateState;
  shipping: ShippingAmountState;
}

export const initialCheckoutState: Readonly<CheckoutState> = {
  customerInfo: initialCustomerState(),
  deliveryInfo: initialDeliveryState(),
  billingInfo: initialBillingState(),
  order: initialOrderState(),
  estimate: initialEstimateState(),
  shipping: initialShippingState(),
};
