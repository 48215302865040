import React, { Component } from "react";
import { withRouter } from "react-router";
import Styles from "./ErrorBoundary.module.scss";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    const { history } = this.props;

    // When history changes, clear the state. This way if they hit
    // the back button, we don't keep showing this error screen.
    this.unregisterListener = history.listen(() => {
      this.setState({
        hasError: false,
        error: null,
        errorInfo: null
      });
    });
  }

  state = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  /*   static getDerivedStateFromError(error, errorInfo) {
    return { hasError: true, error: error, errorInfo: errorInfo };
  } */

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });

    console.error(error, errorInfo);
  }

  componentWillUnmount() {
    if (
      this.unregisterListener &&
      typeof this.unregisterListener === "function"
    ) {
      this.unregisterListener();
    }
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div className={Styles.textStyling}>
          <img
            src={require("../images/screen_face.svg")}
            className={Styles.errorImageStyle}
            alt="Error"
          />
          <h1>Sorry, but we’ve hit an error on our end.</h1>
          <h1>If you need help, please contact your LulaRoe retailer.</h1>
          <img
            src={require("../images/logo.png")}
            className={Styles.logoStyling}
            alt="LulaRoe logo"
          />
        </div>
        /*         <div>
          <div className={Styles.textStyling}>
            <h1>Sorry, but we’ve hit an error on our end.</h1>
            <h1>
              If you need help, send an email to{" "}
              <a href="mailto:bless@lularoe.com">bless@lularoe.com</a>
            </h1>
            <h1>
              or call LulaRoe support at{" "}
              <a href="tel:951-737-7875">951-737-7875</a>
            </h1>
          </div>
          <img
            src={require("../images/logo.png")}
            className={Styles.logoStyling}
            alt="LulaRoe logo"
          />
        </div> */
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
