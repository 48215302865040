import React, { Component } from "react";
import { number, array, string, object } from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { express } from "phenix-web-sdk/dist/phenix-web-sdk";
import { DateTime } from "luxon";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Lightbox from "react-images";
import maybePluralize from "../../utilities/maybePluralize";
import LoadingSpinner from "components/LoadingSpinner";
import Chat from "components/Chat";
import ChatInput from "components/Chat/ChatInput";
import ChatBubble from "components/ChatBubble";
import ImageCover from "components/ImageCover";
import VideoStream from "components/VideoStream";
import LiveInventory from "components/LiveInventory";
import LiveUserInfo from "components/LiveUserInfoModal";
import {
  //postQuestionLive,
  getLiveSessionEnd,
  postJoinLiveEvent,
  postLeaveLiveEvent
} from "../../apis/liveEndpoints";
import { getAllPatterns } from "../../utilities/patterns";
import { activeLiveEvent } from "../../utilities/liveEvents";
import { PageUrls, PubNubSubscribeKey, LiveHeartBeatIntervalSeconds } from "../../config";
import Styles from "./LiveSalePage.module.scss";
import { preloadCustomerEmail, preloadCustomerName, saveFacebookId } from "../../actions/checkout/customer";
import { LiveSaleNoMpegFallback, LiveSaleFacebookAppFallback } from "./Fallbacks";
import * as liveApi from "../../apis/liveEndpoints";
import { getProductSizeChart } from "../../apis/productEndpoints";

const supportH264 = (function(){
  let video = document.createElement("video");
  let supports = video.canPlayType('video/mp4; codecs="avc1.42E01E"');
  video.remove();
  return supports === "probably";
})();
const ua = navigator.userAgent || navigator.vendor || window.opera;
const isFacebookAndroid = ua.indexOf("Android") > -1 && ((ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1));

class LiveSalePage extends Component {
  constructor(props) {
      super(props);
      this.determineFullScreenMode();
      this.openLightbox = this.openLightbox.bind(this);
  }
  mounted = true;
  playbackSupported = supportH264 && !isFacebookAndroid;
  facebookBrowser = isFacebookAndroid;

  static chatService = null;

  static propTypes = {
    patterns: array,
    listings: array,
    alias: string,
    storeId: number,
    eventId: number,
    activeEvent: object
  };

  state = {
    chatData: [],
    chatInputValue: "",
    chatInputValueMax: 256,
    chatMessageSending: false,
    displayEmojiPicker: false,
    displayItems: false,
    fullScreenMode: false,
    jumpToRecentChat: false,
    chatScroll: true,
    jumpToRecentItem: false,
    scrollY: true,
    scrollX: true,
    liveChannelData: {},
    liveEventData: false,
    redirectTime: "",
    liveProducts: [],
    chatLoaded: false,
    videoLoaded: false,
    videoIsMuted: false,
    streaming: false,
    viewers: 0,
    liveUsername: "",
    liveEmail: "",
    liveId: "",
    userInfoFilledOut: false,
    redirect: false,
    pubNubPublishKey: undefined,
    pubNubSubscribeKey: undefined,
    lightboxIsOpen: false,
    sizeChartUrl: ""
  };

  attemptLeaveSession = async () => {
    const { storeId, eventId } = this.props;
    const { liveEmail } = this.state;

    liveEmail && await postLeaveLiveEvent(storeId, eventId, {
        emailAddress: liveEmail
    });
  }

  async componentDidMount() {
    const subKey = await PubNubSubscribeKey();
    this.setState(prevState => ({
      ...prevState,
      pubNubSubscribeKey: subKey
    }));

    if (this.playbackSupported && window.innerWidth <= 767 && this.state.userInfoFilledOut) {
      this.setFullScreenMode(true);
    }
    this.heartbeatDelay = (await LiveHeartBeatIntervalSeconds()) * 1000;
    this.heartbeatInterval = setTimeout(this.performHeartbeat, this.heartbeatDelay);
  }



  async componentWillUnmount() {
    this.mounted = false;
    this.unmounted = true;

    clearTimeout(this.interval);
    this.heartbeatInterval && clearTimeout(this.heartbeatInterval);

    if (this.channelExpress) {
      this.channelExpress.dispose();
    }
    if (this.chatService) {
      this.chatService.stop();
    }

    document.body.classList.remove("full-screen-live");

    await this.attemptLeaveSession();
  }

  postHeartEmoji = () => {
    const { storeId, eventId } = this.props;
    liveApi.postHeartEmoji(storeId, eventId);
  };

  performHeartbeat = async () => {
    const {
      storeId,
      eventId
    } = this.props;
    const {
      liveEmail,
      videoLoaded,
      streaming
    } = this.state;

    try {
      // Only perform a heartbeat if our stream is running...
      if (videoLoaded && streaming) {
        // .. and if we have all the inforamtion we need.
        if (storeId && eventId && liveEmail) {
          await liveApi.postHeartbeat(storeId, eventId, liveEmail);
        }
      }
    } finally {
      this.heartbeatInterval = setTimeout(this.performHeartbeat, this.heartbeatDelay);
    }
  };

  getStreamData = async () => {
    const { storeId, eventId } = this.props;
    const { userInfoFilledOut, liveUsername, liveEmail } = this.state;

    if (userInfoFilledOut) {
      const joinLiveSessionData = {
        deviceId: navigator.userAgent, // TODO get device data here
        displayName: liveUsername,
        emailAddress: liveEmail,
        fullName: liveUsername
      };

      try {
        const sessionInfo = await postJoinLiveEvent(
          storeId,
          eventId,
          joinLiveSessionData
        );
        await this.getStream(sessionInfo);
        this.mounted &&
          this.setState({
            liveChannelData: sessionInfo,
            viewers: sessionInfo.attendance
          });
      } catch (error) {
        console.log("Error getting live events.", error);
        this.mounted && this.setState({ redirect: true });
      }
    }
  };

  getStream = async response => {
    const { storeId, eventId } = this.props;
    const video = document.getElementById("PhenixLiveStream");
    const features = ["real-time", "dash", "hls"];
    const phenixToken = (await liveApi.getPhenixTokens(storeId, eventId)).token;

    // fix for iOS Mobile Browsers
    video.setAttribute("playsinline", true);

    let debugMode = false;
    if (
      window &&
      typeof window.location == "object" &&
      window.location.hash.indexOf("#debug") !== -1
    ) {
      debugMode = true;
    }

    const options = {
      channelId: response.videoChannelId,
      features: features,
      videoElement: video,
      authToken: phenixToken,
      streamToken: phenixToken,
      disableConsoleLogging: !debugMode
    };

    this.channelExpress = new express.ChannelExpress(options);

    this.joinStream(this.channelExpress, options);
  };

  joinStream(channelExpress, options) {
    return channelExpress.joinChannel(
      options,
      (error, response) => {
        // join channel callback
        if (error) {
          // Handle error
          console.log("joinChannel - join channel callback: error");
        }

        if (response.status === "room-not-found") {
          // Handle channel not found - Create a Channel Or Publish to a Channel
          console.log("joinChannel - join channel callback: room-not-found");
          // return;
        } else if (response.status !== "ok") {
          // Handle error
          console.log(
            `joinChannel - join channel callback: response.status !== "ok"`
          );
        }

        if (response.status === "offline") {
          console.log(`response.status === "offline"`);
        }

        // Successfully joined channel
        if (response.status === "ok" && response.channelService) {
          // Do something with channelService

          // console.log(response.channelService.getObservableActiveChannel().getValue());

          const chatService = response.channelService.getChatService();
          const chatMessageObservable = chatService.getObservableChatMessages();

          chatMessageObservable.subscribe(mostRecentChatMessages => {
            // Do something with chat messages
            this.mounted && this.setState({ chatLoaded: true });
            this.getChatFromPhenix(mostRecentChatMessages);
          });

          this.chatService = chatService;
          this.chatService.start();

          let batchSize = 1;
          let afterMessageId = "MessageId1";
          let beforeMessageId = null;

          chatService.getMessages(
            batchSize,
            afterMessageId,
            beforeMessageId,
            (error, response) => {
              if (error) {
                // handle error - request messages again
                console.log(`getMessages: error`);
              }

              if (response.status !== "ok") {
                // handle error - request messages again
                console.log(`getMessages: response.status !== "ok"`);
              }

              if (response.status === "ok" && response.chatMessages) {
                // Successfully got chat messages
                this.getChatFromPhenix(response.chatMessages);
              }
            }
          );

          return console.log("Successfully joined channel");
        }
      },
      (error, response) => {
        // subscriber callback
        if (error) {
          // Handle error
          console.log(`joinChannel - subscriber callback: error`);
        }

        if (response.status === "no-stream-playing") {
          // Handle no stream playing in channel - Wait for one to start
          this.mounted && this.setState({ videoLoaded: true });
          this.endStream();
          console.log(`joinChannel - subscriber callback: no-stream-playing`);
        } else if (response.status !== "ok") {
          // Handle error
          // When stream cuts out
          console.log(
            `joinChannel - subscriber callback: response.status !== "ok"`
          );
        }

        // Successfully subscribed to most recent channel presenter
        if (response.status === "ok" && response.mediaStream) {
          // Do something with mediaStream
          this.mounted && this.setState({ streaming: true, videoLoaded: true });
        }

        if (response.renderer) {
          // Returned if videoElement option passed in - Do something with renderer

          response.renderer.on("autoMuted", () => {
            // The browser refused to play video with audio therefore the stream was started muted.
            // Handle this case properly in your UI so that the user can unmute its stream
            this.mounted && this.setState({ videoIsMuted: true });
          });

          response.renderer.on("failedToPlay", reason => {
            if (reason === "failed-to-play") {
              // Failed to play media stream
              console.log(
                `joinChannel - subscriber callback: reason === "failed-to-play"`
              );
            }
            if (reason === "failed-to-play-unmuted") {
              // Failed to play media stream given the auto mute was disabled
              console.log(
                `joinChannel - subscriber callback: reason === "failed-to-play-unmuted"`
              );
            }
            if (reason === "paused-by-background") {
              // Unable to resume playback after pause in Safari
              console.log(
                `joinChannel - subscriber callback: reason === "paused-by-background"`
              );
            }
          });
        }
      }
    );
  }

  endStream = async () => {
    const { storeId, eventId } = this.props;
    console.log("endStream");

    this.mounted && this.setState({ streaming: false, videoIsMuted: false });
    this.channelExpress.dispose();
    this.chatService.stop();

    try {
      await getLiveSessionEnd(storeId, eventId)
        .then(response => {
          const dateTimeToRedirect = DateTime.fromISO(
            response.endDateTime
          ).plus({
            minutes: response.postEventItemAvailabilityDuration
          });

          const redirectTime = dateTimeToRedirect - new Date();

          const redirectTimeString = new Date(dateTimeToRedirect).toISOString();
          this.mounted && this.setState({ redirectTime: redirectTimeString });
          this.interval = setTimeout(
            () => this.mounted && this.setState({ redirect: true }),
            redirectTime
          );
        })
        .catch(err => {
          console.log("Error getting live events. " + err.message);
          console.log(storeId, eventId);
          this.interval = setTimeout(
            () => this.mounted && this.setState({ redirect: true }),
            10000
          );
          // throw new Error("Error getting live events. " + err.message);
        });
    } catch (err) {
      throw err;
    }
  };

  getChatFromPhenix(messages) {
    if (messages !== undefined) {
      const { chatData } = this.state;
      const difference = messages.filter(x => !chatData.includes(x));
      this.mounted &&
        this.setState({ chatData: [...chatData, ...difference] }, () =>
          this.jumpToBottomOfChat()
        );
    }
  }

  debounce = (func, wait, immediate) => {
    let timeout;
    return (...args) => {
      const later = () => {
        timeout = null;
        if (!immediate) func.apply(this, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(this, args);
    };
  };

  handleScroll = this.debounce(() => {
    const messages = document.getElementById("messages");

    if (messages) {
      const chatScrolledToBottom =
        messages.scrollHeight - messages.clientHeight <= messages.scrollTop + 1; // Fix for zoomed browser

      chatScrolledToBottom
        ? this.mounted &&
          this.setState({ chatScroll: true, jumpToRecentChat: false })
        : this.mounted &&
          this.setState({ chatScroll: false, jumpToRecentChat: true });
    }
  }, 100);

  jumpToBottomOfChat = override => {
    const { chatScroll } = this.state;

    const messages = document.getElementById("messages");

    if (messages) {
      if (chatScroll) {
        messages.scrollTop = messages.scrollHeight;
      }

      if (override) {
        messages.scrollTop = messages.scrollHeight;
      }
    }
  };

  handleScrollItems = this.debounce(() => {
    const productListWrapper = document.getElementById("product-list-wrapper");
    const productList = document.getElementById("product-list");

    if (!this.mounted) {
      return;
    }

    if (productListWrapper) {
      const itemsScrolledToTop = productListWrapper.scrollTop === 0
      const isScrolledToLeft = productList.scrollLeft === 0; // Fix for zoomed browser

      itemsScrolledToTop
        ? this.setState({ scrollY: true, jumpToRecentItem: false })
        : this.setState({ scrollY: false, jumpToRecentItem: true });
        isScrolledToLeft
        ? this.setState({ scrollX: true })
        : this.setState({ scrollX: false });
    }
  }, 100);

  jumpToTopOfItems = override => {
    const { scrollY, scrollX } = this.state;

    const productListWrapper = document.getElementById("product-list-wrapper");
    const productList = document.getElementById("product-list");

    if (productListWrapper) {
      if (scrollY) {
        productListWrapper.scrollTop = 0;
      }

      if (scrollX) {
        productList.scrollLeft = 0;
      }

      if (override) {
        productListWrapper.scrollTop = 0;
        productList.scrollLeft = 0;
      }
    }
  };

  fullScreenMode = () => {
    this.mounted &&
      this.setState(
        prevState => ({
          fullScreenMode: !prevState.fullScreenMode
        }),
        () => {
          this.state.fullScreenMode
            ? document.body.classList.add("full-screen-live")
            : document.body.classList.remove("full-screen-live");
          this.jumpToBottomOfChat(true);
          this.props.patterns.length && this.jumpToTopOfItems();
        }
      );
  };

  setFullScreenMode = state => {
    this.mounted &&
      this.setState(
        {
          fullScreenMode: state
        },
        () => {
          this.state.fullScreenMode
            ? document.body.classList.add("full-screen-live")
            : document.body.classList.remove("full-screen-live");
          this.jumpToBottomOfChat(true);
          this.state.liveProducts.length && this.jumpToTopOfItems();
        }
      );
  };

  determineFullScreenMode = () => {
    const { fullScreenMode } = this.state;
    const screenWidth = window.innerWidth;
    if (screenWidth > 767) {
      if (fullScreenMode === true) {
        this.setFullScreenMode(false);
      }
    }
  };

  toggleDisplayItems = () => {
    this.mounted &&
      this.setState(
        prevState => ({
          displayItems: !prevState.displayItems
        }),
        () => {
          this.jumpToTopOfItems();
        }
      );
  };

  setViewers = viewers => {
    this.mounted && this.setState({ viewers: viewers });
  };

  toggleVideoMute = () => {
    this.mounted && this.setState({ videoIsMuted: false });
  };

  toggleEmojiPicker = () => {
    this.mounted &&
      this.setState(prevState => ({
        displayEmojiPicker: !prevState.displayEmojiPicker
      }));
  };

  addEmoji = emoji => {
    const { chatInputValue } = this.state;
    this.mounted && this.setState({ chatInputValue: chatInputValue + emoji });
  };

  onChange = event => {
    this.mounted && this.setState({ chatInputValue: event.target.value });
  };

  handleButtonPress = () => {
    const { chatInputValue } = this.state;
    if (chatInputValue) {
      this.submitChat();
    }
  };

  handleUsernameChange = value => {
    const inputValue = typeof value === "object" ? value.target.value : value;
    this.mounted && this.setState({ liveUsername: inputValue });
    this.props.dispatch(preloadCustomerName(inputValue));
  };

  handleEmailChange = value => {
    const inputValue = typeof value === "object" ? value.target.value : value;
    this.mounted && this.setState({ liveEmail: inputValue });
    this.props.dispatch(preloadCustomerEmail(inputValue));
  };

  handleIdChange = value => {
    const inputValue = typeof value === "object" ? value.target.value : value;
    this.mounted && this.setState({ liveId: inputValue });
    this.props.dispatch(saveFacebookId(inputValue));
  };

  handleFacebookSignIn = response => {
    this.mounted && this.setState({ liveUsername: response });
    this.mounted && this.setState({ liveEmail: response });
  };

  emailAndUsernameSubmit = () => {
    this.mounted &&
      this.setState({ userInfoFilledOut: true }, () => {
        if (this.playbackSupported && window.innerWidth <= 767) {
          this.setFullScreenMode(true);
        }

        this.getStreamData();
      });
  };

  handleKeyPress = event => {
    const { chatInputValue, chatInputValueMax } = this.state;
    if (
      event.key === "Enter" &&
      chatInputValue &&
      chatInputValue.length <= chatInputValueMax
    ) {
      this.submitChat();
    }
  };

  confirmEnding(str, target) {
    return str.substr(-target.length) === target;
  }

  submitChat = () => {
    const { chatInputValue } = this.state;

    this.setState({
      chatMessageSending: true
    });

    //if (this.confirmEnding(chatInputValue, "?")) {
    //  postQuestionLive(
    //    this.props.storeId,
    //    liveChannelData.eventId,
    //    liveEmail,
    //    chatInputValue
    //  ).then(response => console.log(response));
    //}
    this.sendToPhenixChat(chatInputValue);
  };

  sendToPhenixChat = (message, isEmoji) => {
    const messageObject = JSON.stringify({
      type: 2,
      id: 0,
      username: this.state.liveUsername || "",
      data: message
    });

    this.jumpToBottomOfChat(true);

    if (this.state.streaming) {
      this.chatService.sendMessageToRoom(messageObject, (error, response) => {
        // send message callback
        if (response) {
          if (error) {
            // handle error - send message again
          }

          if (response.status !== "ok") {
            // handle error - send message again
          }

          if (response.status === "ok") {
            // Successfully sent message
            !isEmoji && this.mounted && this.setState({ chatInputValue: "" });
          }
          this.setState({
            chatMessageSending: false
          });
        }
      });
    }
  };

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  isoStringToTimestamp(isoString) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    const time = this.formatAMPM(date);

    if (dt < 10) {
      dt = "0" + dt;
    }

    if (month < 10) {
      month = "0" + month;
    }

    const todaysDate = new Date();

    if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return "Today " + time;
    }

    return year + "-" + month + "-" + dt + " " + time;
  }

  closeLightbox() {
    this.setState((state) => ({
      ...state,
      lightboxIsOpen: false,
      sizeChartUrl: ""
    }));
  }

  openLightbox(typeId) {
    this.fetchSizeChartUrl(typeId);
    this.setState((state) => ({
      ...state,
      lightboxIsOpen: true
    }));
  }

  fetchSizeChartUrl = async (typeId) => {
    const url = await getProductSizeChart(typeId);
    this.setState((state) => ({
      ...state,
      sizeChartUrl: url
    }));
  };

  render() {
    if (!this.playbackSupported) {
      if (this.facebookBrowser) {
        return <LiveSaleFacebookAppFallback />;
      } else {
        return <LiveSaleNoMpegFallback />;
      }
    }

    const {
      chatData,
      displayItems,
      chatInputValue,
      chatInputValueMax,
      displayEmojiPicker,
      fullScreenMode,
      jumpToRecentChat,
      jumpToRecentItem,
      liveChannelData,
      redirectTime,
      chatLoaded,
      videoLoaded,
      videoIsMuted,
      streaming,
      viewers,
      liveUsername,
      liveEmail,
      userInfoFilledOut,
      redirect,
      pubNubPublishKey,
      pubNubSubscribeKey,
      lightboxIsOpen,
      sizeChartUrl
    } = this.state;
    const { storeId, alias, eventId, activeEvent, patterns } = this.props;

    if (redirect || !activeEvent) {
      return <Redirect to={`/${alias}/live`} />;
    } else {
      return (
        <>
          <div className="row">
            <div className="col-12">
              <h4 className={Styles.bless_h3}>Live Sale</h4>
            </div>
          </div>
          {userInfoFilledOut ? (
            <div className={classNames({
              row: true,
              [Styles.muted]: videoIsMuted
            })}>
              <div className="col-12 col-md-6 col-lg-4 col-xl-4">
                <div
                  className={
                    fullScreenMode
                      ? Styles.live_sale_full_screen
                      : Styles.live_sale
                  }
                >
                  <div className={Styles.badge_wrapper}>
                    {streaming && (
                      <>
                        <span
                          className={`${Styles.badge} ${Styles.badge_live}`}
                        >
                          Live
                        </span>
                        <span className={Styles.badge}>
                          <FontAwesomeIcon icon="eye" /> {viewers}
                        </span>
                      </>
                    )}
                    {fullScreenMode && (
                      <button
                        onClick={this.fullScreenMode}
                        className={`btn btn-link ${Styles.full_screen_mode_close}`}
                      >
                        <FontAwesomeIcon icon="times" />
                      </button>
                    )}
                  </div>
                  <div className={Styles.video}>
                    {pubNubSubscribeKey && (
                      <VideoStream
                        streaming={streaming}
                        videoLoaded={videoLoaded}
                        fullScreenMode={fullScreenMode}
                        toggleVideoMute={this.toggleVideoMute}
                        videoIsMuted={videoIsMuted}
                        redirectTime={redirectTime}
                        heartCallback={this.postHeartEmoji}
                        pubnubPublishKey={pubNubPublishKey}
                        pubnubSubscribeKey={pubNubSubscribeKey}
                        channelInfo={liveChannelData}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 d-sm-none">
                <button
                  onClick={this.fullScreenMode}
                  className={`btn btn-block ${Styles.full_screen_mode}`}
                >
                  Watch Live Sale Full Screen
                </button>
              </div>
              <div className="col-12 col-md-6 col-lg-4 col-xl-4 mt-3 mt-md-0">
                {pubNubSubscribeKey && (
                  <LiveInventory
                    eventId={eventId}
                    eventName={activeEvent.description}
                    storeId={storeId}
                    fullScreenMode={fullScreenMode}
                    liveProducts={patterns}
                    channelInfo={liveChannelData}
                    jumpToRecentItem={jumpToRecentItem}
                    displayItems={displayItems}
                    handleScrollItems={this.handleScrollItems}
                    jumpToTopOfItems={this.jumpToTopOfItems}
                    toggleDisplayItems={this.toggleDisplayItems}
                    setViewers={this.setViewers}
                    liveUsername={liveUsername}
                    liveEmail={liveEmail}
                    userInfoFilledOut={userInfoFilledOut}
                    pubnubPublishKey={pubNubPublishKey}
                    pubnubSubscribeKey={pubNubSubscribeKey}
                    sizeChartOpen={lightboxIsOpen}
                    openSizeChart={this.openLightbox}
                  />
                )}
              </div>
              <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                <Chat
                  className={
                    fullScreenMode
                      ? Styles.live_chat_full_screen
                      : Styles.live_chat
                  }
                >
                  <Chat.Header>
                    {activeEvent && (
                      <div
                        className={
                          fullScreenMode ? "d-none" : Styles.live_chat_info
                        }
                      >
                        <div className={Styles.live_chat_cover}>
                          <ImageCover
                            className={Styles.live_chat_cover_img}
                            src={activeEvent.standardImageUrl}
                            alt={activeEvent.description}
                          />
                        </div>
                        <div className={Styles.live_chat_text}>
                          <div className={Styles.live_chat_header}>
                            {activeEvent.description}
                          </div>
                          <div className={Styles.live_chat_times}>
                            {this.formatAMPM(
                              new Date(activeEvent.startDateTime)
                            )}{" "}
                            -{" "}
                            {this.formatAMPM(new Date(activeEvent.endDateTime))}
                          </div>
                        </div>
                      </div>
                    )}
                  </Chat.Header>
                  <Chat.Body className={displayItems ? "d-none" : null}>
                    <div
                      className={classNames(Styles.live_chat_comment_total, {
                        "d-none": fullScreenMode
                      })}
                    >
                      {`${chatData.length} ${maybePluralize(
                        chatData.length,
                        "comment"
                      )}`}
                    </div>
                    <div
                      className={classNames(Styles.live_chat_area, {
                        "d-none": fullScreenMode && displayItems
                      })}
                      id="messages"
                      onScroll={this.handleScroll}
                    >
                      {chatLoaded ? (
                        chatData.map(message => (
                          <ChatBubble
                            key={message.messageId}
                            below={
                              <div
                                className={classNames(
                                  Styles.live_chat_timestamp,
                                  {
                                    [Styles.live_chat_timestamp_fullscreen]: fullScreenMode
                                  }
                                )}
                              >
                                {JSON.parse(message.message).username}{" "}
                                {this.isoStringToTimestamp(message.timestamp)}
                              </div>
                            }
                            fullScreenMode={fullScreenMode}
                          >
                            {JSON.parse(message.message).data}
                          </ChatBubble>
                        ))
                      ) : (
                        <LoadingSpinner />
                      )}
                    </div>
                  </Chat.Body>
                  <ChatInput
                    handleEmoji={this.sendToPhenixChat}
                    toggleEmojiPicker={this.toggleEmojiPicker}
                    handleButtonPress={this.handleButtonPress}
                    chatInputValueMax={chatInputValueMax}
                    onChange={this.onChange}
                    handleKeyPress={this.handleKeyPress}
                    jumpToRecentChat={jumpToRecentChat}
                    displayEmojiPicker={displayEmojiPicker}
                    displayItems={displayItems}
                    jumpToBottomOfChat={this.jumpToBottomOfChat}
                    chatInputValue={chatInputValue}
                    addEmoji={this.addEmoji}
                    streaming={streaming}
                  />
                </Chat>
              </div>
            </div>
          ) : (
            <LiveUserInfo
              handleUsernameChange={this.handleUsernameChange}
              handleEmailChange={this.handleEmailChange}
              handleIdChange={this.handleIdChange}
              emailAndUsernameSubmit={this.emailAndUsernameSubmit}
              liveUsername={liveUsername}
              liveEmail={liveEmail}
            />
          )}
          {sizeChartUrl && (
            <Lightbox
              images={[{ src: sizeChartUrl }]}
              isOpen={lightboxIsOpen}
              onClose={() => this.closeLightbox()}
              backdropClosesModal={true}
              showCloseButton={false}
              showImageCount={false}
              preventScroll={false}
            />
          )}
        </>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  const { store } = state;
  const { alias } = store;

  const allPatterns = getAllPatterns(state);

  const patterns = allPatterns
    .filter(pattern => (
      pattern.liveEventDetails.some(event => (
        event.liveEventId === ownProps.eventId &&
        new Date(event.publishedDateTime) < new Date()
      ))
    ))
    .map(pattern => ({
        ...pattern,
        detailsUrl: PageUrls.PatternDetailsPage(alias, pattern.listingPatternId)
    }));

  const currentEvent = activeLiveEvent(state);

  return {
    patterns,
    alias: state.store.alias,
    storeId: state.store.id,
    activeEvent: currentEvent
  };
};

export default connect(mapStateToProps)(LiveSalePage);
