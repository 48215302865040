type AggregatorFunction<T> = (aggregate: T, current: T) => T;
type AggregatedCallback<T> = (aggregatedValue: T) => void;

interface AggregatedDebounce<T> {
  (newValue: T): void;
  destroy: () => void;
};

export function createAggregateDebounce<T>(
  callback: AggregatedCallback<T>,
  aggregator: AggregatorFunction<T>,
  initialValue: Readonly<T>,
  interval: number
): AggregatedDebounce<T> {
  let value = initialValue;
  let timeout: number | null = null;

  let func = ((newValue: T) => {
    value = aggregator(value, newValue);
    if (!timeout) {
      timeout = window.setTimeout(() => {
        callback(value);
        value = initialValue;
        timeout = null;
      }, interval)
    }
  }) as AggregatedDebounce<T>;

  func.destroy = () => {
    timeout && window.clearTimeout(timeout);
  };

  return func;
}
