import { Component, ReactNode } from "react";
import { connect, MapStateToProps } from "react-redux";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import { State } from "../../store/state";
import { AlbumState } from "../../store/state/albums";
import { allAlbumsSelector } from "../../utilities/albums";
import { ProductManagerContext } from "./ProductManagerContext";

interface StateProps {
  albums: AlbumState[];
}

interface OwnProps {
  children?: ReactNode;
}

type Props = StateProps & ThunkDispatchProp & OwnProps;

class ListingGroupExpirationHandler extends Component<Props> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;
  
  albumExpirationTimers: { [index: number]: number } = {};

  render = () => null;

  componentDidMount() {
    this.props.albums.forEach(album => {
      this.setAlbumExpirationTimer(album);
    });
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.albums !== this.props.albums) {
      this.props.albums.forEach(album => {
        const oldAlbum = prevProps.albums.find(
          oldAlbum => oldAlbum.id === album.id
        );
        if (
          !oldAlbum ||
          oldAlbum.expirationDate !== album.expirationDate ||
          oldAlbum.publishDate !== album.publishDate
        ) {
          // The album is either new or its dates have changed.
          this.setAlbumExpirationTimer(album);
        }
      });
    }
  }

  componentWillUnmount() {
    Object.values(this.albumExpirationTimers).forEach(clearTimeout);
  }

  getNextGroupChange = (album: AlbumState): Date | null => {
    const publishDate = album.publishDate ? new Date(album.publishDate) : null;
    const expirationDate = album.expirationDate ? new Date(album.expirationDate) : null;

    return publishDate && publishDate > new Date() ? publishDate : expirationDate;
  }

  setAlbumExpirationTimer = (album: AlbumState) => {
    if (this.albumExpirationTimers.hasOwnProperty(album.id)) {
      clearTimeout(this.albumExpirationTimers[album.id]);
      delete this.albumExpirationTimers[album.id];
    }

    const nextChange = this.getNextGroupChange(album);
    if (nextChange) {
      let ticks = nextChange.getTime() - Date.now();
      // Make sure within 0 and 2147483646, the limits of setTimeout.
      ticks = Math.max(0, ticks);
      ticks = Math.min(2147483646, ticks);
      const event = {
        listingGroupIds: [album.id]
      };
      setTimeout(() => {
        this.context.signalListingGroupChange(event);
      }, ticks);
    }
  };
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = state => {
  return {
    albums: allAlbumsSelector(state)
  };
};

export default connect(mapStateToProps)(ListingGroupExpirationHandler);
