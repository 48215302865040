import React, { Component } from "react";
import YouTube, { Options } from "react-youtube";

interface Props {
  videoId: string;
}

export class YoutubePlayer extends Component<Props> {
  render() {
    const opts: Options = {
      height: "264",
      width: "470",
      playerVars: {
        autoplay: 0
      }
    };

    return <YouTube videoId={this.props.videoId} opts={opts} />;
  }
}
