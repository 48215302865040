import { Action } from "redux";
import { LiveEventState } from "../../store/state/liveEvents";
import * as liveApi from "../../apis/liveEndpoints";
import { ThunkResult } from "../thunkAction";

export interface UpdateEventsAction extends Action {
  type: "@@liveEvents/UPDATE_EVENTS";
  payload: LiveEventState[];
}

export interface RemoveEventsAction extends Action {
  type: "@@liveEvents/REMOVE_EVENTS";
  payload: number[];
}

export type EventsActions = 
  UpdateEventsAction | 
  RemoveEventsAction;

export const fetchAllEvents = (): ThunkResult => async (dispatch, getState) => {
  const { store } = getState();

  const details = await liveApi.getLiveEvents(store.id);
  dispatch({
    type: "@@liveEvents/UPDATE_EVENTS",
    payload: details.map<LiveEventState>(event => ({
      id: event.id,
      description: event.description,
      startDateTime: event.startDateTime,
      endDateTime: event.endDateTime,
      isEnded: event.isEnded,
      standardImageUrl: event.imageUrl,
      isActive: event.isActive,
      eventType: event.eventType
    }))
  });
};

export const fetchEventsByIds = (eventIds: number[]): ThunkResult => async (
  dispatch,
  getState
) => {
  const { store } = getState();

  const details = await liveApi.getLiveEvents(store.id);

  dispatch({
    type: "@@liveEvents/UPDATE_EVENTS",
    payload: details.map<LiveEventState>(event => ({
      id: event.id,
      description: event.description,
      startDateTime: event.startDateTime,
      endDateTime: event.endDateTime,
      isEnded: event.isEnded,
      standardImageUrl: event.imageUrl,
      isActive: event.isActive,
      eventType: event.eventType
    }))
  });

  // We tried to fetch these, but we are empty handed, so they must be old listings.
  const idsNotFetched = eventIds.filter(
    id => !details.some(event => event.id === id)
  );
  // Remove them from state.
  if (idsNotFetched.length > 0) {
    dispatch({
      type: "@@liveEvents/REMOVE_EVENTS",
      payload: idsNotFetched
    });
  }
};
