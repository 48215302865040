import React from "react";
import { node, string } from "prop-types";
import classNames from "classnames";

import Styles from "./ChatBody.module.scss";

const ChatBody = ({ children, className, ...props }) => (
  <div className={classNames(Styles.wrapper, className)} {...props}>
    {children}
  </div>
);

ChatBody.propTypes = {
  children: node.isRequired,
  className: string
};

export default ChatBody;
