import { Reducer } from "redux";
import {
  LiveEventState,
  initialLiveEventsState
} from "../store/state/liveEvents";
import deepEqual from "deep-equal";
import { EventsActions } from "../actions/liveEvents";

export const liveEventsReducer: Reducer<LiveEventState[], EventsActions> = (
  state = initialLiveEventsState,
  action
): LiveEventState[] => {
  switch (action.type) {
    case "@@liveEvents/UPDATE_EVENTS":
      // Only apply updates where the state is actually different.
      const eventsNeedingUpdate = action.payload.filter(
        newEvent => !deepEqual(newEvent, state.find(existingEvent => existingEvent.id === newEvent.id))
      );
      if (eventsNeedingUpdate.length > 0) {
        return [
          ...state.filter(event => !eventsNeedingUpdate.some(updated => updated.id === event.id)),
          ...eventsNeedingUpdate
        ];
      } else {
        // None of the wares needed an update. Return state as-is.
        return state;
      }
    case "@@liveEvents/REMOVE_EVENTS":
      if (action.payload.some(id => state.some(event => event.id === id))) {
        // At least one id in the list is in state,
        // so we need to create a new state that filters them out.
        return [...state.filter(event => !action.payload.includes(event.id))];
      } else {
        // None of the ids to remove are in state,
        // so we can just return the existing state as-is.
        return state;
      }
    default:
      return state;
  }
};
