import React, { Component } from "react";
import Styles from "./Footer.module.scss";
import { ExternalUrls } from "../../config";
import Contact from "./Contact";
import classnames from "classnames";

interface Props {
  hideContact: boolean;
  phoneNumber?: string;
  emailAddress?: string;
  facebookAccount?: string;
  twitterAccount?: string;
  instagramAccount?: string;
  pinterestAccount?: string;
}

interface ComponentState {
  llrPrivacyUrl: string;
  llrTermsUrl: string;
}

class Footer extends Component<Props, ComponentState> {
  state = {
    llrPrivacyUrl: "",
    llrTermsUrl: ""
  };
  async componentDidMount() {
    this.setState({
      llrPrivacyUrl: await ExternalUrls.LuLaRoePrivacyPolicyUrl(),
      llrTermsUrl: await ExternalUrls.LuLaRoeTermsUrl()
    });
  }

  render() {
    const { 
      hideContact,
      phoneNumber,
      emailAddress,
      facebookAccount,
      twitterAccount,
      instagramAccount,
      pinterestAccount
    } = this.props;

    return (
      <>
        {hideContact ? null : <Contact 
          emailAddress={emailAddress}
          phoneNumber={phoneNumber}
          facebookAccount={facebookAccount}
          twitterAccount={twitterAccount}
          instagramAccount={instagramAccount}
          pinterestAccount={pinterestAccount}
        />}
        <div className="row">
          <div
            className={classnames({
              [Styles.cardStyling]: hideContact,
              "col-12": true,
              "mb-1": true,
              "text-center": true,
              [Styles.terms]: true
            })}
          >
            <span className={Styles.notes}>
              Powered by <a
                href="https://www.lularoe.com/"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noopener"
              >
                <img
                    className={Styles.logo}
                    alt="LuLaRoe Logo"
                    src={require("../../images/LLR_Logo.svg")}
                />
              </a>
            </span>
            {this.state.llrPrivacyUrl && <span className={Styles.notes}>
              <a
                href={this.state.llrPrivacyUrl}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noopener"
              >
                Privacy Policy
              </a>
            </span>}
            {this.state.llrTermsUrl && <span className={Styles.notes}>
              <a
                href={this.state.llrTermsUrl}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noopener"
              >
                Terms and Conditions
              </a>
            </span>}
          </div>
        </div>
      </>
    );
  }
}

export default Footer;
