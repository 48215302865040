import React from "react";

export default () => <>
  <option key="" value="">--</option>
  <option key="AA" value="AA">AA</option>
  <option key="AE" value="AE">AE</option>
  <option key="AK" value="AK">AK</option>
  <option key="AL" value="AL">AL</option>
  <option key="AP" value="AP">AP</option>
  <option key="AR" value="AR">AR</option>
  <option key="AZ" value="AZ">AZ</option>
  <option key="CA" value="CA">CA</option>
  <option key="CO" value="CO">CO</option>
  <option key="CT" value="CT">CT</option>
  <option key="DC" value="DC">DC</option>
  <option key="DE" value="DE">DE</option>
  <option key="FL" value="FL">FL</option>
  <option key="GA" value="GA">GA</option>
  <option key="HI" value="HI">HI</option>
  <option key="IA" value="IA">IA</option>
  <option key="ID" value="ID">ID</option>
  <option key="IL" value="IL">IL</option>
  <option key="IN" value="IN">IN</option>
  <option key="KS" value="KS">KS</option>
  <option key="KY" value="KY">KY</option>
  <option key="LA" value="LA">LA</option>
  <option key="MA" value="MA">MA</option>
  <option key="MD" value="MD">MD</option>
  <option key="ME" value="ME">ME</option>
  <option key="MI" value="MI">MI</option>
  <option key="MN" value="MN">MN</option>
  <option key="MO" value="MO">MO</option>
  <option key="MS" value="MS">MS</option>
  <option key="MT" value="MT">MT</option>
  <option key="NC" value="NC">NC</option>
  <option key="ND" value="ND">ND</option>
  <option key="NE" value="NE">NE</option>
  <option key="NH" value="NH">NH</option>
  <option key="NJ" value="NJ">NJ</option>
  <option key="NM" value="NM">NM</option>
  <option key="NV" value="NV">NV</option>
  <option key="NY" value="NY">NY</option>
  <option key="OH" value="OH">OH</option>
  <option key="OK" value="OK">OK</option>
  <option key="OR" value="OR">OR</option>
  <option key="PA" value="PA">PA</option>
  <option key="PR" value="PR">PR</option>
  <option key="RI" value="RI">RI</option>
  <option key="SC" value="SC">SC</option>
  <option key="SD" value="SD">SD</option>
  <option key="TN" value="TN">TN</option>
  <option key="TX" value="TX">TX</option>
  <option key="UT" value="UT">UT</option>
  <option key="VA" value="VA">VA</option>
  <option key="VT" value="VT">VT</option>
  <option key="WA" value="WA">WA</option>
  <option key="WI" value="WI">WI</option>
  <option key="WV" value="WV">WV</option>
  <option key="WY" value="WY">WY</option>
</>;
