import React, { FunctionComponent } from "react";
import { Formatter } from "../../../Formatter";
import Styles from "./EstimatedOrderSummary.module.scss";

interface Props {
  estimatedSubtotal?: number;
  estimatedItemTax?: number;
  estimatedShippingAmount?: number;
  estimatedShippingTax?: number;
  estimatedGrandTotal?: number;
  promoCode?: string;
  showPromo?: boolean;
  dollarDiscountAmount?: number;
}
export const EstimatedOrderSummary: FunctionComponent<Props> = ({
  estimatedSubtotal,
  estimatedItemTax,
  estimatedShippingAmount,
  estimatedShippingTax,
  estimatedGrandTotal,
  promoCode,
  showPromo,
  dollarDiscountAmount
}) => (
  <table className={Styles.table}>
    <tbody>
      <tr>
        <td>Subtotal</td>
        <td>
          {estimatedSubtotal === undefined
            ? "--"
            : Formatter.format(estimatedSubtotal)}
        </td>
      </tr>
      {showPromo && promoCode ?
        <tr>
          <td>Promo Code {
              promoCode === undefined
              ? ""
              : <span className={Styles.uppercase}>{promoCode}</span>
            }
          </td>
          <td>
            {dollarDiscountAmount === undefined
              ? "--"
              : Formatter.format(-Math.abs(dollarDiscountAmount)) } 
          </td>
        </tr> : null
      }
      <tr>
        <td>Tax</td>
        <td>
          {estimatedItemTax === undefined
            ? "--"
            : Formatter.format(estimatedItemTax)}
        </td>
      </tr>
      <tr>
        <td>Shipping &amp; Handling</td>
        <td>
          {estimatedShippingAmount === undefined
            ? "--"
            : estimatedShippingAmount === 0 ? "FREE" : Formatter.format(estimatedShippingAmount)}
        </td>
      </tr>
      <tr>
        <td>Shipping &amp; Handling Tax</td>
        <td>
          {estimatedShippingTax === undefined
            ? "--"
            : Formatter.format(estimatedShippingTax)}
        </td>
      </tr>
      <tr>
        <td>Total</td>
        <td>
          {estimatedGrandTotal === undefined
            ? "--"
            : Formatter.format(estimatedGrandTotal)}
        </td>
      </tr>
    </tbody>
  </table>
);

export default EstimatedOrderSummary;
