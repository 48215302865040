import React, { Component } from "react";
import PatternList from "./PatternList";
import { connect, MapStateToProps } from "react-redux";
import {
    PatternItem,
    getAvailablePatterns
} from "../../utilities/patterns";
import {
    GetTypeTags,
    GetStyleTags,
    GetSizeTags,
    GetCollectionTags
} from "../../utilities/tags";
import { PatternListingDetails } from '../../apis/listingsEndpoints';
import { ListingPatternTagDetails } from '../../apis/listingsEndpoints';
import { State } from "../../store/state";

interface OwnProps {
    showFilters: boolean;
    listingPatternIds: number[];
    itemClassName?: string;
    listName: string;
    sizeChartOpen: boolean;
    openSizeChart: (typeId: number) => void;
}

interface StateProps {
    patterns: PatternItem[];
    items: PatternListingDetails[];
    collectionTags: ListingPatternTagDetails[];
    sizeTags: ListingPatternTagDetails[];
    styleTags: ListingPatternTagDetails[];
    typeTags: ListingPatternTagDetails[];
}

type Props = OwnProps & StateProps;

class SpecificPatternList extends Component<Props> {
    static defaultProps = {
        showFilters: false
    };

    render() {
        const {
            patterns,
            collectionTags,
            sizeTags,
            showFilters,
            styleTags,
            typeTags,
            itemClassName,
            listName,
            sizeChartOpen,
            openSizeChart
        } = this.props;
        return (
            <div>
                <PatternList
                    patterns={patterns}
                    collectionTags={collectionTags}
                    showFilters={showFilters}
                    sizeTags={sizeTags}
                    styleTags={styleTags}
                    typeTags={typeTags}
                    itemClassName={itemClassName}
                    listName={listName}
                    sizeChartOpen={sizeChartOpen}
                    openSizeChart={openSizeChart}
                />
            </div>
        );
    }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
    state,
    ownProps
) => {
    const { store } = state;

    const listingPatternsSelector = getAvailablePatterns;
    const patterns = listingPatternsSelector(state).filter(item => ownProps.listingPatternIds.includes(item.listingPatternId));
    let items: PatternListingDetails[] = [];
    patterns.forEach((pattern) => {
        pattern.listingDetails.forEach(listing => {
            items.push(listing)
        })
    })
    return {
        storeId: store.id,
        storeName: store.alias,
        patterns: patterns,
        items: items,
        typeTags: GetTypeTags(state),
        styleTags: GetStyleTags(state),
        sizeTags: GetSizeTags(state),
        collectionTags: GetCollectionTags(state)
    };
};

export default connect(mapStateToProps)(SpecificPatternList);
