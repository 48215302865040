import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { AlbumListItem } from "../../components/AlbumList/AlbumListItem/AlbumListItem";
import OrderCompleteSummary from "../../components/Checkout/OrderCompleteSummary/OrderCompleteSummary";
import { AlbumList } from "../../components/AlbumList/AlbumList";
import { activeAlbumsSelector } from "../../utilities/albums";
import { PageUrls } from "../../config";
import { Redirect } from "react-router";
import Styles from "./OrderCompletePage.module.scss";
import arrowIcon from "../../images/arrow_icon.svg";
import { CartListingType } from "../../utilities/cart";
import { Formatter } from "../../Formatter";
import { AlbumState } from "../../store/state/albums";
import { State } from "../../store/state";
import { CreatedOrder } from "../../store/state/checkout/order";
import { TrackCheckoutComplete } from "../../analytics";
import { TagCategoryDefs } from "../../enums/TagCategoryDefs";

interface StateProps {
  storeAlias: string;
  albums: AlbumState[];
  orderComplete: boolean;
  listings: CartListingType[];
  order?: CreatedOrder;
  storeId: number;
  promoCode?: string;
}

interface ComponentState {
  listings: CartListingType[];
}

class OrderCompletePage extends Component<StateProps, ComponentState> {

  constructor(props: StateProps) {
    super(props);

    // This is so the listings do not update with the redux store.
    // If this is not here, listings will disappear if they become sold out and drop off of state.
    this.state = {
      listings: props.listings,
    }
  }

  componentDidMount() {
    const { order, listings, storeId } = this.props;
    if (order) {
      TrackCheckoutComplete(
        listings,
        order.id,
        storeId,
        order.grandTotal,
        order.shippingTax + order.itemTax,
        order.shippingAmount
      );
    }
  }

  AlbumRow = (album: AlbumState) => {
    const image =
      album.images.find((image) => image.isPrimaryImage) || album.images[0];
    return (
      <AlbumListItem
        key={album.id}
        imageSrc={image.thumbnailImageUrl || image.standardImageUrl}
        link={PageUrls.AlbumDetailsPage(this.props.storeAlias, album.id)}
        name={album.title}
      />
    );
  };

  cartItem({ listingId, tagDetails, salePrice, images, styleDisplayName }: CartListingType, index: number) {
    const sizeName = tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name;
    const primaryImage =
      images ? images.find((image) => image.isPrimaryImage) || images[0] : undefined;

    return (
      <div className={`${Styles.checkout_item} clearfix`} key={`${listingId}-${index}`}>
        {
          primaryImage ? (
            <div className={Styles.checkout_item_image_area}>
              <img
                src={
                  primaryImage.thumbnailImageUrl || primaryImage.standardImageUrl
                }
                className={Styles.checkout_item_image}
                alt={styleDisplayName}
              />
            </div>) : (
              <></>
            )
        }
        <div className={Styles.checkoutItemDetails}>
          <span>{styleDisplayName}</span>
          <span style={{ float: "right" }}>
            {salePrice === undefined ? null : Formatter.format(salePrice)}
          </span>
          {sizeName ? (
            <div className={Styles.shopping_cart_size}>
              <span>Size: {sizeName}</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  render() {
    const {
      orderComplete,
      order,
      storeAlias,
      albums,
      promoCode
    } = this.props;
    const { listings } = this.state;

    if (!orderComplete) {
      return <Redirect to={PageUrls.StoreHomepage(storeAlias)} />;
    }

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h2 className={Styles.bless_h3}>Order Confirmation</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-4">
            <div className={Styles.checkout_header}>
              <span>Your order has been placed!</span>
              <div className={Styles.arrow_wrapper}>
                <img
                  className={Styles.arrow}
                  src={arrowIcon}
                  alt="Pointer Arrow"
                />
              </div>
            </div>
            <div className="form-group">
              <p className={Styles.order_number}>
                A Confirmation email is on it’s way! Your order number is{" "}
                <span style={{ fontWeight: "bold" }}>{order && order.id}.</span>
              </p>
              {listings.map(this.cartItem)}
              <div className={Styles.summary}>
                {order && (
                  <OrderCompleteSummary
                    {...order}
                    promoCode={promoCode}
                    showPromo={true}
                  />
                )}
              </div>
              <div className={Styles.orderListings} />
            </div>
          </div>
          <div className="col-12 col-sm-6 col-lg-8">
            <div className={Styles.checkout_header}>
              <span>More Albums you'll love...</span>
            </div>
            <div className={`row ${Styles.album_list_overwrite}`}>
              <AlbumList
                padPlaceholders={3}
                displayLimit={6}
                albums={albums.map((album) => ({
                  ...album,
                  link: PageUrls.AlbumDetailsPage(
                    this.props.storeAlias,
                    album.id
                  )
                }))}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, void, State> = (state) => {
  const patterns = state.patterns.items;

  const orderResponse = state.checkout.order.response;
  const orderSuccess =
    (!state.checkout.order.inProcess &&
      orderResponse &&
      orderResponse.isSuccess) ||
    false;
  const order =
    (orderResponse && orderResponse.isSuccess && orderResponse.createdOrder) ||
    undefined;

  const orderListings: CartListingType[] = [];

  if (order) {
    order.listingIds.forEach((orderListingId) => {
      let listing: CartListingType | undefined;

      const listingPattern = patterns
        .find((pattern) => pattern.listingDetails.find((listing) => orderListingId === listing.listingId));
      const patternListing = listingPattern?.listingDetails.find((item) => item.listingId === orderListingId);

      if (listingPattern && patternListing) {
        listing = {
          ...patternListing,
          listingPatternId: listingPattern.listingPatternId,
          styleDisplayName: listingPattern.styleDisplayName,
          patternTagDetails: listingPattern.tagDetails,
          images: listingPattern.imageDetails.map((image) => (
            {
              id: image.imageMapId,
              isPrimaryImage: image.isPrimary,
              viewingOrder: image.viewingOrder,
              fullImageUrl: image.imageUrl,
              standardImageUrl: image.imageUrl,
              thumbnailImageUrl: image.imageUrl,
            }
          )) || []
        }
      }

      if (listing) orderListings.push(listing);
    })
  }

  return {
    promoCode:
      state.checkout.customerInfo.data &&
      state.checkout.customerInfo.data.promoCode,
    order: order,
    orderComplete: orderSuccess,
    storeId: state.store.id,
    storeAlias: state.store.alias,
    albums: activeAlbumsSelector(state),
    listings: orderListings
  };
};

export default connect(mapStateToProps)(OrderCompletePage);
