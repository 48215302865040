import { Action } from "redux";
import { CheckoutBillingDataState } from "../../store/state/checkout/billing";

export interface UpdateBillingAction extends Action {
  type: "@@checkout/billing/UPDATE_BILLING";
  payload: CheckoutBillingDataState;
}

export interface MarkBillingEditingAction extends Action {
  type: "@@checkout/billing/MARK_EDITING";
  payload: {
    editAddress: boolean;
    editPayment: boolean;
  }
}

export interface MarkBillingDoneEditingAction extends Action {
  type: "@@checkout/billing/DONE_EDITING";
}

export interface MarkBillingFailureAction extends Action {
  type: "@@checkout/billing/MARK_FAILURE";
  payload: {
    errors: { [P in keyof CheckoutBillingDataState | "other"]?: string };
    addressFailure: boolean;
    paymentFailure: boolean;
  };
}

export interface ClearBillingStateAction extends Action {
  type: "@@checkout/billing/CLEAR_STATE";
}

export type BillingActions =
  | MarkBillingEditingAction
  | MarkBillingDoneEditingAction
  | UpdateBillingAction
  | MarkBillingFailureAction
  | ClearBillingStateAction;

export const updateBilling = (
  data: CheckoutBillingDataState
): UpdateBillingAction => {
  return { type: "@@checkout/billing/UPDATE_BILLING", payload: data };
};

export const markBillingEditing = (address: boolean, payment: boolean): MarkBillingEditingAction => {
  return { type: "@@checkout/billing/MARK_EDITING", payload: {
    editAddress: address,
    editPayment: payment
  } };
};

export const markBillingDoneEditing = (): MarkBillingDoneEditingAction => {
  return { type: "@@checkout/billing/DONE_EDITING" };
};

export const markBillingFailure = (
  errors: { [P in keyof CheckoutBillingDataState | "other"]?: string }
): MarkBillingFailureAction => {
  // !! = forcing boolean type
  // true if the thing is truthy (existent).
  const badAddress =
    !!errors.line1 ||
    !!errors.line2 ||
    !!errors.city ||
    !!errors.state ||
    !!errors.zipCode;
  const badPayment = 
    !!errors.paymentToken ||
    !!errors.other;
  return {
    type: "@@checkout/billing/MARK_FAILURE",
    payload: { 
      errors,
      addressFailure: badAddress,
      paymentFailure: badPayment
    }
  };
};

export const clearBillingState = (): ClearBillingStateAction => {
  return { type: "@@checkout/billing/CLEAR_STATE" };
};
