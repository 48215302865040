export interface LiveEventState {
  id: number;
  startDateTime: string;
  endDateTime?: string;
  description: string;
  isEnded: boolean;
  fullImageUrl?: string;
  standardImageUrl?: string;
  thumbnailImageUrl?: string;
  isActive: boolean;
  eventType: string;
}

export interface HeartRequest {
  storeId: number;
  eventId: number;
}

export const initialLiveEventsState: LiveEventState[] = [];
