import { DeliveryMethod } from "../../../enums/index";
import { RequestStateBase } from "../requestStateBase";

export interface CheckoutOrderState
  extends RequestStateBase<
    CheckoutPickupOrderRequestState | CheckoutShippingOrderRequestState,
    FailedOrderResponseState | SuccessfulOrderResponseState
  > {}

interface CheckoutOrderRequestStateBase {
  listingIds: number[];
  promoCode: string;
  utcOffset: number;
  deliveryMethod: DeliveryMethod;
  customer: {
    firstName: string;
    lastName: string;
    email: string;
  };
  billing: {
    paymentToken: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export interface CheckoutShippingOrderRequestState
  extends CheckoutOrderRequestStateBase {
  deliveryMethod: DeliveryMethod.Shipping;
  delivery: {
    firstName: string;
    lastName: string;
    line1: string;
    line2: string;
    city: string;
    state: string;
    zipCode: string;
  };
}

export interface CheckoutPickupOrderRequestState
  extends CheckoutOrderRequestStateBase {
  deliveryMethod: DeliveryMethod.Pickup;
  delivery: {
    firstName: string;
    lastName: string;
  };
}

export interface CreatedOrder {
  id: number;
  subtotal: number;
  itemTax: number;
  shippingAmount: number;
  shippingTax: number;
  grandTotal: number;
  listingIds: number[];
}

export interface SuccessfulOrderResponseState {
  isSuccess: true;
  createdOrder: CreatedOrder;
}

export interface FailedOrderResponseState {
  isSuccess: false;
  failures: {
    addressError?: string;
    zipCodeError?: string;
    cvvError?: string;
    otherError?: string;
  };
}

export function initialOrderState(): CheckoutOrderState {
  return {
    inProcess: false,
    request: undefined,
    response: undefined
  };
}
