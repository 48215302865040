import React, { FunctionComponent } from "react";
import Styles from "./OhSnap.module.scss";

interface Props {
  errorText?: string;
}

export const OhSnap: FunctionComponent<Props> = ({
  errorText
}) => (
  <div className={Styles.container}>
    <p className={Styles.header}>Oh snap!</p>
    <p>
      {errorText}
    </p>
  </div>
);

export default OhSnap;
