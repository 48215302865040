import { createSelector } from "reselect";
import {
  SizeCategoryName,
  TypeCategoryName,
  CollectionCategoryName,
  StyleCategoryName,
} from "../config";
import { ListingPatternTagDetails } from '../apis/listingsEndpoints';
import { TagCategoryDefs } from "../enums/TagCategoryDefs";
import { getAvailablePatterns } from './patterns';
import { activeAlbumsSelector } from './albums';

export const GetActiveTypeTags= createSelector(
  [getAvailablePatterns, activeAlbumsSelector],
  (patterns, albums) => (
    patterns
      .filter((pattern) => pattern.listingGroupIds.some((listingGroupId) => (
        albums.find((album) => !album.isVip && album.id === listingGroupId)
      )))
      .reduce<ListingPatternTagDetails[]>((acc, curr) => {
        curr.tagDetails.forEach((tag) => {
          if (tag.tagCategoryId === TagCategoryDefs.Type && !acc.find((accTag) => accTag.id === tag.id)) {
            acc.push(tag);
          }
        });

        return acc;
      },  [])
  )
);

interface CategorizedTags {
  [SizeCategoryName]: ListingPatternTagDetails[];
  [TypeCategoryName]: ListingPatternTagDetails[];
  [StyleCategoryName]: ListingPatternTagDetails[];
  [CollectionCategoryName]: ListingPatternTagDetails[];
}

const getCategorizedAvailableTags = createSelector(
  [getAvailablePatterns],
  (patterns) => (
    patterns.reduce<CategorizedTags>((acc, curr) => {
      curr.tagDetails.forEach((tag) => {
        switch (tag.tagCategoryId) {
          case TagCategoryDefs.Type:
            if (!acc[TypeCategoryName].find((accTag) => accTag.id === tag.id)) acc[TypeCategoryName].push(tag);
            break;
          case TagCategoryDefs.Style:
            if (!acc[StyleCategoryName].find((accTag) => accTag.id === tag.id)) acc[StyleCategoryName].push(tag);
            break;
          case TagCategoryDefs.Collection:
            if (!acc[CollectionCategoryName].find((accTag) => accTag.id === tag.id)) acc[CollectionCategoryName].push(tag);
            break;
          case TagCategoryDefs.Size:
            if (!acc[SizeCategoryName].find((accTag) => accTag.id === tag.id)) acc[SizeCategoryName].push(tag);
            break;
          default:
            break;
        }
      });

      curr.listingDetails
        .filter((listing) => listing.quantity > 0)
        .forEach((listing) => {
          listing.tagDetails.forEach((tag) => {
            switch (tag.tagCategoryId) {
              case TagCategoryDefs.Size:
                if (!acc[SizeCategoryName].find((accTag) => accTag.id === tag.id)) acc[SizeCategoryName].push(tag);
                break;
              case TagCategoryDefs.Type:
                if (!acc[TypeCategoryName].find((accTag) => accTag.id === tag.id)) acc[TypeCategoryName].push(tag);
                break;
              case TagCategoryDefs.Style:
                if (!acc[StyleCategoryName].find((accTag) => accTag.id === tag.id)) acc[StyleCategoryName].push(tag);
                break;
              case TagCategoryDefs.Collection:
                if (!acc[CollectionCategoryName].find((accTag) => accTag.id === tag.id)) acc[CollectionCategoryName].push(tag);
                break;
              default:
                break;
            }
          });
        });

      return acc;
    },  {
      [SizeCategoryName]: [],
      [TypeCategoryName]: [],
      [StyleCategoryName]: [],
      [CollectionCategoryName]: [],
    })
  ),
);

export const GetSizeTags = createSelector(
  [getCategorizedAvailableTags],
  (categorizedTags) => categorizedTags[SizeCategoryName],
);

export const GetTypeTags = createSelector(
  [getCategorizedAvailableTags],
  (categorizedTags) => categorizedTags[TypeCategoryName],
);

export const GetStyleTags = createSelector(
  [getCategorizedAvailableTags],
  (categorizedTags) => categorizedTags[StyleCategoryName],
);

export const GetCollectionTags = createSelector(
  [getCategorizedAvailableTags],
  (categorizedTags) => categorizedTags[CollectionCategoryName],
);
