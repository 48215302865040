import { FormStateBase } from "./formStateBase";

export interface CheckoutCustomerDataState {
  firstName: string;
  lastName: string;
  email: string;
  promoCode: string;
}

export interface CheckoutCustomerInfoState
  extends FormStateBase<CheckoutCustomerDataState> {}

export function initialCustomerState(): CheckoutCustomerInfoState {
  return {
    data: undefined,
    editing: false,
    failed: false
  };
}
