import axios from "axios";
import { storesApiBase } from "../config";

const liveEvents = (storeId: number) =>
  `${storesApiBase}/${storeId}/livesales/events`;
const facebookVideoUrl = (storeId: number) =>
    `${storesApiBase}/${storeId}/livesales/Video`;
const joinLiveSession = (storeId: number, eventId: number) =>
  `${storesApiBase}/${storeId}/livesales/events/${eventId}/join`;
const leaveLiveSession = (storeId: number, eventId: number) =>
  `${storesApiBase}/${storeId}/livesales/events/${eventId}/leave`;
const liveSessionEnd = (storeId: number, eventId: number) =>
  `${storesApiBase}/${storeId}/livesales/events/${eventId}/session/end`;
//const questionLive = (storeId: number, eventId: number) =>
//  `${storesApiBase}/${storeId}/livesales/events/${eventId}/questions`;
const postHeart = (storeId: number, eventId: number) =>
  `${storesApiBase}/${storeId}/livesales/${eventId}/heart`;
const heartbeat = (storeId: number, eventId: number) =>
  `${storesApiBase}/${storeId}/livesales/events/${eventId}/heartbeat`;
const getPhenixTokensEndpoint = (storeId: number, eventId: number) =>
  `${storesApiBase}/${storeId}/livesales/events/${eventId}/phenix/edgeToken`;

export interface LiveEventsResponse {
  id: number;
  description: string;
  imageUrl: string;
  startDateTime: string;
  endDateTime?: string;
  isActive: boolean;
  isEnded: boolean;
  eventType: string;
}

interface PhenixToken {
  token: string;
}

export async function getLiveEvents(
  storeId: number
): Promise<LiveEventsResponse[]> {
  return await axios({
    method: "get",
    url: liveEvents(storeId)
  }).then(response => response.data);
}

export async function getFacebookVideo(
  storeId: number,
): Promise<string> {
  return await axios({
    method: "get",
    url: facebookVideoUrl(storeId)
  }).then(response => response.data);
}

export async function postHeartEmoji(
  storeId: number,
  eventId: number
): Promise<void> {
  return await axios({
    method: "post",
    url: postHeart(storeId, eventId)
  }).then(response => response.data);
}

export interface JoinLiveEventResponse {
  eventId: number;
  videoChannelId: string;
  publicationChannelId: string;
  itemClaimsChannelId: string;
  questionChannelId: string;
  systemChannelId: string;
  attendance: number;
}

export async function postJoinLiveEvent(
  storeId: number,
  eventId: number,
  data: {
    deviceId: string;
    displayName: string;
    emailAddress: string;
    fullName: string;
  }
): Promise<JoinLiveEventResponse> {
  return await axios({
    method: "post",
    url: joinLiveSession(storeId, eventId),
    data: data
  }).then(response => response.data);
}

export async function postLeaveLiveEvent(
  storeId: number,
  eventId: number,
  data: {
    emailAddress: string;
  }
): Promise<void> {
  return await axios({
    method: "post",
    url: leaveLiveSession(storeId, eventId),
    data: data
  })
    .then(response => response.data)
    .catch(console.error);
}

export interface LiveSessionEndResponse {
  endDateTime: string;
}

export async function getLiveSessionEnd(
  storeId: number,
  eventId: number
): Promise<LiveSessionEndResponse[]> {
  const response = await axios.get<LiveSessionEndResponse[]>(
    liveSessionEnd(storeId, eventId)
  );
  return response.data;
}

export async function postHeartbeat(
  storeId: number,
  eventId: number,
  viewerId: string
): Promise<void> {
  return await axios({
    method: "post",
    url: heartbeat(storeId, eventId),
    data: {
      viewerId
    }
  }).then(response => response.data);
}

export async function getPhenixTokens(
  storeId: number,
  eventId: number,
): Promise<PhenixToken> {
  return await axios.get(
    getPhenixTokensEndpoint(storeId, eventId)
  ).then(response => response.data);
}
