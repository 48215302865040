import { PatternsState, initialListingPatternsState } from './patterns/index';
import { StoreBannerImage } from "./banners/index";
import { CheckoutState, initialCheckoutState } from "./checkout";
import { StoreState, initialStoreState } from "./store";
import { ChangesState, initialChangesState } from "./changes";
import { RouterState } from "connected-react-router";
import { AlbumState } from "./albums";
import { AppState, initialAppState } from "./app";
import { LiveEventState } from "./liveEvents";

export interface State {
  app: AppState;
  albums: AlbumState[];
  banners: StoreBannerImage[];
  checkout: CheckoutState;
  patterns: PatternsState;
  liveEvents: LiveEventState[];
  store: StoreState;
  changes: ChangesState;
  router: RouterState;
}

export const initialState: Readonly<State> = {
  app: initialAppState,
  albums: [],
  banners: [],
  checkout: initialCheckoutState,
  changes: initialChangesState,
  patterns: initialListingPatternsState,
  liveEvents: [],
  store: initialStoreState,
  router: {
    action: "PUSH",
    location: {
      hash: "",
      pathname: "",
      search: "",
      state: ""
    }
  },
};
