import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import queryString from "query-string";
import "react-toastify/dist/ReactToastify.css";
import { getAvailablePatterns, PatternItem } from "../../utilities/patterns";
import {
  GetTypeTags,
  GetStyleTags,
  GetSizeTags,
  GetCollectionTags
} from "../../utilities/tags";
import { State } from "../../store/state";
import { ListingPatternTagDetails } from '../../apis/listingsEndpoints';
import { activeAlbumsSelector } from "utilities/albums";
import PatternList from "./PatternList";

interface StateProps {
  patterns: PatternItem[];
  sizeTags: ListingPatternTagDetails[];
  styleTags: ListingPatternTagDetails[];
  typeTags: ListingPatternTagDetails[];
  collectionTags: ListingPatternTagDetails[];
  albumName: string;
}

interface OwnProps {
  albumId?: number;
  showFilters: boolean;
  showTypeFilter: boolean;
  typeListingGroup?: boolean;
  itemClassName?: string;
  filterParams?: Filters;
  query?: string;
  typeId?: string;
  sizeChartOpen: boolean;
  openSizeChart: (typeId: number) => void;
}

interface Filters {
  type?: number;
  style?: number;
  size?: number;
  collection?: number;
}

type Props = OwnProps & StateProps;

class FilteredProductList extends Component<Props> {
  static defaultProps = {
    showFilters: false,
    showTypeFilter: true
  };

  componentDidMount() {
    const { query } = this.props;

    let filterParamsToNumber: any = {};

    if (query) {
      const filterParams = queryString.parse(query);
      for (let key in filterParams) {
        filterParamsToNumber[key] = Number(filterParams[key]);
      }
    }
  }

  render() {
    const {
      showFilters,
      showTypeFilter,
      patterns,
      typeListingGroup,
      query,
      typeTags,
      styleTags,
      sizeTags,
      collectionTags,
      itemClassName,
      albumName,
      albumId,
      sizeChartOpen,
      openSizeChart
    } = this.props;

    let filterParamsToNumber: any = {};

    if (query) {
      const filterParams = queryString.parse(query);
      for (let key in filterParams) {
        filterParamsToNumber[key] = Number(filterParams[key]);
      }
    }

    return (
      <PatternList
        patterns={patterns}
        typeListingGroup={typeListingGroup}
        showFilters={showFilters}
        showTypeFilter={showTypeFilter}
        typeTags={typeTags}
        styleTags={styleTags}
        sizeTags={sizeTags}
        collectionTags={collectionTags}
        itemClassName={itemClassName}
        listName={albumName}
        filterParams={filterParamsToNumber}
        albumId={albumId}
        sizeChartOpen={sizeChartOpen}
        openSizeChart={openSizeChart}
      />
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  ownProps
) => {
  const { query, albumId } = ownProps;

  const activeAlbums = activeAlbumsSelector(state);

  let patterns: PatternItem[] = [];

  patterns = getAvailablePatterns(state);

  if (albumId) {
    patterns = patterns.filter(pattern => pattern.listingGroupIds.includes(albumId))
  } else {
    patterns = patterns.filter(pattern => pattern.listingGroupIds.some(id => {
      const albumData = activeAlbums.find(album => album.id === id)

      if (albumData) {
        return !albumData.isVip
      }

      return false;
    }))
  }

  const album = state.albums.find((album) => album.id === ownProps.albumId);

  let productQuery;

  if (query) {
    productQuery = query;
  } else {
    productQuery = state.router.location.search;
  }

  return {
    patterns,
    query: productQuery,
    typeTags: GetTypeTags(state),
    styleTags: GetStyleTags(state),
    sizeTags: GetSizeTags(state),
    collectionTags: GetCollectionTags(state),
    albumName: (album && album.title.replace("@", "")) || "",
    albumId: album?.id,
  };
};

export default connect(mapStateToProps)(FilteredProductList);