import { Reducer } from "redux";
import { AlbumState } from "../store/state/albums";
import { AlbumsActions } from "../actions/albums";

export const albumsReducer: Reducer<AlbumState[], AlbumsActions> = (
  state: AlbumState[] = [],
  action
): AlbumState[] => {
  switch (action.type) {
    case "@@albums/UPDATE_ALBUMS":
        return action.payload;
    default:
        return state;
  }
}