import React, { Component } from "react";
import Styles from "./NotFound.module.scss";
import { NotFoundImageUrl } from "config";

export class NotFound extends Component {
  render() {
    return (
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-2 col-md-4 col-sm-4" style={{position: 'absolute', zIndex: '1'}}>
            <p className={Styles.headerText}>Page not found</p>
            <p className={Styles.detailText}>Surely you were searching for more than an empty bag. Sorry, we can’t find the page you’re looking for.</p>
          </div>
          <div className="col-12 col-sm-10 offset-sm-1">
          <img
            src={NotFoundImageUrl}
            className={Styles.image}
            alt="white shopping bag"
          />
          </div>
        </div>
      </div>
    );
  }
}
