import { Action } from "redux";
import { toast } from "react-toastify";
import { ListingPatternModel } from './../../apis/listingsEndpoints';
import * as listingsApi from "../../apis/listingsEndpoints";
import { ThunkResult } from "../thunkAction";
import { ClaimContextType } from "../../enums/ClaimContextType";

export interface UpdateListingPatternsAction extends Action {
  type: "@@patterns/UPDATE_LISTING_PATTERNS";
  payload: ListingPatternModel[];
}

export interface RemoveListingPatternsAction extends Action {
  type: "@@patterns/REMOVE_LISTING_PATTERNS";
  payload: number[];
}

export interface MarkListingQuantitySoldAction extends Action {
  type: "@@patterns/MARK_QUANTITY_SOLD";
  payload: number[];
}

export interface ClaimListingQuantityAction extends Action {
  type: "@@patterns/CLAIM_LISTING_QUANTITY";
  payload: {
    listingId: number,
    expirationDateTime?: string,
  };
}

export interface UnclaimListingQuantityAction extends Action {
  type: "@@patterns/UNCLAIM_LISTING_QUANTITY";
  payload: number;
}

export interface UnclaimAllListingsAction extends Action {
  type: '@@patterns/UNCLAIM_ALL_LISTINGS';
}

export type ListingPatternsActions =
  | UpdateListingPatternsAction
  | RemoveListingPatternsAction
  | MarkListingQuantitySoldAction
  | ClaimListingQuantityAction
  | UnclaimListingQuantityAction
  | UnclaimAllListingsAction;

export const fetchListingPatterns = (): ThunkResult => async (dispatch, getState) => {
  const { store } = getState();

  const listingPatterns = await listingsApi.getListingPatterns(store.id);

  dispatch({
    type: "@@patterns/UPDATE_LISTING_PATTERNS",
    payload: listingPatterns as ListingPatternModel[]
  });
}

export const fetchListingPatternsByIds = (ids: number[]): ThunkResult => async (dispatch, getState) => {
  const { store } = getState();

  const details = await listingsApi.getListingPatternsByIds(store.id, ids);

  dispatch({
    type: "@@patterns/UPDATE_LISTING_PATTERNS",
    payload: details as ListingPatternModel[]
  });

  // We tried to fetch these, but we are empty handed, so they must be old listings.
  const idsNotFetched = ids.filter(
    (id) => !details.some((listing) => listing.listingPatternId === id)
  );
  // Remove them from state.
  if (idsNotFetched.length > 0) {
    dispatch({
      type: "@@patterns/REMOVE_LISTING_PATTERNS",
      payload: idsNotFetched
    });
  }
}

export const markListingSold = (listingIds: number[]): ThunkResult => async (
  dispatch,
  getState
) => {
  dispatch({ type: "@@patterns/MARK_QUANTITY_SOLD", payload: listingIds});
};

export const claimListing = (
  patterId: number,
  listingId: number,
  context?: ClaimContextType,
  contextId?: number
): ThunkResult<boolean> => async (dispatch, getState) => {
  const { store } = getState();

  const result = await listingsApi.claimListing(
    store.id,
    listingId,
    context,
    contextId
  );
  if (result.listingClaimed) {
    dispatch({ type: "@@patterns/CLAIM_LISTING_QUANTITY", payload: {
      listingId,
      expirationDateTime: result.expiresUtc,
    } });

    return true;
  } else {
    toast.error(
      result.notClaimedReason || "There was an error claiming the item"
    );

    return false;
  }
};

export const unclaimListing = (patternId: number, listingId: number): ThunkResult => async (
  dispatch,
  getState
) => {
  const { store } = getState();

  await listingsApi.unclaimListing(store.id, listingId);
  dispatch({ type: "@@patterns/UNCLAIM_LISTING_QUANTITY", payload: listingId });
};
