import axios from "axios";
import { storesApiBase } from "../config";

const allAlbumsDetailsEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/listinggroups`;

const albumDetailsByIdsEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/listinggroups/byids`;

export interface ApiAlbumDetail {
  id: number;
  title: string;
  publishDate?: string;
  expirationDate?: string;
  viewingOrder: number;
  images: ApiAlbumImageDetail[];
  isVip: boolean;
}

export interface ApiAlbumImageDetail {
  id: number;
  isPrimaryImage: boolean;
  viewingOrder: number;
  standardImageUrl: string;
  thumbnailImageUrl?: string;
  fullImageUrl?: string;
}

export function getAllAlbumDetails(
  storeId: number
): Promise<ApiAlbumDetail[]> {
  return axios({
    method: "get",
    url: allAlbumsDetailsEndpoint(storeId)
  }).then(response => Promise.resolve(response.data));
}

export function getAlbumsDetails(storeId: number, albumIds: number[]): Promise<ApiAlbumDetail[]> {
  return axios({
    method: "post",
    url: albumDetailsByIdsEndpoint(storeId),
    data: albumIds
  }).then(response => Promise.resolve(response.data));
}
