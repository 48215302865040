import { createContext } from "react";
import { DurationUnit, Duration } from "luxon";

interface TimerContextValues {
  isActive: boolean;
  duration: Duration;
  units: DurationUnit[];
  activeUnits: DurationUnit[];
}

const defaultTimerContext: TimerContextValues = {
  isActive: false,
  duration: Duration.fromMillis(0),
  units: [],
  activeUnits: []
};

export const TimerContext = createContext<TimerContextValues>(
  defaultTimerContext
);
