import axios from "axios";
import { storesApiBase } from "../config";
import { ClaimContextType } from "../enums/ClaimContextType";

const claimEndpoint = (
  storeId: number,
  listingId: number,
  context?: ClaimContextType,
  contextId?: number
) => {
  if (contextId) {
    return `${storesApiBase}/${storeId}/cart/add?listingId=${listingId}&claimContextType=${context}&contextId=${contextId}`;
  } else {
    return `${storesApiBase}/${storeId}/cart/add?listingId=${listingId}`;
  }
};

const unclaimEndpoint = (storeId: number, listingId: number) =>
  `${storesApiBase}/${storeId}/cart/remove?listingId=${listingId}`;

const suggestedPatternEndpoint = (storeId: number, listingPatternId: number) =>
    `${storesApiBase}/${storeId}/listingpatterns/${listingPatternId}/suggestions`;

const listingPatternsEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/listingPatterns`;

export interface PatternImage {
  imageMapId: number;
  imageUrl: string;
  isPrimary: boolean;
  viewingOrder: number;
}

export interface ListingPatternTagDetails {
  id: number;
  name: string;
  sortOrder: number;
  tagCategoryId: number;
}

export interface PatternListingDetails {
  listingId: number;
  productId: number;
  quantity: number;
  salePrice: number;
  msrp: number;
  tagDetails: ListingPatternTagDetails[];
  patternTagDetails: ListingPatternTagDetails[];
  styleDisplayName: string;
  claimBySessions: {
    claimStatus?: "Unclaimed" | "Claimed" | "ClaimedByYou";
    quantityClaimed: number;
    expiresAt?: string;
    isSold?: boolean;
  }[];
}

export interface ListingPatternModel {
  listingPatternId: number;
  reservedForLiveSelling: boolean;
  imageDetails: PatternImage[];
  listingDetails: PatternListingDetails[];
  liveEventDetails: {
    liveEventId: number;
    publishedDateTime: string;
  }[];
  listingGroupIds: number[];
  styleDisplayName: string;
  tagDetails: ListingPatternTagDetails[];
}

interface ClaimResponse {
  listingClaimed: boolean;
  notClaimedReason?: string;
  expiresUtc?: string;
}

export async function claimListing(
  storeId: number,
  listingId: number,
  context?: ClaimContextType,
  contextId?: number
): Promise<ClaimResponse> {
  return await axios
    .post<ClaimResponse>(claimEndpoint(storeId, listingId, context, contextId))
    .then((response) => response.data);
}

export async function unclaimListing(
  storeId: number,
  listingId: number
): Promise<void> {
  await axios.post(unclaimEndpoint(storeId, listingId));
}

export async function getSuggestedListingPatterns(
    storeId: number,
    listingPatternId: number
): Promise<number[]> {
    return await axios
        .get<number[]>(suggestedPatternEndpoint(storeId, listingPatternId))
        .then((response) => response.data);
}

export async function getListingPatterns(
  storeId: number
): Promise<ListingPatternModel[]> {
  return await axios({
    method: "get",
    url: listingPatternsEndpoint(storeId)
  }).then((response) => response.data);
}

export async function getListingPatternsByIds(
  storeId: number,
  listingPatternIds: number[]
): Promise<ListingPatternModel[]> {
  return await axios({
    method: "post",
    url: listingPatternsEndpoint(storeId),
    data: listingPatternIds
  }).then((response) => response.data);
}
