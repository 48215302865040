import thunk from "redux-thunk";
import reduxImmutableStateInvariant from "redux-immutable-state-invariant";
import { createStore, applyMiddleware } from "redux";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { rootReducer } from "../reducers/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import { initialState } from "./state/index";
import { State } from "./state/index";
import { AllActions } from "../actions";
import { Dispatch } from "react";
const history = createBrowserHistory();

const reduxActionTimingsMiddleware = () => (next: Dispatch<AllActions>) => (action: AllActions) => {
  if (!performance?.mark) return next(action);
  performance.mark(`${action.type}_start`);
  const result = next(action);
  performance.mark(`${action.type}_end`);
  performance.measure(
    `${action.type}`,
    `${action.type}_start`,
    `${action.type}_end`,
  );
  performance.clearMarks(`${action.type}_start`);
  performance.clearMarks(`${action.type}_end`);
  performance.clearMeasures(`${action.type}`);
  return result;
}

const middleware =  [
  routerMiddleware(history),
  thunk,
  reduxActionTimingsMiddleware
];

if (process.env.NODE_ENV !== 'production')
{
  middleware.push(reduxImmutableStateInvariant());
}

export function configureStore() {
  const store = createStore<State, AllActions, { dispatch: {} }, null>(
    rootReducer(history),
    initialState,
    composeWithDevTools(
      applyMiddleware(...middleware)
    )
  );

  return { store, history };
}
