import { CheckoutCustomerDataState } from "../../store/state/checkout/customer";
import { Action } from "redux";

export interface PreloadEmailAction extends Action {
  type: "@@checkout/customer/PRELOAD_EMAIL";
  payload: string;
}

export interface PreloadNameAction extends Action {
  type: "@@checkout/customer/PRELOAD_NAME";
  payload: string;
}

export interface SaveFacebookIdAction extends Action {
  type: "@@checkout/customer/SAVE_FACEBOOK_ID";
  payload: string;
}

export interface UpdateCustomerAction extends Action {
  type: "@@checkout/customer/UPDATE_CUSTOMER";
  payload: CheckoutCustomerDataState;
}

export interface MarkCustomerEditingAction extends Action {
  type: "@@checkout/customer/MARK_EDITING";
}

export interface MarkCustomerDoneEditingAction extends Action {
  type: "@@checkout/customer/DONE_EDITING";
}

export interface MarkCustomerFailureAction extends Action {
  type: "@@checkout/customer/MARK_FAILURE";
  payload: {
    errors: { [P in keyof CheckoutCustomerDataState | "other"]?: string };
  };
}

export interface ClearCustomerStateAction extends Action {
  type: "@@checkout/customer/CLEAR_STATE";
}

export type CustomerActions =
  | UpdateCustomerAction
  | MarkCustomerEditingAction
  | MarkCustomerDoneEditingAction
  | MarkCustomerFailureAction
  | ClearCustomerStateAction
  | PreloadEmailAction
  | PreloadNameAction
  | SaveFacebookIdAction;

export const updateCustomer = (
  data: CheckoutCustomerDataState
): UpdateCustomerAction => {
  return { type: "@@checkout/customer/UPDATE_CUSTOMER", payload: data };
};

export const markCustomerEditing = (): MarkCustomerEditingAction => {
  return { type: "@@checkout/customer/MARK_EDITING" };
};

export const markCustomerDoneEditing = (): MarkCustomerDoneEditingAction => {
  return { type: "@@checkout/customer/DONE_EDITING" };
};

export const markCustomerFailure = (
  errors: { [P in keyof CheckoutCustomerDataState | "other"]?: string }
): MarkCustomerFailureAction => {
  return {
    type: "@@checkout/customer/MARK_FAILURE",
    payload: { errors }
  };
};

export const clearCustomerState = (): ClearCustomerStateAction => {
  return { type: "@@checkout/customer/CLEAR_STATE" };
};

export const preloadCustomerEmail = (email: string): PreloadEmailAction => {
  return { type: "@@checkout/customer/PRELOAD_EMAIL", payload: email };
};

export const preloadCustomerName = (name: string): PreloadNameAction => {
  return { type: "@@checkout/customer/PRELOAD_NAME", payload: name };
};

export const saveFacebookId = (id: string): SaveFacebookIdAction => {
  return { type: "@@checkout/customer/SAVE_FACEBOOK_ID", payload: id };
};
