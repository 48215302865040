import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import Styles from "./AlbumListItem.module.scss";

interface Props {
  imageSrc?: string;
  name: string;
  link: string;
}
export const AlbumListItem: FunctionComponent<Props> = ({
  imageSrc,
  name,
  link
}) => {
  return (
    <div className={`col-6 col-md-4 col-lg-2 ${Styles.wrapper}`}>
      <Link className={Styles.no_link_underline} to={link}>
        <div className={Styles.album_card}>
          <div
            className={Styles.container}
            style={{
              background: imageSrc
                ? `url(${imageSrc}) center center / cover no-repeat`
                : ""
            }}
          />
          <div className={Styles.title}>{name}</div>
        </div>
      </Link>
    </div>
  );
};

export default AlbumListItem;
