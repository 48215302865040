import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import classnames from "classnames";
import Styles from "./EmailSignup.module.scss";
import ExpandArrowIcon from "./icon.arrow.down.svg";

interface SignupValues {
  firstName: string;
  lastName: string;
  email: string;
}

type FormTypes = "MailChimp" | "ShippingEasy";

interface Props {
  onSubmit: (values: SignupValues) => Promise<boolean>;
  formType: FormTypes;
}

interface ComponentState {
  isExpanded: boolean;
  isFinished: boolean;
  isSubmitting: boolean;
}

export class EmailSignup extends Component<Props, ComponentState> {
  unmounted = false;
  state = {
    isSubmitting: false,
    isExpanded: false,
    isFinished: false,
  };

  toggleExpand = () => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    });
  };

  handleSubmit = async (values: SignupValues) => {
    if (this.unmounted || this.state.isSubmitting || this.state.isFinished) {
      return;
    }

    this.setState({
      isSubmitting: true,
    });

    const result = await this.props.onSubmit(values);
    if (!this.unmounted) {
      this.setState({
        isFinished: result,
        isSubmitting: false,
      });
    }
  };

  getLegalText = () => {
    switch (this.props.formType) {
      case "MailChimp":
        return (
          <>
            We use Mailchimp as our marketing platform. By clicking “Sign up”
            above, you acknowledge that your information will be transferred to
            Mailchimp for processing.&nbsp;
            <a
              href="https://mailchimp.com/legal/privacy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more about Mailchimp's privacy practices.
            </a>
          </>
        );
      case "ShippingEasy":
      default:
        return (
          <>
            ShippingEasy is our shipping partner and by signing up, you
            acknowledge your email address will be shared with ShippingEasy for
            processing.&nbsp;
            <a
              href="https://support.shippingeasy.com/hc/en-us/articles/4407016994587"
              rel="noopener noreferrer"
              target="_blank"
            >
              Learn more about ShippingEasy’s privacy practices.
            </a>
          </>
        );
    }
  };

  readyState = () => {
    return (
      <>
        <div
          className={Styles.SignupExpander}
          onClick={() => {
            this.toggleExpand();
          }}
        >
          <div className={Styles.SignupText}>
            Sign up to get our latest updates!
          </div>
          <img
            className={Styles.ExpanderIcon}
            src={ExpandArrowIcon}
            alt="expand section"
          />
        </div>

        <div className={Styles.SignupExpandoSection}>
          <div
            className={classnames([Styles.SignupText, Styles.DesktopOnly])}
            onClick={() => {
              this.toggleExpand();
            }}
          >
            Sign up to get our latest updates!
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
            }}
            onSubmit={this.handleSubmit}
            render={() => (
              <div className={Styles.SignupFormWrapper}>
                <Form className={Styles.SignupForm}>
                  <Field
                    name="firstName"
                    className={Styles.SignupInput}
                    required
                    placeholder="First name"
                  />
                  <Field
                    name="lastName"
                    className={Styles.SignupInput}
                    required
                    placeholder="Last name"
                  />
                  <Field
                    name="email"
                    className={Styles.SignupInput}
                    type="email"
                    required
                    placeholder="Email address"
                  />
                  <button className={Styles.SignupSubmit} type="submit">
                    Sign up
                  </button>
                </Form>
              </div>
            )}
          />

          <div className={Styles.SignupDisclaimer}>{this.getLegalText()}</div>
        </div>
      </>
    );
  };

  submittingState = () => {
    // Do we want to add some sort of loader here?
    return null;
  };

  finishedState = () => {
    return (
      <div className={Styles.CompleteText}>
        <span className={Styles.CompleteTextIcon}>&#x1F389;</span>
        Congratulations! You're on the list for our future updates!
      </div>
    );
  };

  render() {
    return (
      <div
        className={classnames({
          [Styles.SignupContainer]: true,
          expanded: this.state.isExpanded,
        })}
      >
        {(() => {
          if (this.state.isFinished) return this.finishedState();
          if (this.state.isSubmitting) return this.submittingState();
          return this.readyState();
        })()}
      </div>
    );
  }

  componentWillUnmount() {
    this.unmounted = true;
  }
}
