import { Action } from "redux";
import { AlbumState } from "../../store/state/albums";
import * as albumsApi from "../../apis/albumsEndpoints";
import { ThunkActionCreator } from "../thunkAction";
import deepEqual from "deep-equal";

export interface UpdateAlbumsAction extends Action {
  type: "@@albums/UPDATE_ALBUMS";
  payload: AlbumState[];
}

export type AlbumsActions = UpdateAlbumsAction;

export const fetchAllAlbums: ThunkActionCreator<
  UpdateAlbumsAction
> = () => async (dispatch, getState) => {
  const { store, albums } = getState();

  const details = (await albumsApi.getAllAlbumDetails(store.id)).sort(
    (albumA, albumB) => albumA.id - albumB.id
  );

  if (!deepEqual(details, albums)) {
    dispatch({
      type: "@@albums/UPDATE_ALBUMS",
      payload: details
    });
  }
};

export const fetchAlbumsByIds: ThunkActionCreator<UpdateAlbumsAction> = (
  albumIds: number[]
) => async (dispatch, getState) => {
  const { store, albums } = getState();

  const details = await albumsApi.getAlbumsDetails(store.id, albumIds);

  const newAlbums = [
    // Map existing or updated albums
    ...albums.map(album => {
      // filter out ones that are not being requested for update (return existing)
      if (!albumIds.includes(album.id)) {
        return album;
      }
      
      // If they are not found, it means the server considers them deleted.
      return details.find(newAlbum => newAlbum.id === album.id);
    }).filter(album => !!album) as AlbumState[], // filter out nulls (albums that have been deleted, for instance)
    // Also, add any new albums that were not in the state before.
    ...details.filter(newAlbum => !albums.some(album => album.id === newAlbum.id))
  ];

  dispatch({
    type: "@@albums/UPDATE_ALBUMS",
    payload: newAlbums
  });
};
