import { FormStateBase } from "./formStateBase";
import { DeliveryMethod } from "../../../enums/index";

export interface CheckoutDeliveryDataState {
  sameNameAsCustomer: boolean;
  method: DeliveryMethod | "";
  firstName: string;
  lastName: string;
  line1: string;
  line2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface CheckoutDeliveryInfoState
  extends FormStateBase<CheckoutDeliveryDataState> {}

export function initialDeliveryState(): CheckoutDeliveryInfoState {
  return {
    data: undefined,
    editing: false,
    failed: false
  };
}
