import { Action } from "redux";
import { CheckoutDeliveryDataState } from "../../store/state/checkout/delivery";

export interface UpdateDeliveryAction extends Action {
  type: "@@checkout/delivery/UPDATE_DELIVERY";
  payload: CheckoutDeliveryDataState;
}

export interface MarkDeliveryEditingAction extends Action {
  type: "@@checkout/delivery/MARK_EDITING";
}

export interface MarkDeliveryDoneEditingAction extends Action {
  type: "@@checkout/delivery/DONE_EDITING";
}

export interface MarkDeliveryFailureAction extends Action {
  type: "@@checkout/delivery/MARK_FAILURE";
  payload: {
    errors: { [P in keyof CheckoutDeliveryDataState | "other"]?: string };
  };
}

export interface ClearDeliveryStateAction extends Action {
  type: "@@checkout/delivery/CLEAR_STATE";
}

export type DeliveryActions =
  | UpdateDeliveryAction
  | MarkDeliveryDoneEditingAction
  | MarkDeliveryEditingAction
  | MarkDeliveryFailureAction
  | ClearDeliveryStateAction;

export const updateDelivery = (
  data: CheckoutDeliveryDataState
): UpdateDeliveryAction => {
  return { type: "@@checkout/delivery/UPDATE_DELIVERY", payload: data };
};

export const markDeliveryEditing = (): MarkDeliveryEditingAction => {
  return { type: "@@checkout/delivery/MARK_EDITING" };
};

export const markDeliveryDoneEditing = (): MarkDeliveryDoneEditingAction => {
  return { type: "@@checkout/delivery/DONE_EDITING" };
};

export const markDeliveryFailure = (
  errors: { [P in keyof CheckoutDeliveryDataState | "other"]?: string }
): MarkDeliveryFailureAction => {
  return {
    type: "@@checkout/delivery/MARK_FAILURE",
    payload: { errors }
  };
};

export const clearDeliveryState = (): ClearDeliveryStateAction => {
  return { type: "@@checkout/delivery/CLEAR_STATE" };
};
