import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { Redirect } from "react-router-dom";
import { State } from "../../store/state";
import { getAllPatterns } from "../../utilities/patterns";

interface StateProps {
  storeAlias: string;
  redirectUrl?: string;
}

interface OwnProps {
  listingId: number;
  albumId?: number;
}

type Props = StateProps & OwnProps;

const ProductDetailPage = ({ storeAlias, redirectUrl }: Props) => (
  <Redirect to={redirectUrl || `/${storeAlias}`} />
);

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state,
  ownProps
) => {
  const patternId = getAllPatterns(state)
    .find(pattern => pattern.listingDetails.some(listing => listing.listingId === ownProps.listingId))?.listingPatternId;

  const redirectUrl = patternId ? `/${state.store.alias}/pattern/${patternId}` : undefined;

  return {
    storeAlias: state.store.alias,
    redirectUrl
  };
};

export default connect(mapStateToProps)(ProductDetailPage);
