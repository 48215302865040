import { patternsReducer } from './patternsReducer';
import { bannersReducer } from "./bannersReducer";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { storeReducer } from "./storeReducer";
import { checkoutReducer } from "./checkoutReducer";
import { State } from "../store/state";
import { changesReducer } from "./changesReducer";
import { History } from "history";
import { albumsReducer } from "./albumsReducer";
import { appReducer } from "./appReducer";
import { liveEventsReducer } from "./liveEventsReducer";

export const rootReducer = (history: History<any>) =>
  combineReducers<State>({
    app: appReducer,
    changes: changesReducer,
    checkout: checkoutReducer,
    patterns: patternsReducer,
    liveEvents: liveEventsReducer,
    router: connectRouter(history),
    store: storeReducer,
    albums: albumsReducer,
    banners: bannersReducer
  });
