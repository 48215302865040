import axios from "axios";
import { storesApiBase } from "../config";
import {
  CheckoutPickupEstimateRequestState,
  CheckoutShippingEstimateRequestState,
  CheckoutEstimateResponseState,
  FailedEstimateResponseState,
  DiscountEstimateResponseState,
  DiscountEstimateRequest,
} from "../store/state/checkout/estimate";
import {
  CheckoutPickupOrderRequestState,
  CheckoutShippingOrderRequestState,
  SuccessfulOrderResponseState,
  FailedOrderResponseState,
} from "../store/state/checkout/order";
import {
  ShippingAmountRequestState,
  ShippingAmountResponseState,
  FailedShippingAmountResponseState,
} from "../store/state/checkout/shipping";
import {
  PromoCodeCheckRequest,
  PromoCodeCheckResponse,
} from "./promoCodeModels";

const shippingEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/orders/estimateshipping`;
const recaptchaEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/orders/verify-google-recaptcha`;
const discountEstimateEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/orders/estimatediscount`;
const estimationEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/orders/estimate`;
const orderEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/orders/create`;
const promoCodeCheckEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/promocodes/check`;

export type ShippingRequest = ShippingAmountRequestState;
export type ShippingResponse =
  | ShippingAmountResponseState
  | FailedShippingAmountResponseState;
export type EstimateRequest =
  | CheckoutPickupEstimateRequestState
  | CheckoutShippingEstimateRequestState;
export type EstimateResponse =
  | CheckoutEstimateResponseState
  | FailedEstimateResponseState;
export type OrderRequest =
  | CheckoutPickupOrderRequestState
  | CheckoutShippingOrderRequestState;
export type OrderResponse =
  | SuccessfulOrderResponseState
  | FailedOrderResponseState;
  export type RecaptchaResponse = {
    isRecaptchaValidated: boolean;
  };

export async function getShippingAmount(
  storeId: number,
  request: ShippingRequest
): Promise<ShippingResponse> {
  return await axios
    .post<ShippingResponse>(shippingEndpoint(storeId), request)
    .then<ShippingResponse>((response) => response.data);
}

export async function getCaptchaVerification(
  storeId: number,
  challengeToken: string
) {
  return axios
    .post<RecaptchaResponse>(recaptchaEndpoint(storeId), {
      challengeToken: challengeToken,
    })
    .then((response) => response.data);
}

export async function getDiscountEstimate(
  storeId: number,
  request: DiscountEstimateRequest
) {
  return axios
    .post<DiscountEstimateResponseState>(
      discountEstimateEndpoint(storeId),
      request
    )
    .then((response) => response.data);
}

export async function getOrderEstimate(
  storeId: number,
  request: EstimateRequest
) {
  return axios
    .post<EstimateResponse>(estimationEndpoint(storeId), request)
    .then((response) => response.data);
}

export async function placeOrder(storeId: number, request: OrderRequest) {
  return axios
    .post<OrderResponse>(orderEndpoint(storeId), request)
    .then((response) => response.data);
}

export async function checkPromoCode(
  storeId: number,
  request: PromoCodeCheckRequest
) {
  const result = await axios.post<PromoCodeCheckResponse>(
    promoCodeCheckEndpoint(storeId),
    request
  );
  return result.data;
}
