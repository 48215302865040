import axios from "axios";
import { storesApiBase } from "../config";

const submitStoreReviewEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/reviews/submit`;
const getStoreReviewEndpoint = (storeId: number, reviewId: string) =>
  `${storesApiBase}/${storeId}/reviews/${reviewId}`;

interface RetailerRatingResponse {
  retailerRatingId: number;
  comment: string;
  stars: number;
  orderId: number;
  createdDate: Date;
  modifiedDate: Date;
}

export async function getStoreReview(
  storeId: number,
  reviewId: string
): Promise<RetailerRatingResponse> {
  return axios({
    method: "GET",
    url: getStoreReviewEndpoint(storeId, reviewId)
  }).then((response) => Promise.resolve(response.data));
}

export async function submitStoreReview(
  storeId: number,
  data: {
    orderIdentifier: string;
    comments: string;
    rating: number;
  }
): Promise<void> {
  return axios({
    method: "POST",
    url: submitStoreReviewEndpoint(storeId),
    data: data
  }).then((response) => Promise.resolve(response.data));
}
