import React, { FunctionComponent } from "react";
import RetailerBio from "../../components/RetailerBio/RetailerBio";
import { connect, MapStateToProps } from "react-redux";
import { State } from "../../store/state";
import { buildBioInfo, BioInfo } from "../../utilities/bio";

interface StateProps {
  bio: BioInfo;
}

export const AboutPage: FunctionComponent<StateProps> = ({ bio }) => (
  <RetailerBio {...bio} />
);

const mapStateToProps: MapStateToProps<StateProps, void, State> = (
  state: State
) => {
  return {
    bio: buildBioInfo(state)
  };
};

export default connect(mapStateToProps)(AboutPage);
