import React from "react";
import Styles from "./LiveSalePage.module.scss";

export class LiveSaleNoMpegFallback extends React.Component {
  isAndroid = (navigator.userAgent || navigator.vendor).indexOf("Android") > -1;

  render() {
    return <div className={Styles.fallback}>
      <p className={Styles.fallbackAlert}>Unsupported Browser</p>
      <p>Your browser does not support this live sale video. Please try another browser or device.</p>
      {this.isAndroid && <p>
        For Android devices, we recommend downloading <a href="https://play.google.com/store/apps/details?id=com.android.chrome&amp;hl=en_US">Chrome</a> from the Play Store.
      </p>}
    </div>;
  }
}

export class LiveSaleFacebookAppFallback extends React.Component {
  render() {
    return <div className={Styles.fallback}>
      <p className={Styles.fallbackAlert}>Facebook Browser Not Supported</p>
      <p>You are using Facebook's in-app browser, which does not support this live sale video.</p>
      <p>To join this event, please use your phone's full browser:</p>
      <ol>
        <li>
          Click the menu icon at the top right
          <br />
          <img src={require("./AndroidFacebookFallback1.png")} alt="screenshot" />
        </li>
        <li>
          Then, choose to open the site in your phone's browser
          <br />
          <img src={require("./AndroidFacebookFallback2.png")} alt="screenshot" />
        </li>
      </ol>
    </div>;
  }
}
