import { AlbumState } from "../store/state/albums";
import { getAvailablePatterns } from "./patterns";
import { createSelector } from "reselect";
import { State } from "../store/state";

export function isAlbumActive(album: AlbumState) {
  if (!album) return false;
  if (album.publishDate && Date.parse(album.publishDate) > Date.now()) {
    return false;
  }
  if (album.expirationDate && Date.parse(album.expirationDate) < Date.now()) {
    return false;
  }
  return true;
}

export function allAlbumsSelector(state: State): AlbumState[] {
  return [...state.albums].sort(
    (album1, album2) => album1.viewingOrder - album2.viewingOrder
  );
}

export const activeAlbumsSelector = createSelector(
  allAlbumsSelector,
  getAvailablePatterns,
  (albums, patterns) => {
    let activeAlbums: AlbumState[] = albums.filter(isAlbumActive);

    activeAlbums = activeAlbums
      .filter(album =>
        patterns.some(
          pattern =>
            pattern.listingGroupIds.includes(album.id)
        )
      );

    return activeAlbums
  }
);
