import React, { FunctionComponent } from "react";
import { Formatter } from "../../../Formatter";
import Styles from "./OrderCompleteSummary.module.scss";

interface Props {
  subtotal: number;
  itemTax: number;
  dollarDiscountAmount?: number;
  promoCode?: string;
  shippingAmount: number;
  shippingTax: number;
  showPromo?: boolean;
  grandTotal: number;
}

export const OrderCompleteSummary: FunctionComponent<Props> = ({
  subtotal,
  itemTax,
  dollarDiscountAmount,
  promoCode,
  shippingAmount,
  shippingTax,
  grandTotal,
  showPromo
}) => (
  <table className={Styles.table}>
    <tbody>
      <tr>
        <td>Subtotal</td>
        <td>
          {subtotal === undefined ? null : Formatter.format(subtotal)}
        </td>
      </tr>
      {showPromo && promoCode &&
      <tr>
      <td>Promo Code {promoCode === undefined
            ? ""
            : <span className={Styles.uppercase}>{promoCode}</span>}</td>
        <td>
          {dollarDiscountAmount === undefined
            ? "--"
            : Formatter.format(-Math.abs(dollarDiscountAmount)) } 
        </td>
      </tr>
      }
      <tr>
        <td>Tax</td>
        <td>
          {itemTax === undefined ? null : Formatter.format(itemTax)}
        </td>
      </tr>
      <tr>
        <td>Shipping &amp; Handling</td>
        <td>
          {shippingAmount === undefined
            ? null
            : Formatter.format(shippingAmount)}
        </td>
      </tr>
      <tr>
        <td>Shipping &amp; Handling Tax</td>
        <td>
          {shippingTax === undefined
            ? null
            : Formatter.format(shippingTax)}
        </td>
      </tr>
      <tr>
        <td>Total</td>
        <td>
          {grandTotal === undefined
            ? null
            : Formatter.format(grandTotal)}
        </td>
      </tr>
    </tbody>
  </table>
);

export default OrderCompleteSummary;
