import React, { Component } from "react";
import Styles from "./Contact.module.scss";

interface Props {
  phoneNumber?: string;
  emailAddress?: string;
  facebookAccount?: string;
  twitterAccount?: string;
  instagramAccount?: string;
  pinterestAccount?: string;
}

class Contact extends Component<Props> {
  render() {
    const {
      phoneNumber,
      emailAddress,
      facebookAccount,
      twitterAccount,
      instagramAccount,
      pinterestAccount
    } = this.props;

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h6 className={`mb-3 mt-5 text-center ${Styles.contactInfoHeader}`}>
              Contact Information
            </h6>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-3 text-center">
            {phoneNumber ? (
              <a href={"tel:" + phoneNumber}>
                <img
                  alt="phone icon"
                  className={Styles.socialLogo}
                  src={require("../../images/icon.phone.svg")}
                />
              </a>
            ) : null}
            {emailAddress ? (
              <a href={"mailto:" + emailAddress}>
                <img
                  alt="email icon"
                  className={Styles.socialLogo}
                  src={require("../../images/icon.email.svg")}
                />
              </a>
            ) : null}
            {facebookAccount ? (
              <a
                href={facebookAccount}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="facebook icon"
                  className={Styles.socialLogo}
                  src={require("../../images/icon.facebook.svg")}
                />
              </a>
            ) : null}
            {twitterAccount ? (
              <a
                href={twitterAccount}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="twitter icon"
                  className={Styles.socialLogo}
                  src={require("../../images/icon.twitter_Color.svg")}
                />
              </a>
            ) : null}
            {pinterestAccount ? (
              <a
                href={pinterestAccount}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="pintrest icon"
                  className={Styles.socialLogo}
                  src={require("../../images/icon.pinterest.svg")}
                />
              </a>
            ) : null}
            {instagramAccount ? (
              <a
                href={instagramAccount}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="instagram icon"
                  className={Styles.socialLogo}
                  src={require("../../images/icon.instagram_Color.png")}
                />
              </a>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default Contact;
