import axios from "axios";
import { storesApiBase } from "../config";

const changesSinceEndpoint = (storeId: number, lastChangeId: string) =>
  `${storesApiBase}/${storeId}/changes?lastChangeId=${lastChangeId}`;

const maxChangeEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/changes/max`;

export interface ApiChangesResponse {
  latestChangeId: string;
  listingChanges: number[];
  listingPatternChanges: number[];
  albumChanges: number[];
  storeChanges: boolean;
  eventChanges: number[];
}

export function getLatestChangeId(storeId: number): Promise<string> {
  return axios({
    method: "get",
    url: maxChangeEndpoint(storeId)
  }).then<string>((response) => Promise.resolve(response.data));
}

export function getChangesSince(
  storeId: number,
  lastChangeId: string
): Promise<ApiChangesResponse> {
  return axios({
    method: "get",
    url: changesSinceEndpoint(storeId, lastChangeId)
  }).then<ApiChangesResponse>((response) => Promise.resolve(response.data));
}
