import { StoreBannerImage } from "./../store/state/banners/index";
import { createSelector } from "reselect";
import { State } from "../store/state";

export function allBannerImagesSelector(state: State): StoreBannerImage[] {
  return [...state.banners].sort(
    (image1, image2) => image1.displayOrder - image2.displayOrder
  );
}

export const activeBannerImagesSelector = createSelector(
  allBannerImagesSelector,
  (images) => {
    return images.filter((images) => images.isActive);
  }
);
