import React, { Component } from "react";
import Styles from "./BillingForm.module.scss";

interface OwnProps {
  canEdit: boolean;
  error?: string;
  handleEdit: () => void;
}
type Props = OwnProps;

export class PaymentFormSummary extends Component<Props> {
  render() {
    const { canEdit, handleEdit } = this.props;
    return (
      <>
      <br/>
        <div className={Styles.input_header_shipping}>Credit Card Information</div>
        <p>
          Card ****************
        </p>
        {canEdit && (
          <button
            type="button"
            className="btn btn-link"
            onClick={handleEdit}
          >
            Edit
          </button>
        )}
      </>
    );
  }
}

export default PaymentFormSummary;
