import { createSelector } from "reselect";
import { PatternListingDetails, ListingPatternTagDetails } from '../apis/listingsEndpoints';
import { getAllPatterns } from "./patterns";
import { ClaimStatus } from "../enums";

export interface CartListingType extends PatternListingDetails {
  listingPatternId: number;
  styleDisplayName: string;
  patternTagDetails: ListingPatternTagDetails[];
  hangerCode?: string;
  images: {
    id: number,
    isPrimaryImage: boolean,
    viewingOrder: number,
    fullImageUrl: string,
    standardImageUrl: string,
    thumbnailImageUrl: string,
  }[];
}

export const getClaimedListings = createSelector(
  getAllPatterns,
  (patterns) => {
    let filteredPatterns = patterns.filter((pattern) =>
      pattern.listingDetails
        .some(listing =>
          listing.claimBySessions
            .some(claim => claim.claimStatus === ClaimStatus.ClaimedByYou && claim.quantityClaimed > 0 && !claim.isSold)
        )
    );

    let cartListings: CartListingType[] = [];

    filteredPatterns.forEach((pattern) => {
      cartListings.push(
        ...pattern.listingDetails
          .filter((listing) => (
            listing.claimBySessions
            .some(claim => claim.claimStatus === ClaimStatus.ClaimedByYou && claim.quantityClaimed > 0 && !claim.isSold)
          ))
          .map<CartListingType>((listing) => ({
            ...listing,
            listingPatternId: pattern.listingPatternId,
            styleDisplayName: pattern.styleDisplayName,
            patternTagDetails: pattern.tagDetails,
            images: pattern.imageDetails.map((image) => (
              {
                id: image.imageMapId,
                isPrimaryImage: image.isPrimary,
                viewingOrder: image.viewingOrder,
                fullImageUrl: image.imageUrl,
                standardImageUrl: image.imageUrl,
                thumbnailImageUrl: image.imageUrl,
              }
            )) || []
          }))
      )
    });

    return cartListings;
  }
);

export const cartExpiration = createSelector(
  [getClaimedListings],
  (listings) => {
    let expirations: (Date | null)[] = [];
    listings.forEach((listing) =>
      expirations.push(...listing.claimBySessions
        .filter(claim => claim.claimStatus === ClaimStatus.ClaimedByYou)
        .map(claim => claim.expiresAt ? new Date(claim.expiresAt) : null)
      )
    )

    expirations = expirations.filter((exp) => !!exp).sort((a, b) => {
      if (!a && !b) return 0;
      if(!a) return 1;
      if(!b) return -1;

      return b.valueOf() - a.valueOf();
    });

    return expirations[0] || undefined;
  }
);
const sum = (prev: number, current: number) => prev + current;

export const cartCount = createSelector([
  getClaimedListings,
], (listings) => {
  let listingCount = 0;
  listings.forEach(listing =>
    listingCount += listing.claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou)?.quantityClaimed || 0
  );

  return listingCount;
});

export const cartSubtotal = createSelector([
  getClaimedListings,
], (listings) => {
  const listingTotal = listings.map((listing) => {
    let totalListingPrice = listing.salePrice * (listing.claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou)?.quantityClaimed || 0);

    return totalListingPrice;
  }).reduce(sum, 0);
  return listingTotal;
});
