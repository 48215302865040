import { TimerContext } from "./TimerContext";
import { DurationUnit } from "luxon";
import React, { Component, ContextType, FunctionComponent } from "react";

const padNumber = (num: number, width: number) => {
  const numString = num + "";
  return numString.length >= width
    ? numString
    : new Array(width - numString.length + 1).join("0") + numString;
};

interface TimerValueFullProps {
  unit: DurationUnit;
  padWidth?: number;
}

export class TimerUnitValue extends Component<TimerValueFullProps> {
  static contextType = TimerContext;
  context!: ContextType<typeof TimerContext>;

  readonly props!: Readonly<TimerValueFullProps & { children?: null }>;

  shouldComponentUpdate(nextProps: any, _nextState: any, nextContext: ContextType<typeof TimerContext>) {
    if (!this.context || !this.context.duration || !nextContext || !nextContext.duration) { return true; }
    return this.context.duration.get(this.props.unit) !== nextContext.duration.get(nextProps.unit);
  }

  render() {
    if (!this.context) {
      console.error(
        "Tried to use a timer unit value outside of a timer component!"
      );
      return null;
    }
    if (!this.context.isActive || !this.context.duration) {
      return null;
    }

    const { unit, padWidth } = this.props;

    const unitValue = Math.floor(this.context.duration.get(unit));

    return padWidth && padWidth >= 1
      ? padNumber(unitValue, padWidth)
      : unitValue;
  }
}

// These are just shortcuts for the ones we are most likely to use.
export const Seconds: FunctionComponent<{ padWidth?: number }> = ({
  padWidth
}) => <TimerUnitValue unit={"seconds"} padWidth={padWidth} />;
export const Minutes: FunctionComponent<{ padWidth?: number }> = ({
  padWidth
}) => <TimerUnitValue unit={"minutes"} padWidth={padWidth} />;
export const Hours: FunctionComponent<{ padWidth?: number }> = ({
  padWidth
}) => <TimerUnitValue unit={"hours"} padWidth={padWidth} />;
export const Days: FunctionComponent<{ padWidth?: number }> = ({
  padWidth
}) => <TimerUnitValue unit={"days"} padWidth={padWidth} />;
