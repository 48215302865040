import { connect, MapStateToProps } from "react-redux";
import React, { Component, ReactNode } from "react";
import { State } from "../../store/state";
import * as changesApi from "../../apis/changesEndpoints";
import { ListingPatternModel } from '../../apis/listingsEndpoints';
import { updateLastChangeId } from "../../actions/changes";
import { updateStore } from "../../actions/store";
import { InvalidationDelaySeconds } from "../../config";
import { ThunkDispatchProp } from "../../actions/thunkAction";
import { ProductManagerContext } from "./ProductManagerContext";

interface StateProps {
  storeId: number;
  lastChangeId: string;
  patterns: ListingPatternModel[];
}

interface OwnProps {
  children?: ReactNode;
}

class ChangeTracker extends Component<
  OwnProps & StateProps & ThunkDispatchProp
> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  pollingTimer: number | undefined;
  alreadyPolling: boolean = false;

  pollForChanges = async () => {
    if (this.alreadyPolling) return;
    this.alreadyPolling = true;
    try {
      const { lastChangeId, storeId } = this.props;

      const changes = await changesApi.getChangesSince(storeId, lastChangeId);

      if (changes.latestChangeId !== lastChangeId) {
        this.props.dispatch(updateLastChangeId(changes.latestChangeId));
        if (changes.storeChanges) {
          this.props.dispatch(updateStore());
        }
        if (changes.albumChanges.length > 0) {
          this.context.signalListingGroupChange({
            listingGroupIds: changes.albumChanges
          });
        }
        if (changes.listingChanges.length > 0) {
          const patternChanges = this.props.patterns
            .filter((pattern) => (
              pattern.listingDetails.find((listing) => changes.listingChanges.includes(listing.listingId))
            ))
            .map((pattern) => pattern.listingPatternId);

          this.context.signalListingPatternChange({
            listingPatternIds: patternChanges
          });
        }
        if (changes.listingPatternChanges?.length > 0) {
          this.context.signalListingPatternChange({
            listingPatternIds: changes.listingPatternChanges
          });
        }
        if (changes.eventChanges.length > 0) {
          this.context.signalLiveEventsChange({
            eventIds: changes.eventChanges
          });
        }
      }
    } finally {
      this.alreadyPolling = false;
    }
  };

  startPolling = async () => {
    if (this.pollingTimer) {
      this.stopPolling();
    }
    await this.pollForChanges();
    const delay = await InvalidationDelaySeconds();
    this.pollingTimer = window.setInterval(this.pollForChanges, delay * 1000);
  };

  stopPolling = () => {
    window.clearInterval(this.pollingTimer);
  };

  handleVisibilityChange = () => {
    if (document.hidden) {
      this.stopPolling();
    } else {
      this.startPolling();
    }
  };

  componentDidMount() {
    this.startPolling();
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handleVisibilityChange
    );
    this.stopPolling();
  }

  render() {
    return <></>;
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state
) => {
  return {
    lastChangeId: state.changes.lastChangeId || "",
    storeId: state.store.id,
    patterns: state.patterns.items,
  };
};

export default connect(mapStateToProps)(ChangeTracker);
