export interface PromoCodeCheckRequest {
  promoCode: string;
  customerEmail: string;
  subtotal: number;
  isShipping: boolean;
  utcOffset: number;
  productIds?: number[]; 
}

export enum PromoCodeFailureReasons {
  NotFound = "NotFound",
  Revoked = "Revoked",
  NotYetValid = "NotYetValid",
  NoLongerValid = "NoLongerValid",
  MinTotalNotMet = "MinTotalNotMet",
  UsesExhausted = "UsesExhausted",
  ShippingNotApplicable = "ShippingNotApplicable",
  ItemsNotApplicable = "ItemsNotApplicable"
}

export enum PromoCodeDiscountTypes {
  Percent = "Percentage",
  Fixed = "FixedAmount",
  None = "None"
}

export interface PromoCodeDetails {
  code: string;
  name: string;
  discountType: string;
  discountAmount?: number;
  freeShipping: boolean;
  minOrderAmount?: number;
  usesPerCustomer?: number;
  customerLimit?: number;
  startDate?: string;
  endDate?: string;
}

interface PromoCodeCheckSuccessResponse {
  isValid: true;
  promoDetails: PromoCodeDetails;
}

interface PromoCodeCheckFailedResponse {
  isValid: false;
  failureCodes: PromoCodeFailureReasons[];
  promoDetails?: PromoCodeDetails;
}

export type PromoCodeCheckResponse = PromoCodeCheckFailedResponse | PromoCodeCheckSuccessResponse;
