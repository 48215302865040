import { Reducer } from "redux";
import { StoreState, initialStoreState } from "../store/state/store";
import { StoreActions } from "../actions/store";
import deepEqual from "deep-equal";

export const storeReducer: Reducer<StoreState, StoreActions> = (
  state = initialStoreState,
  action
): StoreState => {
  switch (action.type) {
    case "@@store/STORE_LOAD":
      return action.payload;
    case "@@store/STORE_UPDATE":
      return deepEqual(state, action.payload) ? state : action.payload;
    default:
      return state;
  }
};
