export interface AppState {
    loading: boolean;
    failed: boolean;
    notFound: boolean;
    failureReason?: string;
}

export const initialAppState : AppState = {
    loading: true,
    failed: false,
    notFound: false
}
