import React, { FunctionComponent } from "react";
import { AlbumListItem } from "./AlbumListItem/AlbumListItem";
import { AlbumListPlaceholder } from "./AlbumListItem/AlbumListPlaceholder";
import { AlbumState } from "../../store/state/albums";

interface Props {
  displayLimit?: number;
  padPlaceholders: number;
  albums: (AlbumState & { link: string;})[];
}
export const AlbumList: FunctionComponent<Props> = ({
  displayLimit,
  padPlaceholders,
  albums
}) => {
  let filteredAlbums = albums.filter(album => !album.isVip);

  let limitedAlbums = displayLimit ? filteredAlbums.slice(0, displayLimit) : filteredAlbums;
  let placeholderCount = 0;

  if (padPlaceholders > filteredAlbums.length) {
    placeholderCount = padPlaceholders - limitedAlbums.length;
  }

  return (
    <>
      {limitedAlbums.map(album => {
        const image = album.images.find(image => image.isPrimaryImage) || album.images[0];
        return (
          <AlbumListItem
            key={album.id}
            name={album.title}
            imageSrc={image ? image.thumbnailImageUrl || image.standardImageUrl: undefined}
            link={album.link}
          />
        );
      })}
      {[...Array(placeholderCount)].map((_value, index) => (
        <AlbumListPlaceholder key={index} />
      ))}
    </>
  );
};
