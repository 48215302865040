import React from "react";
import { string } from "prop-types";
import Styles from "./ImageCover.module.scss";

const ImageCover = ({ alt, className, src, title, ...props }) => {
  const wrapperClass = className ? " " + className : "";

  return (
    <div className={Styles.itemImageArea + wrapperClass}>
      <img
        src={src}
        className={Styles.itemImage}
        alt={alt}
        title={title}
        {...props}
      />
    </div>
  );
};

ImageCover.propTypes = {
  alt: string.isRequired,
  className: string,
  src: string.isRequired,
  title: string
};

ImageCover.defaultProps = {};

export default ImageCover;
