import { BannerActions } from "./../actions/banners/index";
import { StoreBannerImage } from "../store/state/banners/index";
import { Reducer } from "redux";

export const bannersReducer: Reducer<StoreBannerImage[], BannerActions> = (
  state: StoreBannerImage[] = [],
  action
): StoreBannerImage[] => {
  switch (action.type) {
    case "@@banners/LOAD_BANNERS":
      return action.payload;
    default:
      return state;
  }
};
