export interface StoreState {
  id: number;
  name: string;
  alias: string;
  ownerName?: string;
  bioImageUrl?: string;
  biography?: string;
  youtubeVideoId?: string;
  shippingPolicy?: string;
  returnsPolicy?: string;
  googleAnalyticsId?: string;
  facebookPixelId?: string;
  contactInformation?: ContactInformationState;
  socialMediaAccounts?: SocialMediaAccountsState;
  pickupDetails?: PickupDetailsState;
  storeFeatures: StoreFeaturesState;
  isActive: boolean;
  emailBanner?: "MailChimp" | "ShippingEasy";
  /* storeBanners?: StoreBannerImage[]; */
}

export interface StoreFeaturesState {
  [id: string]: {
    featureId: number;
    available: boolean;
    stale: boolean;
    displayName: string;
    description: string;
  };
}

export interface ContactAddressState {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  postalCode: string;
}

export interface ContactInformationState {
  phoneNumber?: string;
  emailAddress?: string;
  address?: ContactAddressState;
}

export interface SocialMediaAccountsState {
  facebookAccount?: string;
  twitterAccount?: string;
  pinterestAccount?: string;
  instagramAccount?: string;
}

interface PickupEnabledDetailsState {
  enabled: true;
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  notes?: string;
}

interface PickupDisabledDetailsState {
  enabled: false;
}

export type PickupDetailsState =
  | PickupEnabledDetailsState
  | PickupDisabledDetailsState;

export const initialStoreState: Readonly<StoreState> = {
  alias: "",
  id: 0,
  name: "",
  storeFeatures: {},
  isActive: false
};
