import React, { Component } from "react";
import Styles from "./BillingForm.module.scss";
import { CheckoutBillingDataState } from "../../../store/state/checkout/billing";

interface OwnProps {
  canEdit: boolean;
  billingInfo: CheckoutBillingDataState;
  error?: string;
  handleEdit: () => void;
}
type Props = OwnProps;

export class BillingFormSummary extends Component<Props> {
  render() {
    const { canEdit, handleEdit, error, billingInfo } = this.props;
    const { line1, line2, city, state, zipCode } = billingInfo;
    return (
      <>
        <div className={Styles.input_header_shipping}>Billing Information</div>
        <p>
          {`${line1}${line2 ? `, ${line2}` : ""}`}
          <br />
          {city},<br />
          {state} {zipCode}
        </p>
        {error && <div className={Styles.summaryError}>{error}</div>}
        {canEdit && (
          <button
            type="button"
            className="btn btn-link"
            onClick={handleEdit}
          >
            Edit
          </button>
        )}
      </>
    );
  }
}

export default BillingFormSummary;
