import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IfFeature } from "@retailsuccess/react-utilities/FeatureManagement";
import Styles from "./Navigation.module.scss";

interface OwnProps {
  links: {
    featureCode?: string;
    linkText: string;
    shortText?: string;
    url: string;
  }[];
}

interface ComponentState {
  showNav: boolean;
}

class Navigation extends Component<OwnProps, ComponentState> {
  state = {
    showNav: false
  };

  toggleMenu = () => {
    document.body.classList.contains("no-scroll")
      ? document.body.classList.remove("no-scroll")
      : document.body.classList.add("no-scroll");

    this.setState(prevState => ({
      showNav: !prevState.showNav
    }));
  };

  closeMenu = () => {
    document.body.classList.remove("no-scroll");
    this.setState({ showNav: false });
  };

  render() {
    const { links } = this.props;
    const { showNav } = this.state;

    return (
      <div className={showNav ? Styles.show : undefined}>
        <button
          className={`btn btn-link d-sm-none ${Styles.mobileMenuIcon}`}
          onClick={this.toggleMenu}
        >
          <FontAwesomeIcon icon="bars" />
        </button>
        <div className={Styles.clickContainer} onClick={this.closeMenu} />
        <div className={Styles.menu}>
          <div className="text-right d-sm-none">
            <button
              className={`btn btn-link ${Styles.closeMenu}`}
              onClick={this.closeMenu}
            >
              <FontAwesomeIcon icon="times" />
            </button>
          </div>
          {links.map(link => {
            const navLink = (
              <NavLink
                key={link.linkText}
                to={link.url}
                className={Styles.menuLink}
                onClick={this.closeMenu}
                exact
              >
                <span className={Styles.fullMenuLinkText}>{link.linkText}</span>
                <span className={Styles.shortMenuLinkText}>
                  {link.shortText || link.linkText}
                </span>
              </NavLink>
            );
            if (link.featureCode) {
              return (
                <IfFeature key={link.linkText} code={link.featureCode}>
                  {navLink}
                </IfFeature>
              );
            } else {
              return navLink;
            }
          })}
        </div>
      </div>
    );
  }
}

export default Navigation;
