import axios from "axios";
import { storesApiBase } from "../config";
import { StoreBannerImage } from "../store/state/banners";

const getStoreBannersEndpoint = (storeId: number) =>
  `${storesApiBase}/${storeId}/banners`;

export interface BannerResponse {
  banners: StoreBannerImage[];
}

export function getBannersByStoreId(id: number): Promise<StoreBannerImage[]> {
  return axios
    .get<StoreBannerImage[]>(getStoreBannersEndpoint(id))
    .then<StoreBannerImage[]>((value) => {
      return value.data;
    });
}
