import React, { Component } from "react";
import { connect, MapStateToProps } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import classNames from "classnames";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { State } from "../../store/state";
import ImageCover from "../../components/ImageCover";
import Styles from "./LiveAlbumsPage.module.scss";
import { PageUrls } from "../../config";
import {
  visibleLiveEvents,
  upcomingLiveEvents,
  activeLiveEvent,
  LiveEvent,
  formatDate,
  formatTime
} from "../../utilities/liveEvents";

interface ComponentProps {
  storeName: string;
  visibleEventCount: number;
  currentEvent?: LiveEvent;
  upcomingEvents: LiveEvent[];
}

type Props = ComponentProps;

class LiveAlbumsPage extends Component<Props> {

  formatAMPM(date: any) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }

  isoStringToTimestamp(isoString: string) {
    const date = new Date(isoString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    const todaysDate = new Date();

    const ml = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];

    if (date.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return "Today";
    }

    return dt + " " + ml[month] + " " + year;
  }

  render() {
    const
    {
      storeName,
      currentEvent,
      upcomingEvents,
      visibleEventCount
    } = this.props;

    const liveEvent = currentEvent || upcomingEvents[0];

    if (visibleEventCount === 0) {
      return <Redirect to={PageUrls.StoreHomepage(storeName)} />;
    }

    return (
      <>
        <div className="row">
          <div className="col-12">
            <h4 className={Styles.bless_h3}>Live Sales</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 col-xl-5 mt-md-4">
            {liveEvent ? (
              <div
                className={classNames(Styles.live_album, {
                  [Styles.live_album_active]: liveEvent.isActive
                })}
              >
                {liveEvent.isActive && (
                  <div className={Styles.badge_wrapper}>
                    <span className={`${Styles.badge} ${Styles.badge_live}`}>
                      Live
                    </span>
                    {/* <span className={Styles.badge}>
                      <FontAwesomeIcon icon="eye" /> {liveEvent.viewers}
                    </span> */}
                  </div>
                )}
                {liveEvent.standardImageUrl && <ImageCover
                  src={liveEvent.standardImageUrl}
                  alt={liveEvent.description}
                  className=""
                  title={liveEvent.description}
                />}
                <div className={Styles.live_album_info}>
                  <div className={Styles.live_album_details}>
                    <div className={Styles.live_album_header}>
                      {liveEvent.description}
                    </div>
                    {!liveEvent.isActive && <div className={Styles.live_album_datetime}>
                      {formatDate(liveEvent.startDateTime)}{" "}
                      <span className={Styles.live_album_time_component}>
                        {formatTime(liveEvent.startDateTime)}
                        {liveEvent.endDateTime && ` - ${formatTime(liveEvent.endDateTime)}`}
                      </span>
                    </div>}
                  </div>
                  <div>
                    {liveEvent.isActive ? (
                      <Link
                        to={liveEvent.eventUrl}
                        className={`btn btn-block btn-link ${
                          Styles.live_album_button_active
                        }`}
                      >
                        Join Live Sale
                      </Link>
                    ) : (
                      <Link
                        to="/"
                        className={`btn btn-block disabled ${
                          Styles.live_album_button
                        }`}
                      >
                        Next Live Sale
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <h5>No upcoming live event scheduled</h5>
            )}
          </div>
          <div className="col-12 col-md-6 col-lg-8 col-xl-7">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h6 className={Styles.live_column_header}>
                  Upcoming Live Sales
                </h6>
                {upcomingEvents.length > 0 ? upcomingEvents.map(upcomingLiveEvent => {
                  const imageUrl = upcomingLiveEvent.thumbnailImageUrl || upcomingLiveEvent.standardImageUrl;
                  return <div
                    key={upcomingLiveEvent.id}
                    className={`${Styles.upcoming_live} clearfix mb-4`}
                  >
                    <div className={Styles.upcoming_live_img}>
                      {imageUrl && <ImageCover
                        src={imageUrl}
                        alt={upcomingLiveEvent.description}
                        className=""
                        title={upcomingLiveEvent.description}
                      />}
                    </div>
                    <div className={Styles.upcoming_live_info}>
                      <div className={Styles.upcoming_live_header}>
                        {upcomingLiveEvent.description}
                      </div>
                      <div>
                        {formatDate(upcomingLiveEvent.startDateTime)}
                        <br />
                        {formatTime(upcomingLiveEvent.startDateTime)}
                        {upcomingLiveEvent.endDateTime && ` - ${formatTime(upcomingLiveEvent.endDateTime)}`}
                      </div>
                      <Link
                        to="/"
                        className={`btn btn-block disabled ${
                          Styles.upcoming_live_button_disabled
                        }`}
                      >
                        Coming Soon
                      </Link>
                    </div>
                  </div>;
                }) : <p>No upcoming live sales. Check back soon!</p>}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<Props, void, State> = state => {
  const { store } = state;

  return {
    storeName: store.alias || "",
    visibleEventCount: visibleLiveEvents(state).length,
    currentEvent: activeLiveEvent(state),
    upcomingEvents: upcomingLiveEvents(state),
  };
};

export default connect(mapStateToProps)(LiveAlbumsPage);
