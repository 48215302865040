import { DiscountEstimateRequest } from "./../store/state/checkout/estimate";
import { createSelector } from "reselect";
import { getClaimedListings } from "./cart";
import { State } from "../store/state";
import {
  ShippingRequest,
  EstimateRequest,
  OrderRequest
} from "../apis/checkoutEndpoints";
import { ClaimStatus, DeliveryMethod } from "../enums";

const getCustomer = (state: State) =>
  state.checkout.customerInfo.data || {
    email: "",
    firstName: "",
    lastName: "",
    promoCode: ""
  };

const getDelivery = (state: State) => state.checkout.deliveryInfo.data;

const getBilling = (state: State) =>
  state.checkout.billingInfo.data || {
    line1: "",
    line2: "",
    city: "",
    state: "",
    zipCode: "",
    paymentToken: ""
  };

const getUtcOffset = () => -new Date().getTimezoneOffset();

export const buildShippingEstimationRequest = createSelector(
  [getClaimedListings, getCustomer],
  (listings, customer): ShippingRequest => {
    const listingIds: number[] = [];

    listings.forEach((listing) => {
      const claimedQuantity = listing.claimBySessions?.find(
        (claim) => claim.claimStatus === ClaimStatus.ClaimedByYou
      )?.quantityClaimed || 0;

      for (let i = 0; i < claimedQuantity; i++) {
        listingIds.push(listing.listingId)
      }
    });

    return {
      listingIds,
      promoCode: customer.promoCode
    }
  }
);

export const buildPickupDetails = (state: State) => {
  if (
    !state.store ||
    !state.store.pickupDetails ||
    !state.store.pickupDetails.enabled
  ) {
    return {
      enabled: false
    };
  }
  return {
    enabled: true,
    addressLine1: state.store.pickupDetails.addressLine1,
    addressLine2: state.store.pickupDetails.addressLine2,
    city: state.store.pickupDetails.city,
    state: state.store.pickupDetails.state,
    zip: state.store.pickupDetails.zip,
    notes: state.store.pickupDetails.notes
  };
};

export const buildDiscountEstimationRequest = createSelector(
  [getClaimedListings, getCustomer],
  (listings, customer): DiscountEstimateRequest => {
    const listingIds: number[] = [];

    listings.forEach((listing) => {
      const claimedQuantity = listing.claimBySessions?.find(
        (claim) => claim.claimStatus === ClaimStatus.ClaimedByYou
      )?.quantityClaimed || 0;

      for (let i = 0; i < claimedQuantity; i++) {
        listingIds.push(listing.listingId)
      }
    });
    return {
      listingIds,
      promoCode: customer.promoCode
    };
  }
);

export const buildOrderEstimationRequest = createSelector(
  [
    getClaimedListings,
    getDelivery,
    getUtcOffset,
    getCustomer,
    getBilling,
  ],
  (listings, delivery, offset, customer, billing): EstimateRequest | null => {
    const itemCount = listings.length;
    if (itemCount === 0 || !delivery) {
      return null;
    }

    const listingIds: number[] = [];

    listings.forEach((listing) => {
      const claimedQuantity = listing.claimBySessions?.find(
        (claim) => claim.claimStatus === ClaimStatus.ClaimedByYou
      )?.quantityClaimed || 0;

      for (let i = 0; i < claimedQuantity; i++) {
        listingIds.push(listing.listingId)
      }
    });
    if (delivery.method === DeliveryMethod.Pickup) {
      return {
        deliveryMethod: DeliveryMethod.Pickup,
        listingIds,
        promoCode: customer.promoCode,
        billing: billing,
        customer: customer,
        utcOffset: offset,
        challengeToken: 'test'
      };
    } else {
      return {
        deliveryMethod: DeliveryMethod.Shipping,
        delivery,
        listingIds,
        promoCode: customer.promoCode,
        utcOffset: offset,
        billing: billing,
        customer: customer,
        challengeToken: 'test'
      };
    }
  }
);

export const buildOrderCreationRequest = createSelector(
  [
    getClaimedListings,
    getCustomer,
    getBilling,
    getDelivery,
    getUtcOffset,
  ],
  (listings, customer, billing, delivery, utcOffset): OrderRequest | null => {
    const itemCount = listings.length;
    if (
      itemCount === 0 ||
      !delivery ||
      !billing ||
      !customer ||
      !delivery.method
    ) {
      return null;
    }

    const listingIds: number[] = [];

    listings.forEach((listing) => {
      const claimedQuantity = listing.claimBySessions?.find(
        (claim) => claim.claimStatus === ClaimStatus.ClaimedByYou
      )?.quantityClaimed || 0;

      for (let i = 0; i < claimedQuantity; i++) {
        listingIds.push(listing.listingId)
      }
    });
    if (delivery.method === DeliveryMethod.Pickup) {
      return {
        deliveryMethod: DeliveryMethod.Pickup,
        billing,
        customer,
        delivery,
        promoCode: customer.promoCode,
        listingIds,
        utcOffset
      };
    } else {
      return {
        deliveryMethod: DeliveryMethod.Shipping,
        billing,
        customer,
        delivery,
        promoCode: customer.promoCode,
        listingIds,
        utcOffset
      };
    }
  }
);

export const getCreatedOrder = (state: State) => {
  const orderState = state.checkout.order;
  if (orderState.inProcess) {
    return null;
  }
  if (!orderState.response) {
    return null;
  }
  if (!orderState.response.isSuccess) {
    return null;
  }
  return orderState.response.createdOrder;
};
