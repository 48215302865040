import { parse } from "query-string";

export const getCartTokenFromQuery = (queryString: string) => {
    const search = parse(queryString);

    let queryParam = search.carttoken;

    if (queryParam) {
        if (typeof queryParam === "string") {
            return queryParam;
        } else if (Array.isArray(queryParam)) {
            return queryParam[0];
    }
    return undefined;
  }
};
