import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Styles from "./ChatInput.module.scss";
import EmojiPicker from "../EmojiPicker";

export default function ChatInput(props) {
  const inputInError = props.chatInputValue.length > props.chatInputValueMax;

  return (
    <div className={Styles.live_chat_input_wrapper}>
      {props.jumpToRecentChat && !props.displayItems && (
        <button
          onClick={() => props.jumpToBottomOfChat(true)}
          className={`btn ${Styles.live_chat_jump_to_recent}`}
        >
          Jump to recent messages <FontAwesomeIcon icon="chevron-down" />
        </button>
      )}
      <div className="d-table-row">
        <div className="d-table-cell align-middle position-relative">
          <input
            type="text"
            className={classNames(Styles.live_chat_input, "form-control", {
              [Styles.live_chat_input_error]: inputInError
            })}
            maxLength={2000}
            onChange={props.onChange}
            onKeyPress={props.handleKeyPress}
            placeholder="Write a comment..."
            value={props.chatInputValue}
            disabled={!props.streaming}
          />
          {props.chatInputValue && (
            <div className={Styles.live_chat_input_send_wrapper}>
              <button
                onClick={() => !inputInError && props.handleButtonPress()}
                className={`btn ${Styles.live_chat_input_send}`}
                disabled={!props.streaming || inputInError}
              >
                <FontAwesomeIcon icon="paper-plane" />
              </button>
            </div>
          )}
        </div>
        <div className={Styles.emoji_buttons}>
          <button
            onClick={() => props.toggleEmojiPicker()}
            className={`btn ${Styles.live_chat_input_grin}`}
            disabled={!props.streaming}
          >
            <FontAwesomeIcon icon="grin" />
          </button>
          {props.displayEmojiPicker && (
            <EmojiPicker
              clickOutside={props.toggleEmojiPicker}
              addEmoji={props.addEmoji}
              eventTypes={["click", "touchend"]}
            />
          )}
        </div>
        <div className={Styles.emoji_buttons}>
          <button
            onClick={() => props.handleEmoji("👍", true)}
            className={`btn ${Styles.live_chat_input_like}`}
            disabled={!props.streaming}
          >
            <FontAwesomeIcon icon="thumbs-up" />
          </button>
        </div>
      </div>
      {props.chatInputValue.length > 117 && (
        <div className="d-table-row">
          <div className="d-table-cell text-right">
            <small>
              <span
                className={classNames({
                  [Styles.live_chat_input_error_text]: inputInError
                })}
              >
                {props.chatInputValue.length}
              </span>
              /{props.chatInputValueMax}
            </small>
          </div>
        </div>
      )}
    </div>
  );
}
