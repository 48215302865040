import { RequestStateBase } from "../requestStateBase";

export interface ShippingAmountState
  extends RequestStateBase<
    ShippingAmountRequestState,
    ShippingAmountResponseState | FailedShippingAmountResponseState
  > {}

export interface ShippingAmountRequestState {
  listingIds: number[];
  promoCode: string;
}

export interface ShippingAmountResponseState {
  isSuccess: true;
  shippingAmount: number;
}

export interface FailedShippingAmountResponseState {
  isSuccess: false;
  failureReason: string;
}

export function initialShippingState(): ShippingAmountState {
  return {
    inProcess: false,
    request: undefined,
    response: undefined
  };
}
