import { Action } from "redux";
import { AppState } from "../../store/state/app";
import { ActionCreator } from "redux";

export interface UpdateAppStateAction extends Action {
  type: "@@app/UPDATE_STATE";
  payload: AppState;
}

export type AppActions = UpdateAppStateAction;

export const loadAppFailed: ActionCreator<UpdateAppStateAction> = (
  reason: string
) => ({
  type: "@@app/UPDATE_STATE",
  payload: {
    loading: false,
    failed: true,
    failureReason: reason,
    notFound: false
  }
});

export const loadAppNotFound: ActionCreator<UpdateAppStateAction> = () => ({
  type: "@@app/UPDATE_STATE",
  payload: {
    loading: false,
    failed: false,
    notFound: true
  }
});

export const loadAppSuccess: ActionCreator<UpdateAppStateAction> = () => ({
  type: "@@app/UPDATE_STATE",
  payload: {
    loading: false,
    failed: false,
    notFound: false
  }
});
