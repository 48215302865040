import React, { Component } from "react";
import { CheckoutCustomerDataState } from "../../../store/state/checkout/customer";
import Styles from "./CustomerForm.module.scss";

interface OwnProps {
  canEdit: boolean;
  customerInfo: CheckoutCustomerDataState;
  error?: string;
  handleEdit: () => void;
}
export class CustomerFormSummary extends Component<OwnProps> {
  render() {
    const {
      canEdit,
      customerInfo: {
        email,
        firstName,
        lastName,
        promoCode
      },
      error,
      handleEdit
    } = this.props;

    return <>
      <div className={Styles.input_header_shipping}>Customer Information</div>
      <p>
        {firstName} {lastName}<br />
        {email}<br />
        {promoCode ? 'Promo Code' : null} <span className={Styles.uppercase}>{promoCode}</span>
      </p>
      {error && <div className={Styles.summaryError}>{error}</div>}
      {canEdit && <button type="button" className="btn btn-link" onClick={handleEdit}>Edit</button>}
    </>;
  }
}

export default CustomerFormSummary;
