import React, { Component } from "react";
import { Switch, RouteComponentProps } from "react-router";
import { Route } from "react-router-dom";
import { connect, MapStateToProps } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleRight,
  faShoppingBag,
  faImages,
  faSearch,
  faBars,
  faEye,
  faTimes,
  faHeart,
  faThumbsUp,
  faGrin,
  faPaperPlane,
  faTshirt,
  faChevronDown,
  faChevronUp,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { allAlbumsSelector } from "./utilities/albums";
import Header from "./components/Header/Header";
import Footer from "./components/Footer";
import { NotFound } from "./NotFound";
import HomePage from "./pages/HomePage/HomePage";
import AlbumDetailPage from "./pages/AlbumDetailPage/AlbumDetailPage";
import AlbumListPage from "./pages/AlbumListPage/AlbumListPage";
import AboutPage from "./pages/AboutPage/AboutPage";
import LiveAlbumsPage from "./pages/LiveAlbumsPage/LiveAlbumsPage";
import LiveSalePage from "./pages/LiveSalePage/LiveSalePage";
import ProductDetailPage from "./pages/ProductDetailPage/ProductDetailPage";
import PatternDetailPage from "./pages/PatternDetailPage/PatternDetailPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import { NewItemsPage } from "./pages/NewItemsPage/NewItemsPage";
import LoadingSpinner from "./components/LoadingSpinner/LoadingSpinner";
import Styles from "./App.module.scss";
import OrderCompletePage from "./pages/OrderCompletePage/OrderCompletePage";
import { ToastContainer } from "react-toastify";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { ExternalUrls } from "./config";
import RetailerBio from "./components/RetailerBio/RetailerBio";
import { State } from "./store/state";
import { loadInitialState } from "./actions";
import ProductManager from "./components/ProductManager/ProductManager";
import { EmailSignup } from "./components/EmailSignup";
import AgreementBanner from "./components/AgreementBanner";
import {
  EmailSignupRequest,
  sendEmailSignup
} from "./apis/emailSignupEndpoints";
import { ThunkDispatchProp } from "./actions/thunkAction";
import { StoreState } from "./store/state/store";
import queryString from "query-string";
import { ReviewPage } from "./pages/ReviewPage/ReviewPage";
import { buildBioInfo, BioInfo } from "./utilities/bio";
import {
  SetUpAnalyticsProperty,
  TearDownAnalyticsProperty,
  SetUpPixelProperty,
  TearDownPixelProperty,
  GoogleAnalyticsTrackerKey
} from "./analytics";
import { selectLiveEvents } from "./utilities/liveEvents";
import { FeatureManager } from "@retailsuccess/react-utilities/FeatureManagement";

// font awesome icon(s)
library.add(
  faAngleRight,
  faShoppingBag,
  faImages,
  faSearch,
  faBars,
  faEye,
  faTimes,
  faHeart,
  faThumbsUp,
  faGrin,
  faPaperPlane,
  faTshirt,
  faChevronDown,
  faChevronUp,
  faCalendar,
  faArrowRight
);

interface OwnProps {
  storeAlias: string;
  cartToken?: string;
  previousSessionId?: string;
}

interface StateProps {
  isReady: boolean;
  isError: boolean;
  notFound: boolean;
  storeId?: number;
  redirectToRegistration?: boolean;
  showBioOnly?: boolean;
  googleAnalyticsId?: string;
  facebookPixelId?: string;
  errorMessage?: string;
  store?: StoreState;
  bio?: BioInfo;
  emailBanner?: "MailChimp" | "ShippingEasy";
}

interface RouteParameters {
  storeName: string;
}

interface AppState {
  showCart: boolean;
}

type Props = RouteComponentProps<RouteParameters> &
  OwnProps &
  StateProps &
  ThunkDispatchProp;

class App extends Component<Props, AppState> {
  state = {
    showCart: false
  };

  async componentDidMount() {
    const cartResponse = await this.props.dispatch(
      loadInitialState(
        this.props.storeAlias,
        this.props.cartToken,
        this.props.previousSessionId
      )
    );
    if (cartResponse) {
      if (cartResponse.isTokenValid) {
        this.setState({
          showCart: true
        });
      } else {
        this.toastError("Cart token is invalid or expired.");
      }

      if (cartResponse.successfulClaims?.length > 0) {
        this.toastMessage(
          `${cartResponse.successfulClaims.length} items added to cart.`
        );
      }

      if (cartResponse.unsuccessfulClaims?.length > 0) {
        this.toastError(
          `Error adding ${cartResponse.unsuccessfulClaims.length} items to cart.`
        );
      }
    }
    if (this.props.googleAnalyticsId) {
      SetUpAnalyticsProperty(
        GoogleAnalyticsTrackerKey.Retailer,
        this.props.googleAnalyticsId
      );
    }
    if (this.props.facebookPixelId) {
      SetUpPixelProperty(this.props.facebookPixelId);
    }
  }

  componentWillUnmount() {
    if (this.props.googleAnalyticsId) {
      TearDownAnalyticsProperty(GoogleAnalyticsTrackerKey.Retailer);
    }

    if (this.props.facebookPixelId) {
      TearDownPixelProperty(this.props.facebookPixelId);
    }
  }

  toastMessage = (message: string) => {
    toast.success(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 5000,
      className: Styles.toast
    });
  };

  toastError = (errorMessage: string) => {
    toast.error(errorMessage, {
      position: toast.POSITION.BOTTOM_RIGHT,
      autoClose: 10000
    });
  };

  handleClick = () => {
    this.setState((prevState) => ({
      ...prevState,
      showCart: !prevState.showCart
    }));
  };

  handleClickOutside = () => {
    if (this.state.showCart) {
      this.setState((prevState) => ({
        ...prevState,
        showCart: false
      }));
    }
  };

  handleEmailSignup = async (values: EmailSignupRequest) => {
    if (this.props.storeId) {
      const result = await sendEmailSignup(this.props.storeId, values);
      if (!result.success) {
        toast.error(result.errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 10000
        });
      }
      return result.success;
    }
    return false;
  };

  ccpaBanner = () => (
    <AgreementBanner location="bottom" cookieName="privacy_policy_agreement">
      To comply with the California Consumer Privacy Act, we're informing you of
      our use of cookies. By clicking <em>Agree</em>, you agree to the use of
      these cookies. View our{" "}
      <a
        href="https://userguide.lularoebless.com/privacyCustomer.html"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      .
    </AgreementBanner>
  );

  render() {
    if (!this.props.isReady) {
      return <LoadingSpinner centered={true} />;
    }
    if (this.props.notFound) {
      return <NotFound />;
    }
    if (this.props.isError) {
      throw new Error(this.props.errorMessage);
    }

    if (this.props.redirectToRegistration) {
      ExternalUrls.CustomerRegistration(this.props.storeAlias).then((url) => {
        window.location.href = url;
      });
      return null;
    }
    const { location, store, emailBanner, showBioOnly, bio } = this.props;

    if (showBioOnly) {
      return bio ? (
        <FeatureManager initialFeatures={store && store.storeFeatures}>
          <div>
            {emailBanner && (
              <EmailSignup
                onSubmit={this.handleEmailSignup}
                formType={emailBanner}
              />
            )}
            <RetailerBio {...bio} />
            <Footer hideContact={true} />
            <ProductManager
              notify={this.toastMessage}
              cartClick={this.handleClick}
            />
          </div>
          {this.ccpaBanner()}
        </FeatureManager>
      ) : null;
    }

    const { url } = this.props.match;
    return (
      <>
        <FeatureManager initialFeatures={store && store.storeFeatures}>
          {emailBanner && (
            <EmailSignup
              onSubmit={this.handleEmailSignup}
              formType={emailBanner}
            />
          )}
          <div className="container">
            <ProductManager
              notify={this.toastMessage}
              cartClick={this.handleClick}
            >
              <Header
                cartClick={this.handleClick}
                clickOutside={this.handleClickOutside}
                showCart={this.state.showCart}
              />
              <Switch location={location}>
                <Route exact path={`${url}/new`} component={NewItemsPage} />
                <Route
                  exact
                  path={`${url}/albums/:id`}
                  render={({ match }) => (
                    <AlbumDetailPage albumId={parseInt(match.params.id)} />
                  )}
                />
                <Route
                  exact
                  path={`${url}/type/:id`}
                  render={({ match }) => (
                      <AlbumDetailPage
                        showTypeFilter={true}
                        typeId={parseInt(match.params.id)}
                        typeListingGroup={true}
                        query={`?type=${match.params.id}`}
                      />
                    )}
                />
                <Route
                  path={`${url}/shop`}
                  render={({ location }) => {
                    return (
                      <AlbumDetailPage
                        showTypeFilter={true}
                        typeListingGroup={true}
                        query={"?size=&type=&style"}
                      />
                    );
                  }}
                />
                <Route exact path={`${url}/about`} component={AboutPage} />
                <Route exact path={`${url}/albums`} component={AlbumListPage} />
                <Route exact path={`${url}/live`} component={LiveAlbumsPage} />
                <Route
                  exact
                  path={`${url}/live/:eventId`}
                  render={({ match }) => (
                    <LiveSalePage eventId={parseInt(match.params.eventId)} />
                  )}
                />
                <Route
                  exact
                  path={`${url}/item/:id`}
                  render={({ match, location }) => {
                    const search = queryString.parse(location.search);
                    return (
                      <ProductDetailPage
                        listingId={parseInt(match.params.id)}
                        albumId={
                          typeof search.album === "string"
                            ? parseInt(search.album)
                            : undefined
                        }
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`${url}/pattern/:id`}
                  render={({ match, location }) => {
                    const search = queryString.parse(location.search);
                    return (
                      <PatternDetailPage
                        patternId={parseInt(match.params.id)}
                        albumId={
                          typeof search.album === "string"
                            ? parseInt(search.album)
                            : undefined
                        }
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path={`${url}/feedback/:identifierId`}
                  render={({ match }) => (
                    <ReviewPage
                      identifierId={match.params.identifierId}
                      storeName={this.props.bio?.name}
                      initialRating={3}
                      storeId={this.props.storeId}
                      commentsLength={2000}
                    />
                  )}
                />
                <Route
                  exact
                  path={`${url}/checkout`}
                  component={CheckoutPage}
                />
                <Route
                  exact
                  path={`${url}/ordercomplete`}
                  component={OrderCompletePage}
                />
                <Route exact path={`${url}/`} component={HomePage} />
                <Route component={NotFound} />
              </Switch>
              <ToastContainer hideProgressBar={true} />
              {store ? (
                <Footer
                  hideContact={false}
                  {...store.contactInformation}
                  {...store.socialMediaAccounts}
                />
              ) : (
                <Footer hideContact={true} />
              )}
            </ProductManager>
          </div>
        </FeatureManager>
        {this.ccpaBanner()}
      </>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state
) => {
  if (state.app.loading) {
    const loadingProps: StateProps = {
      isReady: false,
      isError: false,
      notFound: false
    };
    return loadingProps;
  }
  if (state.app.notFound) {
    const notFoundProps: StateProps = {
      isReady: true,
      notFound: true,
      isError: false
    };
    return notFoundProps;
  }
  if (state.app.failed) {
    const failedProps: StateProps = {
      isReady: true,
      isError: true,
      notFound: false,
      errorMessage: state.app.failureReason || ""
    };
    return failedProps;
  }

  const hasSomethingToShow =
    allAlbumsSelector(state).length > 0 || selectLiveEvents(state).length > 0;

  const loadedProps: StateProps = {
    isReady: true,
    notFound: false,
    isError: false,
    storeId: state.store.id,
    googleAnalyticsId: state.store.googleAnalyticsId,
    facebookPixelId: state.store.facebookPixelId,
    redirectToRegistration: !state.store.ownerName,
    store: state.store,
    showBioOnly: !state.store.isActive || !hasSomethingToShow,
    bio: {
      ...buildBioInfo(state),
      type: "soloBio"
    },

    emailBanner: state.store.emailBanner
  };
  return loadedProps;
};

export default withRouter(connect(mapStateToProps)(App));
