import React from "react";
import { node, string } from "prop-types";
import classNames from "classnames";

import Styles from "./ChatHeader.module.scss";

const ChatHeader = ({ children, className, ...props }) => (
  <div className={classNames(Styles.wrapper, "clearfix", className)} {...props}>
    {children}
  </div>
);

ChatHeader.propTypes = {
  children: node,
  className: string
};

export default ChatHeader;
