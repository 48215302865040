import { Action } from "redux";
import {
  ShippingAmountRequestState,
  ShippingAmountResponseState,
  FailedShippingAmountResponseState
} from "../../store/state/checkout/shipping";
import { ThunkResult } from "../thunkAction";
import { buildShippingEstimationRequest } from "../../utilities/checkout";
import { getShippingAmount } from "../../apis/checkoutEndpoints";
import deepEqual from "deep-equal";

export interface ShippingAmountRequestAction extends Action {
  type: "@@checkout/shipping/SHIPPING_REQUEST";
  payload: ShippingAmountRequestState;
}

export interface ShippingAmountResponseFailedAction extends Action {
  type: "@@checkout/shipping/SHIPPING_RESPONSE_FAILURE";
  payload: FailedShippingAmountResponseState;
}

export interface ShippingAmountResponseSucceededAction extends Action {
  type: "@@checkout/shipping/SHIPPING_RESPONSE_SUCCESS";
  payload: ShippingAmountResponseState;
}

export type ShippingActions =
  | ShippingAmountRequestAction
  | ShippingAmountResponseFailedAction
  | ShippingAmountResponseSucceededAction;

export function requestShippingAmount(): ThunkResult<boolean, ShippingActions> {
  return async (dispatch, getState) => {
    const state = getState();
    const request = buildShippingEstimationRequest(getState());
    const { shipping } = state.checkout;
    if (deepEqual(shipping.request, request) && shipping.response) {
      // We already have a result for the same request.
      // Just return true since the existing response is still valid.
      return true;
    }
    dispatch({
      type: "@@checkout/shipping/SHIPPING_REQUEST",
      payload: request
    });
    try {
      const response = await getShippingAmount(state.store.id, request);

      if (response.isSuccess) {
        dispatch({
          type: "@@checkout/shipping/SHIPPING_RESPONSE_SUCCESS",
          payload: response
        });
        return true;

      } else {
        dispatch({
          type: "@@checkout/shipping/SHIPPING_RESPONSE_FAILURE",
          payload: response
        });
        console.error("Shipping estimate failure", response.failureReason);
        return false;
        
      }
    } catch (error) {
      dispatch({
        type: "@@checkout/shipping/SHIPPING_RESPONSE_FAILURE",
        payload: {
          isSuccess: false,
          failureReason:
            "An unexpected error occurred while getting the shipping estimate."
        }
      });
      console.error("Shipping estimate failure", error);
      return false;
    }
  };
}
