import React, { Component } from "react";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import classNames from "classnames";
import onClickOutside from "react-onclickoutside";
import Styles from "./EmojiPicker.module.scss";

interface EmojiPickerProps {
  className?: string;
  addEmoji: () => void;
  clickOutside: () => void;
}

class EmojiPicker extends Component<EmojiPickerProps> {
  handleClickOutside = () => {
    this.props.clickOutside();
  };

  render() {
    const { className, addEmoji } = this.props;

    return (
      <div
        className={classNames(Styles.emoji_wrapper, {
          className: className
        })}
      >
        <Picker
          native
          showPreview={false}
          perLine={6}
          exclude={["recent", "objects", "symbols", "flags"]}
          onSelect={emoji => {
            // @ts-ignore
            addEmoji(emoji.native);
          }}
        />
      </div>
    );
  }
}

export default onClickOutside(EmojiPicker);
