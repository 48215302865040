import { Reducer } from "redux";
import { AppState, initialAppState } from "../store/state/app";
import { AppActions } from "../actions/app";

export const appReducer: Reducer<AppState, AppActions> = (
  state = initialAppState,
  action
): AppState => {
  switch (action.type) {
    case "@@app/UPDATE_STATE":
      return action.payload;
    default:
      return state;
  }
};
