import React from "react";
import Styles from "./AlbumListItem.module.scss";

export const AlbumListPlaceholder = () => {
  return (
    <div className={`col-6 col-md-4 col-lg-2 ${Styles.wrapperPlaceholder}`}>
      <div className={Styles.album_card}>
        <div className={Styles.container}>
          
        </div>
        <div className={Styles.title}>Album coming soon</div>
      </div>
    </div>
  );
};
