import { Action } from "redux";

export interface UpdateLastChangeIdAction extends Action {
  type: "@@changes/UPDATE_LASTCHANGEID";
  payload: {
    lastChangeId: string;
  };
}

export type ChangesActions =
  | UpdateLastChangeIdAction;

export const updateLastChangeId = (id: string): UpdateLastChangeIdAction => ({
  type: "@@changes/UPDATE_LASTCHANGEID",
  payload: {
    lastChangeId: id
  }
});
