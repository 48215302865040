import React, { Component } from 'react';
import { Popover } from "react-bootstrap";
import onClickOutside from "react-onclickoutside";
import Styles from "./PatternListItem.module.scss";
import { Formatter } from "../../../Formatter";
import { SizeButtonGroup } from '../../SizeButtonGroup';
import { ProductManagerContext } from '../../ProductManager/ProductManagerContext';
import { ClaimContextType } from "../../../enums/ClaimContextType";
import { PatternListingDetails } from "../../../apis/listingsEndpoints";

interface Props {
  albumId?: number;
  eventId?: number;
  listName: string;
  patternStyle?: number;
  patternListings: PatternListingDetails[];
  displayPriceRange: string;
  sizeChartOpen: boolean;
  openSizeChart: (typeId: number) => void;
  closeOverlay: () => void;
}

interface State {
  selectedPrice: number;
}

class AddToBagModal extends Component<Props, State> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  constructor(props: Props) {
    super(props);

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      selectedPrice: 0
    }
  }

  handleClickOutside = () => this.props.closeOverlay();

  handleEscapeKeypress = (event: KeyboardEvent) => {
    const { sizeChartOpen, closeOverlay } = this.props;

    if (!sizeChartOpen && event.key === "Escape") {
      closeOverlay();
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleEscapeKeypress);
  }

   componentWillUnmount() {
    window.removeEventListener("keydown", this.handleEscapeKeypress);
  }

  displayPrice = (price: number) => {
    this.setState({
      selectedPrice: price
    });
  };

  hidePrice = () => {
    this.setState({
      selectedPrice: 0
    });
  };

  render() {
    const { albumId, eventId, listName, patternStyle, patternListings, displayPriceRange, openSizeChart } = this.props;
    const { selectedPrice } = this.state;

    return (
      <Popover.Content>
        <div style={{ marginBottom: ".5rem" }}>
          <span style={{ fontWeight: 600 }}>PLEASE SELECT SIZE:</span>
          {patternStyle && (
            <span
              className={Styles.sizeGuide}
              onClick={() => openSizeChart(patternStyle)}
            >
              Size Guide
            </span>
          )}
        </div>
        <div>
          <SizeButtonGroup
            patternListings={patternListings}
            onMouseEnter={this.displayPrice}
            onMouseLeave={this.hidePrice}
            onClick={(listingId) => {
              if (eventId) {
                this.context.addListingToCart(
                  listingId,
                  "LiveSale",
                  ClaimContextType.Event,
                  eventId
                );
              } else if (albumId) {
                this.context.addListingToCart(
                  listingId,
                  listName,
                  ClaimContextType.ListingGroup,
                  albumId
                );
              } else {
                this.context.addListingToCart(listingId, listName);
              }

              this.props.closeOverlay();
            }}
          />
        </div>
        <div className={Styles.itemCardText}>
          {selectedPrice === 0
            ? displayPriceRange
            : Formatter.format(selectedPrice)}
        </div>
      </Popover.Content>
    )
  }
}

export default onClickOutside(AddToBagModal);
