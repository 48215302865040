import React, { Component } from "react";
import { CheckoutDeliveryDataState } from "../../../store/state/checkout/delivery";
import { PickupDetailsState } from "../../../store/state/store";
import Styles from "./DeliveryForm.module.scss";

interface OwnProps {
  canEdit: boolean;
  pickupDetails: PickupDetailsState;
  deliveryInfo: CheckoutDeliveryDataState;
  error?: string;
  handleEdit: () => void;
}
type Props = OwnProps;

export class DeliveryFormSummary extends Component<Props> {

  shippingDisplay() {
    const {
        firstName,
        lastName,
        line1,
        line2,
        city,
        state,
        zipCode
    } = this.props.deliveryInfo;
    return <>
        <div className={Styles.input_header_shipping}>Ship to Address</div>
        <p>
        {firstName} {lastName}
        <br />
        {`${line1}${line2 ? `, ${line2}` : ""}`}<br />
        {city}<br />
        {state} {zipCode}
        </p>
    </>;
  }

  pickupDisplay() {
      if (!this.props.pickupDetails.enabled) {
          return null;
      }
      const {
          firstName,
          lastName
      } = this.props.deliveryInfo;
      const {
          addressLine1,
          addressLine2,
          city,
          state,
          zip,
          notes
      } = this.props.pickupDetails;
    return <>
        <div className={Styles.input_header_shipping}>Pick up Address</div>
        <p>
            {`${addressLine1}${addressLine2 ? `, ${addressLine2}` : ""}`}
            <br />
            {city},<br />
            {state} {zip}
        </p>
        {notes && (
            <>
            <div className={Styles.input_header_shipping}>Notes</div>
            <p>{notes}</p>
            </>
        )}
        <div className={Styles.input_header_shipping}>Who's picking up this order?</div>
        <p>
            {firstName} {lastName}
        </p>
    </>;
}

  render() {
    const {canEdit, handleEdit, error} = this.props;
    const { method } = this.props.deliveryInfo;
    return <>
        {method === "PICKUP" ? this.pickupDisplay() : this.shippingDisplay()}
        {error && <div className={Styles.summaryError}>{error}</div>}
        {canEdit && <button type="button" className="btn btn-link" onClick={handleEdit}>Edit</button>}
    </>;
  }
}

export default DeliveryFormSummary;
