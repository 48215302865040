import { TimerContext } from "./TimerContext";
import React, { Component, ContextType, ReactNode, FunctionComponent } from "react";

interface TimerSwitchFullProps {
  children?: {
    active?: ReactNode;
    inactive?: ReactNode;
  };
}
export class TimerSwitch extends Component<TimerSwitchFullProps> {
  static contextType = TimerContext;
  context!: ContextType<typeof TimerContext>;

  readonly props!: Readonly<TimerSwitchFullProps>;


  shouldComponentUpdate(nextProps: any, _nextState: any, nextContext: ContextType<typeof TimerContext>) {
    if (!this.context || !nextContext) { return true; }
    return this.context.isActive !== nextContext.isActive;
  }

  render() {
    if (!this.context) {
      console.error(
        "Tried to use a timer switch outside of a timer component!"
      );
      return null;
    }
    if (this.context.isActive) {
      if (
        typeof this.props.children === "object" &&
        "active" in this.props.children
      ) {
        return this.props.children.active;
      }
    } else {
      if (
        typeof this.props.children === "object" &&
        "inactive" in this.props.children
      ) {
        return this.props.children.inactive;
      }
    }
    return null;
  }
}

export const TimerActive: FunctionComponent<{ children?: ReactNode }> = ({
  children
}) => <TimerSwitch children={{ active: children }} />;
export const TimerInactive: FunctionComponent<{ children?: ReactNode }> = ({
  children
}) => <TimerSwitch children={{ inactive: children }} />;
