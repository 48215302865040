import React, { Component, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { connect, MapStateToProps } from "react-redux";
// import * as cartActions from "../../oldactions/cartActions";
import { CheckoutDisabled, PageUrls } from "../../config";
import Styles from "./ShoppingCart.module.scss";
import { Formatter } from "../../Formatter";
import {
  getClaimedListings,
  cartSubtotal,
  cartCount
} from "../../utilities/cart";
import { State } from "../../store/state";
import { AllActions } from "../../actions";
import { ThunkDispatch } from "redux-thunk";
import { ProductManagerContext } from "../ProductManager/ProductManagerContext";
import { TagCategoryDefs } from "../../enums/TagCategoryDefs";
import { ClaimStatus } from "../../enums";
import { CartListingType } from "../../utilities/cart";

interface StateProps {
  storeName: string;
  storeId: number;
  cartCount: number;
  cartListings: CartListingType[];
  storeCheckoutLink: string;
  subtotal: number;
}

interface OwnProps {
  handleClick: MouseEventHandler;
}

export class ShoppingCart extends Component<
  StateProps & OwnProps & { dispatch: ThunkDispatch<State, void, AllActions> }
> {
  static contextType = ProductManagerContext;
  context!: React.ContextType<typeof ProductManagerContext>;

  cartItems: (item: CartListingType) => JSX.Element[] = ({
    listingId,
    tagDetails,
    salePrice,
    images,
    claimBySessions,
    listingPatternId,
    styleDisplayName,
    hangerCode,
  }) => {
    const { storeName } = this.props;
    const primaryImage =
      images ? images.find((image) => image.isPrimaryImage) || images[0] : undefined;

    const cartDuplicateItems = [];
    const itemLink = `/${storeName}/pattern/${listingPatternId}`;
    const quantityClaimed = claimBySessions?.find(claim => claim.claimStatus === ClaimStatus.ClaimedByYou)?.quantityClaimed || 0;

    const sizeName = tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.name;

    for (let i = 0; i < quantityClaimed; i++) {
      cartDuplicateItems.push(
        <li
          style={{ listStyle: "none", marginRight: "5px" }}
          className={`${Styles.shopping_cart_item} clearfix`}
          key={`${listingId}-${i}`}
        >
          <Link to={itemLink}>
            <div className={Styles.shopping_cart_item_image_area}>
              {primaryImage && (
                <img
                  src={
                    primaryImage.thumbnailImageUrl ||
                    primaryImage.standardImageUrl
                  }
                  className={Styles.shopping_cart_item_image}
                  alt={styleDisplayName}
                />
              )}
            </div>
            <span>{styleDisplayName}</span>
          </Link>
          <span style={{ float: "right" }}>{Formatter.format(salePrice)}</span>
          {sizeName && (
            <div className={Styles.shopping_cart_size}>Size: {sizeName}</div>
          )}
          {hangerCode && (
            <div className={Styles.shopping_cart_hanger_code}>
              Hanger Code: {hangerCode}
            </div>
          )}
          <div
            className={Styles.shopping_cart_item_remove}
            onClick={() => this.context.removeListingFromCart(listingId)}
          >
            Remove from bag
          </div>
        </li>
      )
    }

    return cartDuplicateItems;
  };

  render() {
    const {
      cartCount,
      cartListings,
      storeCheckoutLink,
      subtotal,
      handleClick
    } = this.props;

    return (
      <div id="flyout" className={Styles.container}>
        <div className={Styles.shopping_cart}>
          <div className={Styles.shopping_cart_header}>
            <span className={Styles.header}>Your Shopping Bag</span>
          </div>
          {cartCount > 0 && (
            <>
              <div className={Styles.shopping_cart_list}>
                {cartListings.length > 0 && cartListings.map(this.cartItems)}
                {/* Let's just use a subtotal from the cart items for now */}
              </div>
              <div className={Styles.summaryComplete}>
                <div className={Styles.totals}>
                  Subtotal
                  <span className={Styles.totalsCurrency}>
                    {Formatter.format(subtotal)}
                  </span>
                </div>
              </div>
            </>
          )}
          {CheckoutDisabled || cartCount > 0 ? (
            <Link
              to={storeCheckoutLink}
              className={
                CheckoutDisabled
                  ? `btn d-block disabled ${Styles.button_disabled}`
                  : `btn d-block ${Styles.button}`
              }
              onClick={handleClick}
            >
              Checkout
            </Link>
          ) : (
            <>
              <div className={Styles.emptyCart}>Your bag is empty</div>
              <Link
                to={storeCheckoutLink}
                className={`btn d-block disabled ${Styles.button_disabled}`}
              >
                Checkout
              </Link>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<StateProps, OwnProps, State> = (
  state
) => {
  const { store } = state;
  let listings = getClaimedListings(state);

  return {
    storeName: store.alias || "",
    storeId: store.id || 0,
    cartListings: listings,
    cartCount: cartCount(state),
    subtotal: cartSubtotal(state),
    storeCheckoutLink: PageUrls.CheckoutPage(store.alias),
  };
};

export default connect(mapStateToProps)(ShoppingCart);
