import { DurationUnit } from "luxon";

export const UnitsAscending: Readonly<DurationUnit[]> = [
  "millisecond",
  "milliseconds",
  "second",
  "seconds",
  "minute",
  "minutes",
  "hour",
  "hours",
  "day",
  "days",
  "week",
  "weeks",
  "month",
  "months",
  "quarter",
  "quarters",
  "year",
  "years"
];

export const UnitsDescending: Readonly<DurationUnit[]> = [
  ...UnitsAscending
].reverse();
