import React, { Component } from "react";
import Styles from "./GoogleReCAPTCHATos.module.scss";

class GoogleReCAPTCHATos extends Component {
  render() {
    return (
      <p>
        <small>
          This site is protected by reCAPTCHA and the Google{" "}
          <a
            href="https://policies.google.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
            className={Styles.externalLink}
          >
            Privacy Policy
          </a>{" "}
          and{" "}
          <a
            href="https://policies.google.com/terms"
            target="_blank"
            rel="noopener noreferrer"
            className={Styles.externalLink}
          >
            Terms of Service
          </a>{" "}
          apply.
        </small>
      </p>
    );
  }
}

export default GoogleReCAPTCHATos;
