import { Action } from "redux";
import {
  CheckoutPickupOrderRequestState,
  CheckoutShippingOrderRequestState,
  SuccessfulOrderResponseState,
  FailedOrderResponseState
} from "../../store/state/checkout/order";
import { ThunkResult } from "../thunkAction";
import { placeOrder, OrderResponse } from "../../apis/checkoutEndpoints";
import { buildOrderCreationRequest } from "../../utilities/checkout";
import { markBillingFailure, MarkBillingFailureAction } from "./billing";
import { markListingSold, MarkListingQuantitySoldAction } from "../patterns";

export interface OrderRequestAction extends Action {
  type: "@@checkout/order/ORDER_REQUEST";
  payload: CheckoutPickupOrderRequestState | CheckoutShippingOrderRequestState;
}

export interface OrderResponseFailedAction extends Action {
  type: "@@checkout/order/ORDER_RESPONSE_FAILURE";
  payload: FailedOrderResponseState;
}

export interface OrderResponseSucceededAction extends Action {
  type: "@@checkout/order/ORDER_RESPONSE_SUCCESS";
  payload: SuccessfulOrderResponseState;
}

export type OrderActions =
  | OrderRequestAction
  | OrderResponseFailedAction
  | OrderResponseSucceededAction;

export function requestPlaceOrder(): ThunkResult<
  OrderResponse,
  OrderActions | MarkBillingFailureAction | MarkListingQuantitySoldAction
> {
  return async (dispatch, getState) => {
    const state = getState();
    const request = buildOrderCreationRequest(getState());
    if (!request) {
      const nonResponse: FailedOrderResponseState = {
        isSuccess: false,
        failures: {
          otherError: "Unable to build an order request from the current state!"
        }
      };
      dispatch({
        type: "@@checkout/order/ORDER_RESPONSE_FAILURE",
        payload: nonResponse
      });
      return {
        isSuccess: false,
        failures: {
          otherError: "An unexpected error occurred"
        }
      };
    }
    dispatch({
      type: "@@checkout/order/ORDER_REQUEST",
      payload: request
    });
    try {
      const response = await placeOrder(state.store.id, request);
      if (response.isSuccess) {
        dispatch({
          type: "@@checkout/order/ORDER_RESPONSE_SUCCESS",
          payload: response
        });
        if (request.listingIds && request.listingIds.length > 0) {
          dispatch(markListingSold(request.listingIds));
        }
        return response;
      } else {
        dispatch({
          type: "@@checkout/order/ORDER_RESPONSE_FAILURE",
          payload: response
        });
        dispatch(
          markBillingFailure({
            paymentToken: response.failures.cvvError,
            line1: response.failures.addressError,
            zipCode: response.failures.zipCodeError,
            other: response.failures.otherError
          })
        );
        console.error("Order creation failure", response.failures);
        return response;
      }
    } catch (error) {
      const failResponse: FailedOrderResponseState = {
        isSuccess: false,
        failures: {
          otherError: "An unexpected error occurred while processing the order."
        }
      };
      dispatch({
        type: "@@checkout/order/ORDER_RESPONSE_FAILURE",
        payload: failResponse
      });
      console.error("Order creation failure", error);
      return failResponse;
    }
  };
}
