import { createSelector } from "reselect";
import { PageUrls } from "../config";
import { State } from "../store/state";
import { ListingPatternModel, PatternListingDetails } from '../apis/listingsEndpoints';
import { Formatter } from "../Formatter";
import { TagCategoryDefs } from "../enums/TagCategoryDefs";
import { ClaimStatus } from '../enums';

export interface PatternItem extends ListingPatternModel {
  detailsUrl: string;
  maximumPrice: number;
  minimumPrice: number;
  displayPriceRange: string;
  maxRetailPrice: number;
  minRetailPrice: number;
}

export const sortPatternListingsBySize = (patternListings?: PatternListingDetails[]) => {
  const listings = patternListings ? [ ...patternListings ] : [];

  return listings
    .sort((a, b) => {
      const sizeA = a.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size);
      const sizeB = b.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size);

      return (sizeA?.sortOrder ?? -1) - (sizeB?.sortOrder ?? -1);
    })
    .filter((obj, pos) => {
      return (
        listings
          .map((mapObj) => mapObj.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.id)
          .indexOf(obj.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size)?.id) === pos
      );
    })
    .filter((l) => l.quantity > 0);
};

const sortPatterns = (a: PatternItem, b: PatternItem): number => {
  if (!a.listingDetails.length) return -1;
  if (!b.listingDetails.length) return 1;

  const styleA = a.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Style);
  const styleB = b.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Style);

  const compareStyle = (styleA?.sortOrder || 0) - (styleB?.sortOrder || 0);
  if (compareStyle) return compareStyle;

  const collectionA = a.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Collection);
  const collectionB = b.tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Collection);

  const compareCollection = (collectionA?.sortOrder || 0) - (collectionB?.sortOrder || 0);
  if (compareCollection) return compareCollection;

  let patternAFirstSize = sortPatternListingsBySize(a.listingDetails)[0];
  let patternBFirstSize = sortPatternListingsBySize(b.listingDetails)[0];
  if (patternAFirstSize && patternBFirstSize) {
    const sizeA = patternAFirstSize
        .tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size);
    const sizeB = patternBFirstSize
        .tagDetails.find((tag) => tag.tagCategoryId === TagCategoryDefs.Size);

    const compareSize = (sizeA?.sortOrder || 0) - (sizeB?.sortOrder || 0);
    if (compareSize) return compareSize;
  } else if (patternAFirstSize) {
    return -1;
  } else if (patternBFirstSize) {
    return 1;
  }

  const compareId = a.listingPatternId - b.listingPatternId;
  return compareId;
}

const getPatternsWithPriceRanges = createSelector(
[
    (state: State) => state.patterns.items,
    (state: State) => state.store.alias,
], (patterns, alias) => {
  const mappedPatterns = patterns.map<PatternItem>((pattern): PatternItem => {
    const availablePatternListings = pattern.listingDetails.filter((listing) => (
      listing.quantity > 0 || listing.claimBySessions.length > 0
    ));

    const maxPrice = Math.max.apply(Math, availablePatternListings.map((x) => x.salePrice));
    const minPrice = Math.min.apply(Math, availablePatternListings.map((x) => x.salePrice));
    const priceRange = (maxPrice === minPrice) ?
      `${Formatter.format(maxPrice)}` :
      `${Formatter.format(minPrice)} \u2014 ${Formatter.format(maxPrice)}`;

    const maxRetailPrice = Math.max.apply(Math, availablePatternListings.map((x) => x.msrp));
    const minRetailPrice = Math.min.apply(Math, availablePatternListings.map((x) => x.msrp));

    return {
      ...pattern,
      maximumPrice: maxPrice,
      minimumPrice: minPrice,
      displayPriceRange: priceRange,
      maxRetailPrice: maxRetailPrice > maxPrice ? maxRetailPrice : maxPrice,
      minRetailPrice: minRetailPrice > minPrice ? minRetailPrice : minPrice,
      detailsUrl: PageUrls.PatternDetailsPage(alias, pattern.listingPatternId),
    }

  });
  return mappedPatterns;
});

export const getAllPatterns = createSelector(
  [getPatternsWithPriceRanges],
  (patterns) => (
    patterns.sort((a, b) => sortPatterns(a, b))
  )
)

export const getAvailablePatterns = createSelector(
  [getPatternsWithPriceRanges],
  (patterns) => (
    patterns
      .filter((pattern) => (
        !pattern.reservedForLiveSelling &&
        pattern.listingDetails.some(listing =>
          listing.quantity > 0 || listing.claimBySessions?.some(claim => claim.claimStatus === ClaimStatus.ClaimedByYou && !claim.isSold)
        )
      ))
      .sort((a, b) => sortPatterns(a, b))
  )
)
