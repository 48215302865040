import React, { ReactChild, ReactNode, FunctionComponent } from "react";
import Styles from "./ChatBubble.module.scss";

interface Props {
  above: ReactNode;
  below: ReactNode;
  children: ReactChild;
  fullScreenMode: boolean;
}

const ChatBubble: FunctionComponent<Props> = ({
  above,
  below,
  children,
  fullScreenMode
}) => (
  <div className={Styles.message}>
    {above}
    <div className="clearfix">
      <div
        className={`${Styles.text} ${
          fullScreenMode ? Styles.text_full_screen : ""
        }`}
      >
        {children}
      </div>
    </div>
    {below}
  </div>
);

export default ChatBubble;
